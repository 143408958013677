import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import { Typography, Switch, FormControlLabel, FormGroup } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { t } from "i18next";
import Scenarios from "./Scenarios";
import SimpleInput from "../SimpleInput";
import StyledLabel from "../StyledLabel";

const VoteResultDialog = ({ open, handleClose, reso, addReso }) => {
  const [resolutionName, setResolutionName] = useState(reso?.title || "");
  const [resolutionNumber, setResolutionNumber] = useState(
    reso?.number_or_letter || ""
  );
  const [proposedAtAgm, setProposedAtAgm] = useState(
    reso?.proposed_at_agm || false
  );
  const [isCorrection, setIsCorrection] = useState(
    reso?.is_correction || false
  );
  const [dissenting, setDissenting] = useState(reso?.is_dissenting || false);
  return (
    <Dialog fullWidth open={open} onClose={handleClose}>
      <div style={{ padding: "15px 15px 0 15px" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <StyledLabel
            border
            text={reso?.from_boarddata ? t("add_score") : t("add_reso")}
          />
          <CloseIcon
            style={{ color: "#064c3b", cursor: "pointer" }}
            onClick={() => handleClose()}
          />
        </div>
        <br />
        {reso?.from_boarddata ? ( // Resolution selected from boarddata=> opened for editing
          <Typography
            sx={{
              color: "#064c3b",
            }}
            variant="h6"
            component="div"
          >
            {reso?.number_or_letter} - {reso?.resolution_type.title}
          </Typography>
        ) : (
          // new resolution=> opened for creation
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "baseline",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                gap: 20,
                width: "100%",
              }}
            >
              <div style={{ flex: 0.5 }}>
                <SimpleInput
                  placeholder="Num letter"
                  value={resolutionNumber}
                  onChange={(e) => setResolutionNumber(e.target.value)}
                  width="100%"
                />
              </div>
              <div style={{ flex: 3 }}>
                <SimpleInput
                  placeholder={t("reso_name")}
                  value={resolutionName}
                  onChange={(e) => setResolutionName(e.target.value)}
                  width="100%"
                />
              </div>
            </div>
            <br />
            <FormGroup sx={{ color: "#001756" }}>
              <FormControlLabel
                label="Proposed by Company Board at the AGM"
                control={
                  <Switch
                    value={proposedAtAgm}
                    onChange={({ target: { checked } }) =>
                      setProposedAtAgm(checked)
                    }
                  />
                }
              />
              <FormControlLabel
                label="Analyst catch up"
                control={
                  <Switch
                    value={isCorrection}
                    onChange={({ target: { checked } }) =>
                      setIsCorrection(checked)
                    }
                  />
                }
              />
              <FormControlLabel
                label="Dissenting"
                control={
                  <Switch
                    value={dissenting}
                    onChange={({ target: { checked } }) =>
                      setDissenting(checked)
                    }
                  />
                }
              />
            </FormGroup>
          </div>
        )}
      </div>
      <div style={{ padding: "0 16px 16px 16px" }}>
        <Scenarios
          reso={reso}
          addReso={(val) => {
            addReso({
              ...val,
              title: resolutionName,
              proposed_at_agm: proposedAtAgm,
              is_correction: isCorrection,
              is_dissenting: dissenting,
              number_or_letter: resolutionNumber,
            });
            setResolutionName("");
            setProposedAtAgm(false);
            setIsCorrection(false);
            setDissenting(false);
            setResolutionNumber("");
          }}
        />
      </div>
    </Dialog>
  );
};

export default VoteResultDialog;
