import { useState, useEffect, useMemo, useCallback } from "react";
import { produce } from "immer";
import { useTranslation } from "react-i18next";
import deepEqual from "deep-equal";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Loading from "../../components/Loading";
import Container from "@mui/material/Container";
import { v4 as uuidv4 } from "uuid";
import Typography from "@mui/material/Typography";
import StyledLabel from "../../components/StyledLabel";
import ControlledInput from "../../components/ControlledInput";
import { reports } from "../../data/reports";
import SimpleInput from "../../components/SimpleInput";
import { assemblyTypes } from "../../data/assemblyTypes";
import { confirmPrompter } from "../../data/ConfirmPrompter";
import MultilineInput from "../../components/MultilineInput";
import RichTextEditor from "../../components/RichTextEditor";
import RadioButtonsGroup from "../../components/RadioButtonsGroup";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ScrutineerDialog from "../../components/ScrutineerDialog";
import SimpleDatePicker from "../../components/SimpleDatePicker";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { useAlert } from "../../providers/AlertProvider";
import {
  groupBy,
  prop,
  mapObjIndexed,
  sum,
  pluck,
  values,
  isEmpty,
} from "ramda";
import SimpleSelect from "../../components/SimpleSelect";
import {
  toastSuccessHelper,
  toastChangeStatus,
  toastUpdateSection,
} from "../../components/Toaster";
import TimePickerComponent from "../../components/TimePickerComponent";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import DataSender from "../../components/DataSender";
import dayjs from "dayjs";
import { useUserRole } from "../../hooks/useUserRole";
import { Role } from "../../data/roles";
import {
  convertMinutesToHours,
  removeDuplicates,
  getHasPostponed,
  getVenue,
  getLiveVoting,
  getWebcastValue,
  getReplay,
  getLiveQA,
  getDomesticLanguage,
  getSimultaneousTranslation,
  getExclusiveEng,
  sortByPosition,
  objectRemap,
  cleanHtmlString,
} from "../../utils";
import MultiSelect from "../../components/MultiSelect";
import VoteResultDialog from "../../components/resolutions/VoteResultDialog";
import { diffInMinutes } from "../../utils";
import { TextField } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import QuestionsForms from "../../components/questions/QuestionsForms";
import { useParams } from "react-router-dom";
import ResolutionItem from "../../components/resolutions/ResolutionItem";
import MeetingReportFiles from "../../components/MeetingReportFiles";
import QuestionItem from "../../components/questions/QuestionItem";
import meetingReportsService from "../../services/investigate/meeting-reports-service";
import utilService from "../../services/investigate/util-service";
import { useConfirmationDialog } from "../../providers/ConfirmationDialogProvider";
import PresentationDialog from "../../components/presentations/PresentationDialog";
import Presentations from "../../components/presentations/Presentations";
const MeetingReport = () => {
  //------------- Get the passed meeting report id ----------------
  const { id } = useParams();
  const [report, setReport] = useState(null);
  useEffect(() => {
    meetingReportsService.getOneMeetingReport(id).then((res) => {
      console.log(res.data);
      setReport(res.data);
    });
  }, []);
  if (!report) return <Loading />;
  else return <RenderEditReport report={report} />;
};

const RenderEditReport = ({ report }) => {
  const userRole = useUserRole();
  const [form, setForm] = useState(report.form);
  const { requestConfirm } = useConfirmationDialog();
  const { showAlert: displayError } = useAlert();
  // section states
  const [company, setCompany] = useState(form.company);
  const [assembly, setAssembly] = useState(form.assembly);
  const [status, setStatus] = useState(produce(form.status, (draft) => {}));
  // ----------
  const [general, setGeneral] = useState(produce(form.general, (draft) => {}));
  const [terms, setTerms] = useState(produce(form.terms, (draft) => {}));
  const [composition, setComposition] = useState(
    produce(form.composition, (draft) => {}),
  );
  const [allocation, setAllocation] = useState(
    produce(form.allocation, (draft) => {}),
  );
  const [resolutions, setResolutions] = useState(
    produce(form.resolutions, (draft) => {}),
  );
  const [questions, setQuestions] = useState(
    produce(form.questions, (draft) => {}),
  );
  const [presentationModalOpen, setPresentationModalOpen] = useState(false);
  const [presentationsList, setPresentationsList] = useState([]);
  const [presentationToEdit, setPresentationToEdit] = useState(null);
  const [summary, setSummary] = useState(produce(form.summary, (draft) => {}));
  const [files, setFiles] = useState(produce(form.files, (draft) => {}));
  //------------- A- remote "meeting report" states ----------------
  const [companies, setCompanies] = useState([]);
  const [assemblies, setAssemblies] = useState([]);
  const [resosBoarddata, setResosBoarddata] = useState([]);
  const [resolutionTypes, setResolutionTypes] = useState([]);
  const [questionsThemes, setQuestionsThemes] = useState([]);
  const [presentationsThemes, setPresentationThemes] = useState([]);
  const [comexes, setComexes] = useState([]);
  const [mandataries, setMandataries] = useState([]);
  //---------- Dialogs States---------
  const [scrutineerDialogOpen, setScrutineerDialogOpen] = useState(false);
  const [voteResultDialogOpen, setVoteResultDialogOpen] = useState(false);
  const [resoToEdit, setResoToEdit] = useState({}); // This is the selected reso to give it to the resolution dialog to display it
  const [isNewReso, setIsNewReso] = useState(false);
  /* -------------------------***Questions***-------------------------*/
  const [questionsFormOpen, setQuestionsFormOpen] = useState(false);
  const [questionToEdit, setQuestionToEdit] = useState(null);
  const handleUpdateResolution = (updatedResolution) => {
    setResolutions(
      produce((draft) => {
        draft.resolutions = draft.resolutions.map((reso) => {
          if (reso.uid === updatedResolution.uid) {
            return updatedResolution;
          } else {
            return reso;
          }
        });
      }),
    );
  };

  const automaticChangeStatusIfStudentUpdates = (section) => {
    if (userRole == Role.SCALENS_EXTERNAL_STUDENT) {
      meetingReportsService
        .updateMeetingReportSectionStatus(report.id, section, "PENDING")
        .then((res) => {
          setStatus(
            produce((draft) => {
              draft[section] = "PENDING";
            }),
          );
        });
    }
  };
  const { t } = useTranslation();

  const groupedByLabel = useMemo(
    () => groupBy(prop("theme"), allocation.presentations),
    [allocation.presentations],
  );
  const sumDurationsByLabel = useMemo(() => {
    return mapObjIndexed(
      (group) => sum(pluck("duration", group)),
      groupedByLabel,
    );
  }, [groupedByLabel]);

  const totalDuration = useMemo(() => {
    return sum(values(sumDurationsByLabel));
  }, [sumDurationsByLabel]);

  const questionsDuration = useMemo(() => {
    if (!questions.question_start && !questions.question_end) return 0;
    if (questions.question_start && questions.question_end)
      return diffInMinutes(questions.question_start, questions.question_end);
  }, [
    questions.question_start,
    questions.question_end,
    questions.questions.length,
  ]);

  const meetingTotalDuration = useMemo(() => {
    return sum([totalDuration, questionsDuration]);
  }, [totalDuration, questionsDuration]);
  //----------------API functions--------------------------------------
  const getCompanies = async () => {
    console.log("getting companies...");
    utilService
      .getAllCompanies()
      .then((res) => {
        console.log("companies fetched successfully");
        setCompanies([...new Set(res.data)]);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getResolutionTypes = async () => {
    console.log("getting resolution types...");
    utilService
      .getResolutionTypes()
      .then((res) => {
        console.log("resolution types fetched successfully");
        setResolutionTypes(
          [...new Set(res.data)].sort((a, b) =>
            a.title.toLowerCase().localeCompare(b.title.toLowerCase()),
          ),
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAssemblies = async (cp) => {
    console.log(`get assemblies for id ${cp.id}...`);
    utilService
      .getAssembliesForCompany(cp.id)
      .then((res) => {
        console.log("assemblies fetched successfully");
        setAssemblies(res.data.map((asmbly) => asmbly._source.after));
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getBoarddataResolutions = async (asb) => {
    console.log(`get resolutions for id ${asb.id}...`);
    utilService
      .getAssemblyResolutions(asb.id)
      .then((res) => {
        console.log("Resolutions fetched successfully");
        setResosBoarddata(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getComexes = async (cp) => {
    console.log(`get comex for id ${cp.id}...`);
    utilService
      .getCompanyComexes(cp.id)
      .then((res) => {
        console.log("comex fetched successfully");
        setComexes(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getMandataries = async (cp) => {
    console.log(`get comex for id ${cp.id}...`);
    utilService
      .getCompanyMandataries(cp.id)
      .then((res) => {
        console.log("mandataries fetched successfully");
        setMandataries(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const getQuestionsThemes = async () => {
    console.log("getting questions themes");
    utilService
      .getQuestionsThemes()
      .then((res) => {
        console.log("themes fetched successfully");
        setQuestionsThemes([...new Set(res.data)]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getPresentationsThemes = async () => {
    console.log("getting proceeding themes...");
    utilService
      .getProceedingsThemes()
      .then((res) => {
        console.log("proceeding themes fetched successfully");
        setPresentationThemes([...new Set(res.data)]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleQuorumBlur = ({ target: { value } }) => {
    const isValid =
      /^(\d+([.,]\d+)?)?$/.test(value) || /^(n\/a)?$/i.test(value);
    if (!isValid) {
      setComposition(
        produce((draft) => {
          draft.quorum = "";
        }),
      );
      alert(
        "Please enter a valid number or 'n/a'. Any other input is not allowed.",
      );
    }
  };

  //----------------------Side effects--------------------------------
  useEffect(() => {
    getCompanies();
    getQuestionsThemes();
    getPresentationsThemes();
    getResolutionTypes();
  }, []);

  useEffect(() => {
    if (company?.id) {
      getAssemblies(company);
      getComexes(company);
      getMandataries(company);
    }
  }, [JSON.stringify(company)]);

  useEffect(() => {
    if (assembly?.id) getBoarddataResolutions(assembly);
  }, [JSON.stringify(assembly)]);
  useEffect(() => {
    if (questions.questions.length == 0) {
      setQuestions(
        produce((draft) => {
          draft.question_start = null;
          draft.question_end = null;
        }),
      );
    }
  }, [questions.questions.length]);

  useEffect(() => {
    setPresentationsList(allocation.presentations);
  }, [allocation.presentations]);

  const summaryTemplate = useCallback(
    () => `
  ${company && company.company_name} - ${terms.ag_motivation}
  <br>
  ${assembly.date_assembly}  
  <br>
  ${terms.location}
  <br>
  Quorum: ${
    composition.quorum === "n/a" ? composition.quorum : `${composition.quorum}%`
  }
  <br>
  ${getVenue(terms.venue)} 
  <br>
  ${convertMinutesToHours(meetingTotalDuration)}
  <br>
    The ${company && company.company_name} meeting was held on ${
      assembly.date_assembly
    } at ${terms.location}.
  <br>
  ${terms.assembly_description}
  <br>
  ${composition.assembly_president}
  <br>
  ${composition.scrutineers
    .map(
      (scrutineer) =>
        `${scrutineer.name} - ${scrutineer.company_name} - ${scrutineer.description}`,
    )
    .join("\n")}

  ${composition.assembly_secretary}
  <br>
  ${allocation.presentations
    ?.map((expo) => `${expo.theme} - ${expo.highlights}`)
    .join("<br><br>")}
   
    `,
    [
      company,
      composition.quorum,
      allocation.presentations,
      terms.venue,
      terms.assembly_description,
      composition.assembly_secretary,
      composition.assembly_president,
      composition.scrutineers,
      assembly.date_assembly,
      terms.location,
      terms.ag_motivation,
      meetingTotalDuration,
    ],
  );

  useEffect(() => {
    if (form)
      setSummary(
        produce((draft) => {
          draft.autogenerated = summaryTemplate();
        }),
      );
  }, [form, summaryTemplate]);

  const handleEditPresentation = (presentation) => {
    setPresentationToEdit(presentation);
    setPresentationModalOpen(true);
  };

  const resolutionsOptions = useMemo(() => {
    const resosFiltered = resosBoarddata.filter(
      (reso) => !resolutions.resolutions.some((res) => res.uid === reso.uid),
    );
    return sortByPosition(resosFiltered);
  }, [resolutions.resolutions, resosBoarddata]);

  // -----------------------------------------------------------------------
  return (
    <div
      style={{
        opacity: report.validate_by_analyst ? 0.6 : 1,
        pointerEvents: report.validate_by_analyst ? "none" : "auto",
      }}
    >
      <PresentationDialog
        isEditing
        proceedingsThemes={presentationsThemes}
        presentation={presentationToEdit}
        onUpdatePresentation={(presentation) => {
          setAllocation(
            produce((draft) => {
              draft.presentations = draft.presentations.map((pre) =>
                pre.uid === presentation.uid ? presentation : pre,
              );
            }),
          );
          setPresentationModalOpen(false);
        }}
        open={presentationModalOpen}
        handleClose={() => setPresentationModalOpen(false)}
      />
      <ScrutineerDialog
        open={scrutineerDialogOpen}
        handleClose={() => setScrutineerDialogOpen(false)}
        handleAddScrutineer={(scrutineer) =>
          setComposition(
            produce((draft) => {
              draft.scrutineers = [{ ...scrutineer }, ...draft.scrutineers];
            }),
          )
        }
      />
      <VoteResultDialog
        key={resoToEdit?.uid || null}
        open={voteResultDialogOpen}
        handleClose={() => {
          setResoToEdit(null);
          setIsNewReso(false);
          setVoteResultDialogOpen(false);
        }}
        isNewReso={isNewReso}
        reso={resoToEdit}
        addReso={(newReso) => {
          setResolutions(
            produce((draft) => {
              draft.resolutions = [newReso, ...draft.resolutions];
            }),
          );
          setResoToEdit(null);
          setVoteResultDialogOpen(false);
        }}
      />
      <QuestionsForms
        questionToEdit={questionToEdit}
        open={questionsFormOpen}
        handleClose={() => {
          setQuestionToEdit(null);
          setQuestionsFormOpen(false);
        }}
        addOrUpdateQuestion={(val) => {
          // check if question  already exists in the list of questions
          if (questions.questions.some((quest) => quest.uid == val.uid)) {
            const newQuestionsList = questions.questions.map((quest) => {
              if (quest.uid === val.uid) {
                return val;
              } else return quest;
            });
            setQuestions(
              produce((draft) => {
                draft.questions = newQuestionsList;
              }),
            );
          }
          // New question
          else
            setQuestions(
              produce((draft) => {
                draft.questions = [val, ...draft.questions];
              }),
            );
        }}
        questionsThemes={questionsThemes}
      />
      <Container
        maxWidth="lg"
        sx={{
          paddingBottom: ".5rem",
        }}
      >
        <CssBaseline />
        <Box
          sx={{
            bgcolor: "#FFF",
            height: "100%",
            marginTop: "1rem",
            padding: 3,
            borderRadius: 3,
          }}
        >
          <Typography
            sx={{
              color: "#064c3b",
            }}
            variant="h4"
            component="div"
          >
            {t("app_title")}
          </Typography>
          <hr style={{ margin: "20px 0" }} />
          <div style={{ display: "flex", alignItems: "center" }}>
            <StyledLabel text={t("company")} />
            <span style={{ color: "red", fontSize: 21 }}>*</span>
          </div>
          <Tooltip title={`Company id: ${company.id}`} placement="top">
            <TextField
              id="filled-basic"
              variant="filled"
              value={company.company_name}
              disabled
            />
          </Tooltip>
          {company && assembly ? (
            <>
              <StyledLabel required text={t("meeting_date")} />
              <Tooltip title={`Assembly id: ${assembly.id}`} placement="top">
                <TextField
                  id="filled-basic"
                  variant="filled"
                  value={assembly.date_assembly}
                  disabled
                />
              </Tooltip>
              <br />
            </>
          ) : (
            company && (
              <Typography
                sx={{ color: "crimson", fontStyle: "italic" }}
                variant="subtitle3"
                display="block"
                gutterBottom
              >
                {t("no_assembly")}
              </Typography>
            )
          )}
          <br />
          <DataSender
            key={"general"}
            title={t("general")}
            status={status.general.state || status.general}
            comment={status.general.comment || ""}
            onStatusChange={(newStatus) => {
              if (!deepEqual(general, form.general)) {
                alert("Please update or reset data before changing status");
                return;
              }
              requestConfirm({
                title: confirmPrompter.changeStatus.title(t("general")),
                description:
                  confirmPrompter.changeStatus.description(newStatus),
                displayComment: newStatus == "REJECTED",
                onConfirm: (comment) =>
                  toastChangeStatus(
                    meetingReportsService.updateMeetingReportSectionStatus(
                      report.id,
                      "general",
                      newStatus,
                      comment,
                    ),
                  )
                    .then((res) => {
                      setStatus(
                        produce((draft) => {
                          draft.general = {
                            state: newStatus,
                            comment: comment,
                          };
                        }),
                      );
                    })
                    .catch((err) => {
                      displayError(err.response?.data?.detail || err.message);
                    }),
              });
            }}
            onUpdate={() =>
              requestConfirm({
                title: confirmPrompter.updateSection.title,
                description: confirmPrompter.updateSection.description,
                onConfirm: () =>
                  toastUpdateSection(
                    meetingReportsService.updateMeetingReportSectionData(
                      report.id,
                      "general",
                      general,
                    ),
                  )
                    .then((res) => {
                      setForm(
                        produce((draft) => {
                          draft.general = general;
                        }),
                      );
                      automaticChangeStatusIfStudentUpdates("general");
                    })
                    .catch((err) => {
                      displayError(err.response?.data?.detail || err.message);
                    }),
              })
            }
            onResetClicked={() =>
              requestConfirm({
                title: confirmPrompter.resetSection.title,
                description: confirmPrompter.resetSection.description,
                onConfirm: () =>
                  setGeneral(produce(form.general, (draft) => {})),
              })
            }
          >
            <StyledLabel text={t("Time")} />
            <TimePickerComponent
              time={dayjs(`2022-04-17T${general.time_assembly}`)}
              onChange={(e) =>
                setGeneral(
                  produce((draft) => {
                    draft.time_assembly = dayjs(e).format("HH:mm");
                  }),
                )
              }
            />
            <StyledLabel text={t("report_made_by")} />
            <SimpleInput
              value={general.reported_by}
              onChange={(e) =>
                setGeneral(
                  produce((draft) => {
                    draft.reported_by = e.target.value;
                  }),
                )
              }
            />
            <StyledLabel required text={t("student_email")} />
            <SimpleInput
              value={general.student_email}
              onChange={(e) =>
                setGeneral(
                  produce((draft) => {
                    draft.student_email = e.target.value;
                  }),
                )
              }
              type="email"
            />
            <StyledLabel text={t("report_has_posponed")} />
            <div style={{ display: "flex", flexDirection: "column" }}>
              <RadioButtonsGroup
                value={getHasPostponed(general.postponed)}
                handleRadioChange={(e) =>
                  setGeneral(
                    produce((draft) => {
                      draft.postponed = {
                        date_report:
                          e.target.value != "Postponed for other reasons"
                            ? null
                            : draft.date_report,
                        meeting_no_postponed:
                          e.target.value ===
                          "No postponement, held in 1st convocation",
                        meeting_held_2d_convocation:
                          e.target.value === "Held in 2d convocation",
                        meeting_postponed_other_reason:
                          e.target.value === "Postponed for other reasons",
                      };
                    }),
                  )
                }
                options={reports}
              />
              {general.postponed.meeting_postponed_other_reason && (
                <>
                  <StyledLabel text={t("Postponement date")} />
                  <SimpleDatePicker
                    date={general.postponed.date_report || ""}
                    onChange={(newDate) =>
                      setGeneral(
                        produce((draft) => {
                          draft.postponed.date_report = newDate;
                        }),
                      )
                    }
                  />
                </>
              )}
            </div>
          </DataSender>
          <DataSender
            key={"terms"}
            title={t("terms")}
            status={status.terms.state || status.terms}
            comment={status.terms.comment || ""}
            onStatusChange={(newStatus) => {
              if (!deepEqual(terms, form.terms)) {
                alert("Please update or reset data before changing status");
                return;
              }
              requestConfirm({
                title: confirmPrompter.changeStatus.title(t("terms")),
                description:
                  confirmPrompter.changeStatus.description(newStatus),
                displayComment: newStatus == "REJECTED",
                onConfirm: (comment) =>
                  toastChangeStatus(
                    meetingReportsService.updateMeetingReportSectionStatus(
                      report.id,
                      "terms",
                      newStatus,
                      comment,
                    ),
                  )
                    .then((res) => {
                      setStatus(
                        produce((draft) => {
                          draft.terms = { state: newStatus, comment: comment };
                        }),
                      );
                    })
                    .catch((err) => {
                      displayError(err.response?.data?.detail || err.message);
                    }),
              });
            }}
            onUpdate={() =>
              requestConfirm({
                title: confirmPrompter.updateSection.title,
                description: confirmPrompter.updateSection.description,
                onConfirm: () =>
                  toastUpdateSection(
                    meetingReportsService.updateMeetingReportSectionData(
                      report.id,
                      "terms",
                      terms,
                    ),
                  )
                    .then((res) => {
                      setForm(
                        produce((draft) => {
                          draft.terms = terms;
                        }),
                      );
                      automaticChangeStatusIfStudentUpdates("terms");
                    })
                    .catch((err) => {
                      displayError(err.response?.data?.detail || err.message);
                    }),
              })
            }
            onResetClicked={() =>
              requestConfirm({
                title: confirmPrompter.resetSection.title,
                description: confirmPrompter.resetSection.description,
                onConfirm: () => setTerms(produce(form.terms, (draft) => {})),
              })
            }
          >
            <div style={{ marginTop: 20, marginBottom: 40 }}>
              <StyledLabel text={t("place")} />
              <SimpleInput
                value={terms.location}
                onChange={(e) =>
                  setTerms(
                    produce((draft) => {
                      draft.location = e.target.value;
                    }),
                  )
                }
                placeholder=""
              />
            </div>
            <div style={{ display: "flex", alignItems: "baseline", gap: 50 }}>
              <div>
                <StyledLabel text={t("venue")} />
                <RadioButtonsGroup
                  value={getVenue(terms.venue)}
                  handleRadioChange={(e) =>
                    setTerms(
                      produce((draft) => {
                        draft.venue = {
                          meeting_hybrid: e.target.value === "Hybrid",
                          meeting_in_person: e.target.value === "In person",
                          meeting_virtual: e.target.value === "Virtual",
                        };
                      }),
                    )
                  }
                  options={[
                    { label: "Hybrid", value: "Hybrid" },
                    { label: "Virtual", value: "Virtual" },
                    { label: "In person", value: "In person" },
                  ]}
                />
              </div>
              <div>
                <StyledLabel text={t("live_voting")} />
                <RadioButtonsGroup
                  value={getLiveVoting(terms.meeting_live_voting)}
                  handleRadioChange={(e) =>
                    setTerms(
                      produce((draft) => {
                        draft.meeting_live_voting = e.target.value === "yes";
                      }),
                    )
                  }
                  options={[
                    { label: "Yes", value: "yes" },
                    { label: "No", value: "no" },
                  ]}
                />
              </div>
              <div>
                <StyledLabel text={t("webcast")} />
                <RadioButtonsGroup
                  value={getWebcastValue(terms.webcast)}
                  handleRadioChange={(e) =>
                    setTerms(
                      produce((draft) => {
                        draft.webcast = {
                          has_audio: e.target.value === "Audio",
                          has_video: e.target.value === "Video",
                          can_ask_direct: e.target.value === "No webcast",
                        };
                      }),
                    )
                  }
                  options={[
                    { label: "Video", value: "Video" },
                    { label: "Audio", value: "Audio" },
                    { label: "No webcast", value: "No webcast" },
                  ]}
                />
              </div>
            </div>
            <div style={{ display: "flex", alignItems: "baseline", gap: 50 }}>
              <div>
                <StyledLabel text={t("replay")} />
                <RadioButtonsGroup
                  value={getReplay(terms.meeting_replay)}
                  handleRadioChange={(e) =>
                    setTerms(
                      produce((draft) => {
                        draft.meeting_replay = e.target.value == "yes";
                      }),
                    )
                  }
                  options={[
                    { label: "Yes", value: "yes" },
                    { label: "No", value: "no" },
                  ]}
                />
              </div>
              <div>
                <StyledLabel text={t("live_qa")} />
                <RadioButtonsGroup
                  value={getLiveQA(terms.meeting_live_QandA)}
                  handleRadioChange={(e) =>
                    setTerms(
                      produce((draft) => {
                        draft.meeting_live_QandA = e.target.value == "yes";
                      }),
                    )
                  }
                  options={[
                    { label: "Yes", value: "yes" },
                    { label: "No", value: "no" },
                  ]}
                />
              </div>
            </div>
            <div style={{ display: "flex", alignItems: "baseline", gap: 50 }}>
              <div>
                <StyledLabel text={t("domestic_language")} />
                <RadioButtonsGroup
                  value={getDomesticLanguage(terms.language)}
                  handleRadioChange={(e) =>
                    setTerms(
                      produce((draft) => {
                        draft.language.domestic_language =
                          e.target.value == "yes";
                      }),
                    )
                  }
                  options={[
                    { label: "Yes", value: "yes" },
                    { label: "No", value: "no" },
                  ]}
                />
              </div>
              <div>
                <StyledLabel text={t("simultaneous_trans")} />
                <RadioButtonsGroup
                  value={getSimultaneousTranslation(terms.language)}
                  handleRadioChange={(e) =>
                    setTerms(
                      produce((draft) => {
                        draft.language.simultaneous_translation =
                          e.target.value == "yes";
                      }),
                    )
                  }
                  options={[
                    { label: "Yes", value: "yes" },
                    { label: "No", value: "no" },
                  ]}
                />
              </div>

              <div>
                <StyledLabel text={t("exclusive_eng")} />
                <RadioButtonsGroup
                  value={getExclusiveEng(terms.language)}
                  handleRadioChange={(e) =>
                    setTerms(
                      produce((draft) => {
                        draft.language.exclusively_english =
                          e.target.value == "yes";
                      }),
                    )
                  }
                  options={[
                    { label: "Yes", value: "yes" },
                    { label: "No", value: "no" },
                  ]}
                />
              </div>
            </div>
            <StyledLabel text={t("meeting_type")} />
            <RadioButtonsGroup
              value={terms.ag_motivation}
              handleRadioChange={({ target: { value } }) => {
                setTerms(
                  produce((draft) => {
                    draft.ag_motivation = value;
                  }),
                );
              }}
              options={assemblyTypes}
            />
            <StyledLabel text={t("scene_description")} />
            <MultilineInput
              value={terms.assembly_description}
              onChange={(e) =>
                setTerms(
                  produce((draft) => {
                    draft.assembly_description = e.target.value;
                  }),
                )
              }
            />
          </DataSender>
          <DataSender
            key={"composition"}
            title={t("office_composition")}
            status={status.composition.state || status.composition}
            comment={status.composition.comment || ""}
            onStatusChange={(newStatus) => {
              if (!deepEqual(composition, form.composition)) {
                alert("Please update or reset data before changing status");
                return;
              }
              requestConfirm({
                title: confirmPrompter.changeStatus.title(
                  t("office_composition"),
                ),
                description:
                  confirmPrompter.changeStatus.description(newStatus),
                displayComment: newStatus == "REJECTED",
                onConfirm: (comment) =>
                  toastChangeStatus(
                    meetingReportsService.updateMeetingReportSectionStatus(
                      report.id,
                      "composition",
                      newStatus,
                      comment,
                    ),
                  )
                    .then((res) => {
                      setStatus(
                        produce((draft) => {
                          draft.composition = {
                            state: newStatus,
                            comment: comment,
                          };
                        }),
                      );
                    })
                    .catch((err) => {
                      displayError(err.response?.data?.detail || err.message);
                    }),
              });
            }}
            onResetClicked={() =>
              requestConfirm({
                title: confirmPrompter.resetSection.title,
                description: confirmPrompter.resetSection.description,
                onConfirm: () =>
                  setComposition(produce(form.composition, (draft) => {})),
              })
            }
            onUpdate={() =>
              requestConfirm({
                title: confirmPrompter.updateSection.title,
                description: confirmPrompter.updateSection.description,
                onConfirm: () =>
                  toastUpdateSection(
                    meetingReportsService.updateMeetingReportSectionData(
                      report.id,
                      "composition",
                      composition,
                    ),
                  )
                    .then((res) => {
                      setForm(
                        produce((draft) => {
                          draft.composition = composition;
                        }),
                      );
                      automaticChangeStatusIfStudentUpdates("composition");
                    })
                    .catch((err) => {
                      displayError(err.response?.data?.detail || err.message);
                    }),
              })
            }
          >
            <br />
            <br />
            <StyledLabel text={t("assembly_president")} />
            <SimpleInput
              value={composition.assembly_president}
              onChange={(e) =>
                setComposition(
                  produce((draft) => {
                    draft.assembly_president = e.target.value;
                  }),
                )
              }
              placeholder=""
            />
            <br />
            <br />
            <div style={{ display: "flex", alignItems: "center" }}>
              <StyledLabel text={t("scrutineer")} />
              <AddCircleOutlineIcon
                onClick={() => setScrutineerDialogOpen(true)}
                style={{ fontSize: 30, color: "green", cursor: "pointer" }}
              />
            </div>
            <br />
            <div>
              {composition.scrutineers.map((scrutineer) => (
                <div
                  key={scrutineer.uid || scrutineer.name}
                  style={{
                    border: "1px solid lightgray",
                    padding: 5,
                    backgroundColor: "#F1F1F1",
                    borderRadius: 5,
                    marginBottom: 10,
                    height: "fit-content",
                  }}
                >
                  <ul style={{ padding: 0 }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <StyledLabel text={scrutineer.name} />
                      <DeleteOutlineIcon
                        onClick={() =>
                          setComposition(
                            produce((draft) => {
                              draft.scrutineers =
                                composition.scrutineers.filter(
                                  (s) => s.name !== scrutineer.name,
                                );
                            }),
                          )
                        }
                        fontSize="small"
                        style={{ color: "crimson" }}
                      />
                    </div>
                    <li
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: 10,
                      }}
                    >
                      <Typography
                        style={{ fontSize: 15, fontWeight: "bold" }}
                        variant="subtitle1"
                        gutterBottom
                      >
                        {t("company_name")}:
                      </Typography>
                      <Typography
                        style={{ fontSize: 17 }}
                        variant="h6"
                        gutterBottom
                      >
                        {scrutineer.company_name}
                      </Typography>
                    </li>
                    <li
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: 10,
                      }}
                    >
                      <Typography
                        style={{ fontSize: 15, fontWeight: "bold" }}
                        variant="subtitle1"
                        gutterBottom
                      >
                        {t("description")}:
                      </Typography>
                      <Typography
                        style={{ fontSize: 17, overflowWrap: "anywhere" }}
                        variant="h6"
                        gutterBottom
                      >
                        {scrutineer.description}
                      </Typography>
                    </li>
                  </ul>
                </div>
              ))}
            </div>
            <br />
            <StyledLabel text={t("board_secretary")} />
            <SimpleInput
              value={composition.assembly_secretary}
              onChange={(e) =>
                setComposition(
                  produce((draft) => {
                    draft.assembly_secretary = e.target.value;
                  }),
                )
              }
              placeholder=""
            />
            <br />
            <br />
            <div style={{ display: "flex", alignItems: "baseline" }}>
              <StyledLabel text={t("quorum")} />
              <ControlledInput
                value={composition.quorum ?? ""}
                type="text"
                onChange={({ target: { value } }) => {
                  setComposition(
                    produce((draft) => {
                      draft.quorum = value.replace(",", ".");
                    }),
                  );
                }}
                handleBlur={handleQuorumBlur}
                width={100}
                adornment="%"
                placeholder="n/a"
              />
            </div>
            <br />
            <StyledLabel text={t("directors_attendance")} />
            <MultiSelect
              value={composition.attended_mandataries}
              options={removeDuplicates(mandataries)}
              onChange={(pre) =>
                setComposition(
                  produce((draft) => {
                    draft.attended_mandataries = pre.map((p) => ({
                      value: p.value,
                      name: p.name,
                      present: true,
                      uid: p.uid,
                      person_id: p.person_id,
                    }));
                  }),
                )
              }
            />
            <br />
            <StyledLabel text={t("executive_comitee")} />
            <MultiSelect
              value={composition.attended_comexes}
              options={removeDuplicates(comexes)}
              onChange={(pre) => {
                setComposition(
                  produce((draft) => {
                    draft.attended_comexes = pre.map((p) => ({
                      value: p.value,
                      name: p.name,
                      present: true,
                      uid: p.uid,
                      person_id: p.person_id,
                    }));
                  }),
                );
              }}
            />
            <br />
            <br />
            {(getVenue(terms.venue) === "In person" ||
              getVenue(terms.venue) === "Hybrid") && (
              <>
                <div style={{ display: "flex", alignItems: "baseline" }}>
                  <StyledLabel text={t("physical_attendance")} />
                  <ControlledInput
                    value={composition.comex_physical_attendance || 0}
                    type="number"
                    onChange={(e) =>
                      setComposition(
                        produce((draft) => {
                          const inputValue = parseInt(e.target.value);
                          draft.comex_physical_attendance = isNaN(inputValue)
                            ? 0
                            : inputValue;
                        }),
                      )
                    }
                    width={100}
                  />
                </div>
                <br />
              </>
            )}
            <div style={{ display: "flex", alignItems: "baseline" }}>
              <StyledLabel text={t("connected_persons")} />
              <ControlledInput
                value={composition.number_people_connected || 0}
                type="number"
                onChange={(e) =>
                  setComposition(
                    produce((draft) => {
                      const inputValue = parseInt(e.target.value);
                      draft.number_people_connected = isNaN(inputValue)
                        ? 0
                        : inputValue;
                    }),
                  )
                }
                width={100}
              />
            </div>
            <br />
            <div style={{ display: "flex", alignItems: "baseline" }}>
              <StyledLabel text={t("domestic_version")} />
              <ControlledInput
                value={
                  composition.number_people_connected_domestic_version || 0
                }
                onChange={(e) =>
                  setComposition(
                    produce((draft) => {
                      const inputValue = parseInt(e.target.value);
                      draft.number_people_connected_domestic_version = isNaN(
                        inputValue,
                      )
                        ? 0
                        : inputValue;
                    }),
                  )
                }
                width={100}
                type="number"
              />
            </div>
            <br />
            <div style={{ display: "flex", alignItems: "baseline" }}>
              <StyledLabel text={t("connected_eng")} />
              <ControlledInput
                value={composition.number_people_connected_english_version || 0}
                onChange={(e) =>
                  setComposition(
                    produce((draft) => {
                      const inputValue = parseInt(e.target.value);
                      draft.number_people_connected_english_version = isNaN(
                        inputValue,
                      )
                        ? 0
                        : inputValue;
                    }),
                  )
                }
                width={100}
                type="number"
              />
            </div>
            <br />
            <br />
          </DataSender>
          <DataSender
            key="allocation"
            title={t("time_allocation")}
            status={status.allocation.state || status.allocation}
            comment={status.allocation.comment || ""}
            onStatusChange={(newStatus) => {
              if (form.allocation.duration !== meetingTotalDuration) {
                alert("Please update or reset data before changing status");
                return;
              }
              requestConfirm({
                title: confirmPrompter.changeStatus.title(t("time_allocation")),
                description:
                  confirmPrompter.changeStatus.description(newStatus),
                displayComment: newStatus == "REJECTED",
                onConfirm: (comment) =>
                  toastChangeStatus(
                    meetingReportsService.updateMeetingReportSectionStatus(
                      report.id,
                      "allocation",
                      newStatus,
                      comment,
                    ),
                  )
                    .then((res) => {
                      setStatus(
                        produce((draft) => {
                          draft.allocation = {
                            state: newStatus,
                            comment: comment,
                          };
                        }),
                      );
                    })
                    .catch((err) => {
                      displayError(err.response?.data?.detail || err.message);
                    }),
              });
            }}
            onUpdate={() =>
              requestConfirm({
                title: confirmPrompter.updateSection.title,
                description: confirmPrompter.updateSection.description,
                onConfirm: () =>
                  toastUpdateSection(
                    meetingReportsService.updateMeetingReportSectionData(
                      report.id,
                      "allocation",
                      {
                        ...allocation,
                        duration: meetingTotalDuration,
                      },
                    ),
                  )
                    .then((res) => {
                      setForm(
                        produce((draft) => {
                          draft.allocation = allocation;
                        }),
                      );
                      automaticChangeStatusIfStudentUpdates("allocation");
                    })
                    .catch((err) => {
                      displayError(err.response?.data?.detail || err.message);
                    }),
              })
            }
            onResetClicked={() =>
              requestConfirm({
                title: confirmPrompter.resetSection.title,
                description: confirmPrompter.resetSection.description,
                onConfirm: () =>
                  setAllocation(produce(form.allocation, (draft) => {})),
              })
            }
          >
            {" "}
            <br />
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(3, 1fr)",
                gridAutoRows: "minmax(90px, auto)",
                overflow: "auto",
              }}
            >
              {presentationsThemes?.map((pt) => (
                <div key={pt.uid}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "baseline",
                      marginBottom: 10,
                      backgroundColor: "#8fa6e616",
                      padding: 10,
                      borderRadius: 10,
                      maxWidth: 300,
                    }}
                  >
                    <StyledLabel preWrap flex={1} text={t(pt.theme)} />
                    <ControlledInput
                      bold
                      width={110}
                      adornment="min"
                      value={
                        isEmpty(sumDurationsByLabel) ||
                        !sumDurationsByLabel[pt.theme]
                          ? ""
                          : sumDurationsByLabel[pt.theme]
                      }
                      defaultValue={0}
                      readOnly
                    />
                  </div>
                </div>
              ))}
              <div
                style={{
                  display: "flex",
                  alignItems: "baseline",
                  marginBottom: 10,
                  backgroundColor: "#8fa6e616",
                  padding: 10,
                  borderRadius: 10,
                  maxWidth: 250,
                }}
              >
                <StyledLabel flex={1} text={t("Questions")} />
                <ControlledInput
                  bold
                  width={110}
                  adornment="min"
                  value={questionsDuration}
                  readOnly
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "baseline",
                justifyContent: "end",
                backgroundColor: "#064c3b17",
                padding: 10,
                borderRadius: 10,
              }}
            >
              <StyledLabel text={t("total_duration")} />
              <ControlledInput
                readOnly
                bold
                value={meetingTotalDuration}
                width={120}
                adornment="min"
              />
            </div>
            <br />
            <Presentations
              presentationsThemes={presentationsThemes}
              presentations={allocation.presentations}
              onAddPresentation={(val) => {
                setAllocation(
                  produce((draft) => {
                    draft.presentations = [...draft.presentations, { ...val }];
                  }),
                );
              }}
              onEditPresentation={(presentation) => {
                handleEditPresentation(presentation);
              }}
              onDeletePresentation={(presentation) => {
                setAllocation(
                  produce((draft) => {
                    draft.presentations = draft.presentations.filter(
                      (e) => e.uid !== presentation.uid,
                    );
                  }),
                );
              }}
              list={allocation.presentations.map((pre) => ({ ...pre }))}
              setList={(newList) => {
                setAllocation({ ...allocation, presentations: newList });
              }}
            />
            <br />
            <br />
          </DataSender>
          <DataSender
            key="questions"
            title={t("qa")}
            status={status.questions.state || status.questions}
            comment={status.questions.comment || ""}
            onStatusChange={(newStatus) => {
              if (!deepEqual(questions, form.questions)) {
                alert("Please update or reset data before changing status");
                return;
              }
              requestConfirm({
                title: confirmPrompter.changeStatus.title(t("qa")),
                description:
                  confirmPrompter.changeStatus.description(newStatus),
                displayComment: newStatus == "REJECTED",
                onConfirm: (comment) =>
                  toastChangeStatus(
                    meetingReportsService.updateMeetingReportSectionStatus(
                      report.id,
                      "questions",
                      newStatus,
                      comment,
                    ),
                  )
                    .then((res) => {
                      setStatus(
                        produce((draft) => {
                          draft.questions = {
                            state: newStatus,
                            comment: comment,
                          };
                        }),
                      );
                    })
                    .catch((err) => {
                      displayError(err.response?.data?.detail || err.message);
                    }),
              });
            }}
            onUpdate={() =>
              requestConfirm({
                title: confirmPrompter.updateSection.title,
                description: confirmPrompter.updateSection.description,
                onConfirm: () =>
                  toastUpdateSection(
                    meetingReportsService.updateMeetingReportSectionData(
                      report.id,
                      "questions",
                      { ...questions, duration: meetingTotalDuration },
                    ),
                  )
                    .then((res) => {
                      setForm(
                        produce((draft) => {
                          draft.questions = questions;
                        }),
                      );
                      automaticChangeStatusIfStudentUpdates("questions");
                    })
                    .catch((err) => {
                      displayError(err.response?.data?.detail || err.message);
                    }),
              })
            }
            onResetClicked={() =>
              requestConfirm({
                title: confirmPrompter.resetSection.title,
                description: confirmPrompter.resetSection.description,
                onConfirm: () =>
                  setQuestions(produce(form.questions, (draft) => {})),
              })
            }
          >
            <br />
            <div
              style={{
                display: "flex",
                alignItems: "baseline",
                justifyContent: "end",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                }}
              >
                <StyledLabel
                  cursor="pointer"
                  text={t("add_question")}
                  onClick={() => setQuestionsFormOpen(true)}
                />
                <AddCircleOutlineIcon
                  onClick={() => setQuestionsFormOpen(true)}
                  style={{ fontSize: 30, color: "green", cursor: "pointer" }}
                />
              </div>
            </div>
            <br />
            <div style={{ backgroundColor: "#8fa6e616", borderRadius: 10 }}>
              {questions.questions.length > 0 && (
                <>
                  {questions.questions.map((quest, index) => (
                    <QuestionItem
                      key={quest.uid}
                      question={quest}
                      onDelete={() => {
                        const confirmDelete = window.confirm(
                          "Are you sure you want to delete this question?",
                        );
                        if (confirmDelete) {
                          setQuestions(
                            produce((draft) => {
                              draft.questions = draft.questions.filter(
                                (q) => q.uid !== quest.uid,
                              );
                            }),
                          );
                        }
                      }}
                      onEdit={() => {
                        setQuestionToEdit(quest);
                        setQuestionsFormOpen(true);
                      }}
                    />
                  ))}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: 5,
                      paddingLeft: 10,
                      paddingRight: 10,
                      justifyContent: "end",
                      marginTop: 10,
                    }}
                  >
                    <div>
                      <StyledLabel text={t("start")} fontSize={16} />
                      <TimePickerComponent
                        time={questions.question_start}
                        onChange={(e) =>
                          setQuestions(
                            produce((draft) => {
                              draft.question_start = e;
                            }),
                          )
                        }
                      />
                    </div>
                    <div>
                      <StyledLabel text={t("end")} fontSize={16} />
                      <TimePickerComponent
                        time={questions.question_end}
                        onChange={(e) =>
                          setQuestions(
                            produce((draft) => {
                              draft.question_end = e;
                            }),
                          )
                        }
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
            <br />
            <div
              style={{
                display: "flex",
                alignItems: "baseline",
                justifyContent: "end",
                gap: 20,
              }}
            >
              <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
                <Typography
                  style={{ fontSize: 21, fontWeight: "bold" }}
                  variant="h6"
                >
                  {questions.questions.length}
                </Typography>
                <Typography
                  style={{ fontSize: 14, fontWeight: "bold" }}
                  variant="subtitle1"
                >
                  {`${
                    questions.questions.length > 1 ? "questions" : "question"
                  } asked`}
                </Typography>
              </div>
              {questionsDuration > 0 && (
                <Typography
                  sx={{
                    color: "#064c3b",
                  }}
                  variant="body1"
                  component="div"
                >
                  {convertMinutesToHours(questionsDuration)}
                </Typography>
              )}
            </div>
          </DataSender>
          <DataSender
            key="resolutions"
            title={t("Resolutions")}
            status={status.resolutions.state || status.resolutions}
            comment={status.resolutions.comment || ""}
            onStatusChange={(newStatus) => {
              if (
                !deepEqual(
                  objectRemap(resolutions),
                  objectRemap(form.resolutions),
                )
              ) {
                alert("Please update or reset data before changing status");
                return;
              }
              requestConfirm({
                title: confirmPrompter.changeStatus.title(t("Resolutions")),
                description:
                  confirmPrompter.changeStatus.description(newStatus),
                displayComment: newStatus == "REJECTED",
                onConfirm: (comment) =>
                  toastChangeStatus(
                    meetingReportsService.updateMeetingReportSectionStatus(
                      report.id,
                      "resolutions",
                      newStatus,
                      comment,
                    ),
                  )
                    .then((res) => {
                      setStatus(
                        produce((draft) => {
                          draft.resolutions = {
                            state: newStatus,
                            comment: comment,
                          };
                        }),
                      );
                    })
                    .catch((err) => {
                      displayError(err.response?.data?.detail || err.message);
                    }),
              });
            }}
            onUpdate={() =>
              requestConfirm({
                title: confirmPrompter.updateSection.title,
                description: confirmPrompter.updateSection.description,
                onConfirm: () =>
                  toastUpdateSection(
                    meetingReportsService.updateMeetingReportSectionData(
                      report.id,
                      "resolutions",
                      resolutions,
                    ),
                  )
                    .then((res) => {
                      setForm(
                        produce((draft) => {
                          draft.resolutions = resolutions;
                        }),
                      );
                      automaticChangeStatusIfStudentUpdates("resolutions");
                    })
                    .catch((err) => {
                      displayError(err.response?.data?.detail || err.message);
                    }),
              })
            }
            onResetClicked={() =>
              requestConfirm({
                title: confirmPrompter.resetSection.title,
                description: confirmPrompter.resetSection.description,
                onConfirm: () =>
                  setResolutions(produce(form.resolutions, (draft) => {})),
              })
            }
          >
            <br />
            <div
              style={{
                marginBottom: 10,
              }}
            >
              <FormControl variant="standard">
                <FormLabel component="legend">
                  Are we including abstention?
                </FormLabel>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={resolutions.including_abstention}
                        onChange={(e) =>
                          setResolutions(
                            produce((draft) => {
                              draft.including_abstention = e.target.checked;
                            }),
                          )
                        }
                        name="abstention"
                      />
                    }
                    label={
                      resolutions.including_abstention
                        ? "Including abstention"
                        : "Not including abstention"
                    }
                  />
                </FormGroup>
              </FormControl>
            </div>
            <div style={{ display: "flex", alignItems: "center", gap: 20 }}>
              {/* If the selected assembly has resolutions */}
              <SimpleSelect
                disabled={!resosBoarddata.length}
                width="100%"
                onChange={(_, newVal) => {
                  if (!newVal) return;
                  setResoToEdit({ from_boarddata: true, ...newVal });
                  if (resolutions.resolutions.some((e) => e.uid === newVal.uid))
                    return;
                  setVoteResultDialogOpen(true);
                }}
                renderOption={(props, option) => (
                  <li {...props} key={crypto.randomUUID()}>
                    {`${option.number_or_letter} - ${option.resolution_type?.title}`}
                  </li>
                )}
                options={resolutionsOptions}
              />
              {/* If the user wants to add resolutions*/}
              <AddCircleOutlineIcon
                onClick={() => {
                  // setIsNewReso(true);
                  setResoToEdit({
                    uid: uuidv4(),
                    title: "",
                    shareholder_proposal: false,
                    proposed_at_agm: false,
                    is_dissenting: false,
                    is_correction: false,
                    // ----S1----
                    score: null, //or number
                    // ----S2---
                    is_for: null, //or number
                    against: null, // or number
                    abstention: null, // or number
                    // ----S3---
                    accepted: false,
                    rejected: false,
                    // ----Other--
                    position: null, //or number
                    number_or_letter: null, //or number
                    resolution_type: null,
                    from_boarddata: false,
                  });
                  setVoteResultDialogOpen(true);
                }}
                style={{
                  fontSize: 30,
                  color: "green",
                  cursor: "pointer",
                  marginBottom: 20,
                }}
              />
            </div>
            <div style={{ backgroundColor: "#8fa6e616", borderRadius: 10 }}>
              {resolutions.resolutions.length > 0 && (
                <div>
                  {sortByPosition([...resolutions.resolutions]).map(
                    (reso, index) => (
                      <ResolutionItem
                        key={reso.uid}
                        resolution={reso}
                        resolutionTypes={resolutionTypes}
                        onClickDelete={() =>
                          setResolutions(
                            produce((draft) => {
                              draft.resolutions = draft.resolutions.filter(
                                (r) => r.uid !== reso.uid,
                              );
                            }),
                          )
                        }
                        onUpdateResolution={handleUpdateResolution}
                      />
                    ),
                  )}
                </div>
              )}
            </div>
            <br />
            <br />
          </DataSender>
          <StyledLabel border text={t("summary_template")} />
          <RichTextEditor
            value={summary.autogenerated}
            onChange={(e) => console.log(e)}
            disabled={true}
            style={{ backgroundColor: "#ededed" }}
          />
          <br />
          <br />
          <DataSender
            title={t("summary")}
            status={status.summary.state || status.summary}
            comment={status.summary.comment || ""}
            onStatusChange={(newStatus) => {
              if (
                cleanHtmlString(summary.report) !==
                cleanHtmlString(form.summary.report)
              ) {
                alert("Please update or reset data before changing status");
                return;
              }
              requestConfirm({
                title: confirmPrompter.changeStatus.title(t("summary")),
                description:
                  confirmPrompter.changeStatus.description(newStatus),
                displayComment: newStatus == "REJECTED",
                onConfirm: (comment) =>
                  toastChangeStatus(
                    meetingReportsService.updateMeetingReportSectionStatus(
                      report.id,
                      "summary",
                      newStatus,
                      comment,
                    ),
                  )
                    .then((res) => {
                      setStatus(
                        produce((draft) => {
                          draft.summary = {
                            state: newStatus,
                            comment: comment,
                          };
                        }),
                      );
                    })
                    .catch((err) => {
                      displayError(err.response?.data?.detail || err.message);
                    }),
              });
            }}
            onUpdate={() =>
              requestConfirm({
                title: confirmPrompter.updateSection.title,
                description: confirmPrompter.updateSection.description,
                onConfirm: () =>
                  toastUpdateSection(
                    meetingReportsService.updateMeetingReportSectionData(
                      report.id,
                      "summary",
                      summary,
                    ),
                  )
                    .then((res) => {
                      setForm(
                        produce((draft) => {
                          draft.summary = summary;
                        }),
                      );
                      automaticChangeStatusIfStudentUpdates("summary");
                    })
                    .catch((err) => {
                      displayError(err.response?.data?.detail || err.message);
                    }),
              })
            }
            onResetClicked={() =>
              requestConfirm({
                title: confirmPrompter.resetSection.title,
                description: confirmPrompter.resetSection.description,
                onConfirm: () =>
                  setSummary(
                    produce(cleanHtmlString(form.summary), (draft) => {}),
                  ),
              })
            }
          >
            <RichTextEditor
              value={summary.report}
              onChange={(newSummary) => {
                setSummary(
                  produce((draft) => {
                    draft.report = newSummary;
                  }),
                );
              }}
            />
            <br />
            <br />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
                gap: 10,
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                {(questions.questions.length > 0 &&
                  (!questions.question_start || !questions.question_end)) ||
                !assembly.date_assembly ||
                !company ? (
                  <>
                    <span style={{ color: "red" }}>*</span>{" "}
                    <span
                      style={{
                        fontSize: 13,
                        fontWeight: "bold",
                        color: "red",
                      }}
                    >
                      {t("Check required fieds")}
                    </span>
                  </>
                ) : null}
              </div>
            </div>
          </DataSender>
          <hr style={{ margin: "20px 0" }} />
          <br />{" "}
          <DataSender
            title={t("assembly_files")}
            status={status.files.state || status.files}
            comment={status.files.comment || ""}
            onStatusChange={(newStatus) => {
              if (JSON.stringify(files) !== JSON.stringify(form.files)) {
                alert("Please update or reset data before changing status");
                return;
              }
              requestConfirm({
                title: confirmPrompter.changeStatus.title(t("assembly_files")),
                description:
                  confirmPrompter.changeStatus.description(newStatus),
                displayComment: newStatus == "REJECTED",
                onConfirm: (comment) =>
                  toastChangeStatus(
                    meetingReportsService.updateMeetingReportSectionStatus(
                      report.id,
                      "files",
                      newStatus,
                      comment,
                    ),
                  )
                    .then((res) => {
                      setStatus(
                        produce((draft) => {
                          draft.files = { state: newStatus, comment: comment };
                        }),
                      );
                    })
                    .catch((err) => {
                      displayError(err.response?.data?.detail || err.message);
                    }),
              });
            }}
            onResetClicked={() => alert("you can't reset this part")}
            onUpdate={() =>
              requestConfirm({
                title: confirmPrompter.updateSection.title,
                description: confirmPrompter.updateSection.description,
                onConfirm: () =>
                  toastUpdateSection(
                    meetingReportsService.updateMeetingReportSectionData(
                      report.id,
                      "files",
                      files,
                    ),
                  )
                    .then((res) => {
                      setForm(
                        produce((draft) => {
                          draft.files = files;
                        }),
                      );
                      automaticChangeStatusIfStudentUpdates("files");
                    })
                    .catch((err) => {
                      displayError(err.response?.data?.detail || err.message);
                    }),
              })
            }
          >
            <MeetingReportFiles
              meeting_id={report.id}
              filesMetas={files.metas || []}
              handleFilesMetasChange={(filesMetas) => {
                setFiles(
                  produce((draft) => {
                    draft.metas = filesMetas;
                  }),
                );
              }}
            />
          </DataSender>
          <br />
        </Box>
      </Container>
    </div>
  );
};

export default MeetingReport;
