import { TimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import frLocale from "date-fns/locale/fr";

import TextField from "@mui/material/TextField";

const TimePickerComponent = ({ time, onChange }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={frLocale}>
      <TimePicker
        value={time}
        onChange={onChange}
        renderInput={(params) => (
          <TextField
            {...params}
            sx={{ width: "auto", marginBottom: 5 }}
            error={false}
          />
        )}
      />
    </LocalizationProvider>
  );
};

export default TimePickerComponent;
