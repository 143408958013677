import React, { useState } from "react";
import ProceedingThemesSelect from "./presentations/ProceedingThemesSelect";
import PresentationDialog from "./presentations/PresentationDialog";

const SearchInputDialog = ({ onAddPresentation, options }) => {
  const [open, toggleOpen] = useState(false);
  const [dialogValue, setDialogValue] = useState("");

  const handleClose = (e, reason) => {
    if (reason === "backdropClick") return;
    setDialogValue("");
    toggleOpen(false);
  };

  const handleThemeChange = (_, newVal) => {
    if (newVal) {
      toggleOpen(true);
      setDialogValue(newVal);
    }
  };

  const handleAddPresentation = (presentation) => {
    onAddPresentation(presentation);
    setDialogValue({});
    handleClose();
  };

  return (
    <>
      <ProceedingThemesSelect
        value={dialogValue}
        instructions="Click here to add a new presentation"
        onChange={handleThemeChange}
        options={options}
      />
      <PresentationDialog
        open={open}
        handleClose={handleClose}
        dialogValue={dialogValue}
        onAddPresentation={handleAddPresentation}
      />
    </>
  );
};

export default SearchInputDialog;
