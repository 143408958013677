import React, { useState, useEffect, useMemo } from "react";
import { produce } from "immer";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {
  Typography,
  Input,
  Switch,
  FormControl,
  InputLabel,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Button,
  LinearProgress,
  TextField,
  Autocomplete,
} from "@mui/material";
import AuthorizeForRoles from "../AuthorizeForRoles";
import { Role } from "../../data/roles";
import { toastErrorHelper } from "../Toaster";
import utilService from "../../services/investigate/util-service";

function ResolutionItem({
  resolution,
  resolutionTypes,
  onUpdateResolution,
  onClickDelete,
}) {
  const [autoDetectedType, setAutoDetectedType] = useState(null);
  const [isAutoDetectingType, setIsAutoDetectingType] = useState(false);
  const [reso, setReso] = useState({
    uid: resolution.uid,
    title: resolution.title,
    is_correction: resolution.is_correction,
    proposed_at_agm: resolution.proposed_at_agm,
    // ----S1----
    score: resolution.score,
    // ----S2---
    is_for: resolution.is_for,
    against: resolution.against,
    abstention: resolution.abstention || resolution.absentation || null,
    // ----S3---
    accepted: resolution.accepted,
    rejected: resolution.rejected,
    // ----Other--
    position: resolution.position,
    number_or_letter: resolution.number_or_letter,
    resolution_type: resolution.resolution_type,
    is_dissenting: resolution.is_dissenting,
    withdrawn_before_agm: resolution.withdrawn_before_agm,
  });
  const resoTitle = useMemo(
    () =>
      reso.title || resolution.resolution_type?.title || "Unnamed Resolution",
    []
  );
  // Update the resolution's information when the state changes.
  useEffect(() => {
    onUpdateResolution(reso);
  }, [reso]);
  const handleDetectTypeClick = async () => {
    setIsAutoDetectingType(true);
    const resolutionName = resolution.title || "Unnamed Resolution";
    utilService
      .classifyResolution(resolutionName)
      .then((res) => {
        setIsAutoDetectingType(false);
        setAutoDetectedType(res.data.resolution_type);
      })
      .catch((err) => {
        setIsAutoDetectingType(false);
        alert("Failed to fetch company list");
      });
  };
  return (
    <div
      style={{
        border: "1px solid lightgray",
        padding: 5,
        backgroundColor: "#F1F1F1",
        borderRadius: 5,
        marginBottom: 10,
      }}
    >
      {/* <ToastContainer /> */}
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Typography
            sx={{
              color: "#064c3b",
            }}
            variant="h6"
          >
            {reso.number_or_letter} -{resoTitle}
          </Typography>
          <DeleteOutlineIcon
            onClick={onClickDelete}
            fontSize="small"
            style={{ color: "crimson" }}
          />
        </div>
      </div>
      <AuthorizeForRoles
        roles={[Role.SCALENS_ANALYST_ADMIN, Role.SCALENS_SUPER_ADMIN]}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <FormControl
            sx={{
              width: "80%",
            }}
          >
            <Autocomplete
              value={
                resolutionTypes.find(
                  (type) => type.uid === reso.resolution_type?.uid
                ) || null
              }
              onChange={(event, newValue) => {
                setReso((currentState) =>
                  produce(currentState, (draftState) => {
                    draftState.resolution_type = newValue;
                  })
                );
              }}
              options={resolutionTypes}
              getOptionLabel={(option) =>
                `${option.title}-${option.category}(${option.majority}%)`
              }
              renderInput={(params) => (
                <TextField {...params} label="Resolution Type" />
              )}
            />
          </FormControl>
          <div
            style={{
              display: "flex",
              alignItems: "center" /* aligns vertically center */,
              justifyContent: "center" /* aligns horizontally center */,
            }}
          >
            <Button
              disabled={!!reso.resolution_type}
              variant="contained"
              onClick={handleDetectTypeClick}
            >
              Detect Type
            </Button>
          </div>
        </div>
        {isAutoDetectingType && <LinearProgress />}
        {autoDetectedType && (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <p
              style={{
                color: "Highlight",
              }}
            >
              Detected Type: {autoDetectedType}
            </p>
            <div
              style={{
                display: "flex",
                alignItems: "center" /* aligns vertically center */,
                justifyContent: "center" /* aligns horizontally center */,
              }}
            >
              <Button
                style={{ marginLeft: 10 }}
                color="success"
                size="small"
                variant="contained"
                onClick={() => {
                  const foundResolutionType = resolutionTypes.find(
                    (resolutionType) =>
                      resolutionType.title
                        .toLowerCase()
                        .includes(autoDetectedType.toLowerCase())
                  );
                  if (!foundResolutionType) {
                    toastErrorHelper("Type does not exist in boarddata");
                  } else {
                    setReso((currentState) =>
                      produce(currentState, (draftState) => {
                        draftState.resolution_type = foundResolutionType;
                      })
                    );
                  }
                }}
              >
                Assign Type
              </Button>
            </div>
          </div>
        )}
        <br />
        <div>
          <InputLabel
            sx={{
              color: "#064c3b",
              display: "inline",
              marginRight: "10px",
            }}
          >
            Number Or Letter:
          </InputLabel>
          <Input
            type="text"
            value={reso.number_or_letter}
            onChange={(e) =>
              setReso((currentState) =>
                produce(currentState, (draftState) => {
                  draftState.number_or_letter = e.target.value || null;
                })
              )
            }
          />
        </div>
        <hr style={{ margin: "20px 0" }} />
      </AuthorizeForRoles>
      <div>
        <div>
          <InputLabel
            sx={{
              color: "#064c3b",
              display: "inline",
              marginRight: "10px",
            }}
          >
            Score (%):
          </InputLabel>
          <Input
            type="number"
            value={reso.score}
            onChange={(e) =>
              setReso((currentState) =>
                produce(currentState, (draftState) => {
                  draftState.score = parseInt(e.target.value) || null;
                })
              )
            }
          />
        </div>
      </div>
      <hr style={{ margin: "20px 0" }} />
      <div>
        <div>
          <InputLabel
            sx={{
              color: "#064c3b",
              display: "inline",
              marginRight: "10px",
            }}
          >
            For (Actions):
          </InputLabel>
          <Input
            type="number"
            value={reso.is_for}
            onChange={(e) =>
              setReso((currentState) =>
                produce(currentState, (draftState) => {
                  draftState.is_for = parseInt(e.target.value) || null;
                })
              )
            }
          />
        </div>
        <div>
          <InputLabel
            sx={{
              color: "#064c3b",
              display: "inline",
              marginRight: "10px",
            }}
          >
            Against (Actions):
          </InputLabel>
          <Input
            type="number"
            value={reso.against}
            onChange={(e) =>
              setReso((currentState) =>
                produce(currentState, (draftState) => {
                  draftState.against = parseInt(e.target.value) || null;
                })
              )
            }
          />
        </div>
        <div>
          <InputLabel
            sx={{
              color: "#064c3b",
              display: "inline",
              marginRight: "10px",
            }}
          >
            Abstention (Actions)
          </InputLabel>
          <Input
            type="number"
            value={reso.abstention}
            onChange={(e) =>
              setReso((currentState) =>
                produce(currentState, (draftState) => {
                  draftState.abstention = parseInt(e.target.value) || null;
                })
              )
            }
          />
        </div>
      </div>
      <hr style={{ margin: "20px 0" }} />
      <div>
        <div>
          <FormControl
            component="fieldset"
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <FormLabel
              component="legend"
              style={{
                color: "#064c3b",
                display: "inline",
                marginRight: "10px",
              }}
            >
              Status:
            </FormLabel>
            <RadioGroup
              aria-label="status"
              name="status"
              value={
                reso.accepted ? "ACCEPTED" : reso.rejected ? "REJECTED" : ""
              }
              onChange={(e) =>
                setReso((currentState) =>
                  produce(currentState, (draftState) => {
                    draftState.accepted = e.target.value === "ACCEPTED";
                    draftState.rejected = e.target.value === "REJECTED";
                  })
                )
              }
              row
            >
              <FormControlLabel
                value="ACCEPTED"
                control={<Radio />}
                label="Accepted"
              />
              <FormControlLabel
                value="REJECTED"
                control={<Radio />}
                label="Rejected"
              />
            </RadioGroup>
          </FormControl>
        </div>
      </div>
      <hr style={{ margin: "20px 0" }} />
      <AuthorizeForRoles
        roles={[Role.SCALENS_ANALYST_ADMIN, Role.SCALENS_SUPER_ADMIN]}
      >
        {/* <div>
          <InputLabel
            sx={{
              color: "#064c3b",
              display: "inline",
              marginRight: "10px",
            }}
          >
            Position:
          </InputLabel>
          <Input
            type="number"
            value={reso.position}
            onChange={(e) =>
              setReso((currentState) =>
                produce(currentState, (draftState) => {
                  draftState.position = parseInt(e.target.value) || null;
                })
              )
            }
          />
        </div> */}
      </AuthorizeForRoles>
      <div>
        <Typography
          sx={{
            color: "#064c3b",
            display: "inline",
            marginRight: "10px",
          }}
          variant="body1"
          component="div"
        >
          Proposed by Company Board at the AGM
        </Typography>
        <Switch
          checked={reso.proposed_at_agm || false}
          onChange={() =>
            setReso((currentState) =>
              produce(currentState, (draftState) => {
                draftState.proposed_at_agm = !currentState.proposed_at_agm;
              })
            )
          }
        />
      </div>
      <div>
        <Typography
          sx={{
            color: "#064c3b",
            display: "inline",
            marginRight: "10px",
          }}
          variant="body1"
          component="div"
        >
          Analyst catch up
        </Typography>
        <Switch
          checked={reso.is_correction || false}
          onChange={() =>
            setReso((currentState) =>
              produce(currentState, (draftState) => {
                draftState.is_correction = !currentState.is_correction;
              })
            )
          }
        />
      </div>
      <div>
        <Typography
          sx={{
            color: "#064c3b",
            display: "inline",
            marginRight: "10px",
          }}
          variant="body1"
          component="div"
        >
          Dissenting
        </Typography>
        <Switch
          checked={reso.is_dissenting || false}
          onChange={() =>
            setReso((currentState) =>
              produce(currentState, (draftState) => {
                draftState.is_dissenting = !currentState.is_dissenting;
              })
            )
          }
        />
      </div>
      <hr style={{ margin: "20px 0" }} />
      <div>
        <Typography
          sx={{
            color: "#064c3b",
            display: "inline",
            marginRight: "10px",
          }}
          variant="body1"
          component="div"
        >
          Withdrawn Before AGM
        </Typography>
        <Switch
          checked={reso.withdrawn_before_agm || false}
          onChange={() =>
            setReso((currentState) =>
              produce(currentState, (draftState) => {
                draftState.withdrawn_before_agm =
                  !currentState.withdrawn_before_agm;
              })
            )
          }
        />
      </div>
    </div>
  );
}

export default ResolutionItem;
