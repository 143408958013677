import { InputAdornment } from "@mui/material";
import TextField from "@mui/material/TextField";

const ControlledInput = ({
  value,
  width = "100%",
  onChange,
  handleBlur,
  type,
  placeholder,
  adornment,
  flex,
  readOnly,
  bold,
}) => {
  return (
    <TextField
      type={type}
      value={value}
      onChange={onChange}
      onBlur={handleBlur}
      placeholder={placeholder}
      sx={{
        width,
        flex,
        fontWeight: "600 !important",
      }}
      inputProps={{
        style: {
          fontSize: "1rem",
          fontWeight: bold && 600,
          color: "#001756",
          padding: "0.5rem 1rem",
        },
      }}
      InputProps={{
        readOnly,
        endAdornment: (
          <InputAdornment position="end">{adornment}</InputAdornment>
        ),
      }}
    />
  );
};

export default ControlledInput;
