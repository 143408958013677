import React, { useState, useEffect } from "react";
import peerService from "../../services/investigate/peer-service";
import {
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
  Box,
} from "@mui/material";
import { toastCreatePeer } from "../../components/Toaster";
import { useConfirmationDialog } from "../../providers/ConfirmationDialogProvider";
import { useAlert } from "../../providers/AlertProvider";

const PeerCreate = () => {
  const [peer, setPeer] = useState({
    name: "",
    isin: "", // Added 'isin' field
    verified: true,
    code: "",
    exchange: "",
    country: "",
    currency: "",
  });
  const { requestConfirm } = useConfirmationDialog();
  const { showAlert: displayError } = useAlert();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPeer((prevData) => ({ ...prevData, [name]: value }));
  };
  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setPeer((prevData) => ({ ...prevData, [name]: checked }));
  };

  const handleCreate = async () => {
    requestConfirm({
      title: "Creating new Peer",
      description: "Are you sure you want to create this peer?",
      onConfirm: () =>
        toastCreatePeer(peerService.createPeer(peer))
          .then((resp) => {
            console.log(resp);
          })
          .catch((err) => {
            displayError(err.response?.data?.detail || err.message);
          }),
    });
  };

  return (
    <>
      <Box>
        <TextField
          label="Name"
          name="name"
          value={peer.name}
          onChange={handleChange}
          fullWidth
        />
        <TextField
          label="ISIN"
          name="isin"
          value={peer.isin}
          onChange={handleChange}
          fullWidth
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={peer.verified}
              onChange={handleCheckboxChange}
              name="verified"
              color="primary"
            />
          }
          label="Verified"
        />
        <TextField
          label="Code"
          name="code"
          value={peer.code}
          onChange={handleChange}
          fullWidth
        />
        <TextField
          label="Exchange"
          name="exchange"
          value={peer.exchange}
          onChange={handleChange}
          fullWidth
        />
        <TextField
          label="Country"
          name="country"
          value={peer.country}
          onChange={handleChange}
          fullWidth
        />
        <TextField
          label="Currency"
          name="currency"
          value={peer.currency}
          onChange={handleChange}
          fullWidth
        />
        <Box mt={2}>
          <Button onClick={handleCreate} variant="contained" color="primary">
            Create
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default PeerCreate;
