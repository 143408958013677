import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Container,
  Card,
  CardContent,
  Typography,
  Box,
  Chip,
  Divider,
  Stack,
  Grid,
  Paper,
  Button,
} from "@mui/material";
import { getStatusColor } from "../../utils";
import Loading from "../../components/Loading";
import newsService from "../../services/boarddata/news-service";
import { useAlert } from "../../providers/AlertProvider";

const NewsDetailPage = () => {
  const { id } = useParams();
  const [newsItem, setNewsItem] = useState(null);
  const { showAlert: displayError } = useAlert();

  useEffect(() => {
    newsService
      .getOneNewsItem(id)
      .then((resp) => {
        setNewsItem(resp.data);
      })
      .catch((err) => {
        displayError(err.response?.data?.detail || err.message);
      });
  }, [id, displayError]);

  if (!newsItem) return <Loading />;
  else return <RenderNewsItem newsItem={newsItem} />;
};

const RenderNewsItem = ({ newsItem }) => {
  const statusColor = getStatusColor(newsItem.status);
  const navigate = useNavigate();
  const handleEdit = () => {
    navigate(`/news/edit/${newsItem.id}`);
  };
  return (
    <Container maxWidth="md" sx={{ marginTop: 4 }}>
      <Card elevation={3}>
        <CardContent>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
          >
            <Typography variant="h4" component="h1" gutterBottom>
              {newsItem.title}
            </Typography>
            <Chip
              label={newsItem.status}
              sx={{
                backgroundColor: statusColor,
                color: "white",
              }}
            />
          </Box>

          <Typography variant="body2" color="textSecondary" gutterBottom>
            {newsItem.date_news}
          </Typography>

          <Stack
            direction="row"
            spacing={1}
            mb={2}
            flexWrap="wrap"
            alignItems="center"
          >
            {newsItem.themes.map((theme) => (
              <Chip
                key={theme.key}
                label={theme.label}
                sx={{ margin: "4px" }}
              />
            ))}
          </Stack>

          <Divider sx={{ marginY: 2 }} />

          <Typography
            variant="body1"
            component="div"
            gutterBottom
            sx={{ whiteSpace: "pre-line" }}
            dangerouslySetInnerHTML={{ __html: newsItem.news }}
          />

          <Divider sx={{ marginY: 2 }} />

          <Grid container spacing={2} mt={2}>
            <Grid item xs={12} md={6}>
              <Paper elevation={1} sx={{ padding: 2 }}>
                <Typography variant="h6" gutterBottom>
                  Persons
                </Typography>
                <Stack
                  direction="row"
                  spacing={1}
                  flexWrap="wrap"
                  alignItems="center"
                >
                  {newsItem.persons.map((person) => (
                    <Chip
                      key={person.id}
                      label={person.name}
                      sx={{ margin: "4px" }}
                    />
                  ))}
                </Stack>
              </Paper>
            </Grid>

            <Grid item xs={12} md={6}>
              <Paper elevation={1} sx={{ padding: 2 }}>
                <Typography variant="h6" gutterBottom>
                  Companies
                </Typography>
                <Stack
                  direction="row"
                  spacing={1}
                  flexWrap="wrap"
                  alignItems="center"
                >
                  {newsItem.companies.map((company) => (
                    <Chip
                      key={company.id}
                      label={company.name}
                      sx={{ margin: "4px" }}
                    />
                  ))}
                </Stack>
              </Paper>
            </Grid>
          </Grid>
          <Box display="flex" justifyContent="flex-end" mt={3}>
            <Button variant="contained" color="primary" onClick={handleEdit}>
              Edit
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
};

export default NewsDetailPage;
