import React from "react";
import { ReactSortable } from "react-sortablejs";
import StyledLabel from "../StyledLabel";
import { t } from "i18next";
import SearchInputDialog from "../SearchInputDialog";
import PresentationItem from "./PresentationItem";

const Presentations = ({
  presentationsThemes,
  presentations,
  onAddPresentation,
  onEditPresentation,
  onDeletePresentation,
  list,
  setList,
}) => {
  return (
    <div>
      <StyledLabel border text={t("presentations")} />
      <br />
      <SearchInputDialog
        options={presentationsThemes}
        onAddPresentation={onAddPresentation}
      />
      <br />
      <ReactSortable
        filter=".addImageButtonContainer"
        dragClass="sortableDrag"
        list={list}
        setList={setList}
        animation="200"
        easing="ease-out"
      >
        {presentations.map((expo) => (
          <PresentationItem
            expo={expo}
            presentations={presentations}
            onClickEdit={() => onEditPresentation(expo)}
            onClickDelete={() => onDeletePresentation(expo)}
          />
        ))}
      </ReactSortable>
    </div>
  );
};

export default Presentations;
