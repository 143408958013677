import { apiClientInvestigate } from "../api-client";

class WeeklyService {
  uploadWeeklyFile(newFile, newFileName, newWeekDate) {
    let formData = new FormData();
    formData.append("file", newFile);
    const params = {
      modified_filename: newFileName,
      week_date: newWeekDate,
    };
    return apiClientInvestigate.post(`/weeklies/upload`, formData, {
      params,
    });
  }
  getAllWeeklyFiles() {
    return apiClientInvestigate.get("/weeklies");
  }
  getDownloadWeeklyFileLink(file_url) {
    return `${apiClientInvestigate.getUri()}/weeklies/${file_url}/download`;
  }
  updateWeeklyFile(uid, updatedValues) {
    return apiClientInvestigate.patch(
      `/weeklies/${uid}/metadata`,
      updatedValues
    );
  }
  deleteWeeklyFile(uid) {
    return apiClientInvestigate.delete(`/weeklies/${uid}`);
  }
}

export default new WeeklyService();
