import { useAuth0 } from "@auth0/auth0-react";
import { Role } from "../data/roles";
export const useUserRole = () => {
  const { user } = useAuth0();
  const userRoles =
    user && user["https://api.praexo.com/roles"]
      ? user["https://api.praexo.com/roles"]
      : [];
  if (userRoles.includes(Role.SCALENS_SUPER_ADMIN))
    return Role.SCALENS_SUPER_ADMIN;
  else if (userRoles.includes(Role.SCALENS_ANALYST_ADMIN))
    return Role.SCALENS_SUPER_ADMIN;
  else if (userRoles.includes(Role.SCALENS_EXTERNAL_STUDENT))
    return Role.SCALENS_EXTERNAL_STUDENT;
  return null;
};

export default useUserRole;
