export const reports = [
  {
    label: "No postponement, held in 1st convocation",
    value: "No postponement, held in 1st convocation",
  },
  { label: "Held in 2d convocation", value: "Held in 2d convocation" },
  {
    label: "Postponed for other reasons",
    value: "Postponed for other reasons",
  },
];
