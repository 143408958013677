import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { t } from "i18next";
import Dialog from "@mui/material/Dialog";
import StyledLabel from "../StyledLabel";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import TimePickerComponent from "../TimePickerComponent";
import Typography from "@mui/material/Typography";
import SimpleInput from "../SimpleInput";
import MultilineInput from "../MultilineInput";
import CloseIcon from "@mui/icons-material/Close";
import ProceedingThemesSelect from "./ProceedingThemesSelect";

const PresentationDialog = ({
  open,
  handleClose,
  dialogValue,
  proceedingsThemes,
  isEditing,
  presentation,
  onAddPresentation,
  onUpdatePresentation,
}) => {
  const [theme, setTheme] = useState(null);
  const [speaker, setSpeaker] = useState("");
  const [highlights, setHighLights] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const handleSpeaker = (e) => setSpeaker(e.target.value);
  const handleHighlights = (e) => setHighLights(e.target.value);
  const handleStartTime = (e) => {
    setStartTime(e);
  };
  const handleEndTime = (e) => {
    setEndTime(e);
  };

  useEffect(() => {
    if (isEditing) {
      setSpeaker(presentation?.speaker);
      setHighLights(presentation?.highlights);
      setStartTime(dayjs(presentation?.start));
      setEndTime(dayjs(presentation?.end));
      setTheme(presentation?.theme);
    }
  }, [presentation, isEditing]);

  const onSendPresentation = () => {
    if (isEditing)
      onUpdatePresentation({
        ...presentation,
        theme: theme?.theme || presentation?.theme,
        theme_id: theme?.id || presentation?.theme_id,
        start: startTime,
        end: endTime,
        duration: dayjs(endTime).diff(startTime, "minute"),
        speaker,
        highlights,
      });
    if (!isEditing) {
      onAddPresentation({
        uid: crypto.randomUUID(),
        theme: dialogValue.theme,
        start: startTime,
        end: endTime,
        duration: dayjs(endTime).diff(startTime, "minute"),
        speaker,
        theme_id: dialogValue.id,
        highlights,
      });
      setSpeaker("");
      setHighLights("");
      setStartTime("");
      setEndTime("");
    }
  };

  const handleThemeChange = (_, newVal) => {
    if (newVal) {
      setTheme(newVal);
    }
  };

  return (
    <Dialog fullWidth open={open} onClose={handleClose}>
      <form>
        <div style={{ padding: 15 }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {isEditing ? (
              <StyledLabel border text={t("edit_expo")} />
            ) : (
              <StyledLabel border text={t("add_expo")} />
            )}
            <CloseIcon
              style={{ color: "#064c3b", cursor: "pointer" }}
              onClick={() => handleClose()}
            />
          </div>
          <br />
          {isEditing ? (
            <ProceedingThemesSelect
              label="Theme"
              instructions="Select a theme"
              value={theme?.theme || presentation?.theme}
              onChange={handleThemeChange}
              options={proceedingsThemes}
            />
          ) : (
            <Typography
              sx={{
                color: "#064c3b",
              }}
              variant="h6"
              gutterBottom
              component="div"
            >
              {dialogValue?.theme}
            </Typography>
          )}
        </div>
        <DialogContent>
          <div style={{ display: "flex", gap: 30 }}>
            <div>
              <StyledLabel text={t("start")} />
              <TimePickerComponent
                time={startTime}
                onChange={handleStartTime}
              />
            </div>
            <div>
              <StyledLabel text={t("end")} />
              <TimePickerComponent time={endTime} onChange={handleEndTime} />
            </div>
          </div>
          <div>
            <StyledLabel text={t("speaker")} />
            <SimpleInput value={speaker} onChange={handleSpeaker} />
          </div>
          <div>
            <StyledLabel text={t("highlights")} />
            <MultilineInput value={highlights} onChange={handleHighlights} />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={!startTime || !endTime}
            onClick={onSendPresentation}
            sx={{ marginTop: -5 }}
          >
            {isEditing ? t("update") : t("add_expo")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default PresentationDialog;
