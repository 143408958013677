import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import RichText from "./RichTextEditor/RichText";

const ConfirmationDialog = ({
  open,
  onClose,
  onConfirm,
  title,
  description,
  displayComment,
}) => {
  const [comment, setComment] = useState("");
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title || "Please Confirm"}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {description || "Do you want to perform this action?"}
        </DialogContentText>
        {displayComment && (
          <div style={{ marginTop: "2px" }}>
            <DialogContentText>{"Add a comment (Optional):"}</DialogContentText>
            <RichText text={comment} setText={setComment} />
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={() => {
            onConfirm(comment);
            setComment("");
          }}
          color="primary"
          autoFocus
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
