import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
  Box,
} from "@mui/material";

import Loading from "../../components/Loading";
import peerService from "../../services/investigate/peer-service";
import { useConfirmationDialog } from "../../providers/ConfirmationDialogProvider";
import { toastUpdatePeer } from "../../components/Toaster";
import { useAlert } from "../../providers/AlertProvider";

const PeerEdit = () => {
  const { id } = useParams();
  const [peer, setPeer] = useState(null);
  const { showAlert: displayError } = useAlert();

  useEffect(() => {
    peerService
      .getOnePeer(id)
      .then((resp) => {
        setPeer(resp.data);
      })
      .catch((err) => {
        displayError(err.response?.data?.detail || err.message);
      });
  }, []);

  if (!peer) return <Loading />;
  else
    return (
      <>
        <RenderPeerEdit peer={peer} />
      </>
    );
};

const RenderPeerEdit = ({ peer }) => {
  const { showAlert: displayError } = useAlert();
  const { requestConfirm } = useConfirmationDialog();
  const [editedPeer, setEditedPeer] = useState({
    name: peer.name,
    isin: peer.isin,
    verified: peer.verified,
    code: peer.code || "",
    exchange: peer.exchange || "",
    country: peer.country || "",
    currency: peer.currency || "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedPeer((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setEditedPeer((prevData) => ({ ...prevData, [name]: checked }));
  };

  const handleUpdate = async () => {
    requestConfirm({
      title: "Updating Peer",
      description: "Are you sure you want to update this peer?",
      onConfirm: () =>
        toastUpdatePeer(peerService.updatePeer(peer.id, editedPeer))
          .then((res) => console.log(res))
          .catch((err) =>
            displayError(err.response?.data?.detail || err.message)
          ),
    });
  };

  return (
    <>
      <Box>
        <TextField
          label="Name"
          name="name"
          value={editedPeer.name}
          onChange={handleChange}
          fullWidth
        />
        <TextField
          label="ISIN"
          name="isin"
          value={editedPeer.isin}
          onChange={handleChange}
          fullWidth
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={editedPeer.verified}
              onChange={handleCheckboxChange}
              name="verified"
              color="primary"
            />
          }
          label="Verified"
        />
        <TextField
          label="Code"
          name="code"
          value={editedPeer.code}
          onChange={handleChange}
          fullWidth
        />
        <TextField
          label="Exchange"
          name="exchange"
          value={editedPeer.exchange}
          onChange={handleChange}
          fullWidth
        />
        <TextField
          label="Country"
          name="country"
          value={editedPeer.country}
          onChange={handleChange}
          fullWidth
        />
        <TextField
          label="Currency"
          name="currency"
          value={editedPeer.currency}
          onChange={handleChange}
          fullWidth
        />
        <Box mt={2}>
          <Button onClick={handleUpdate} variant="contained" color="primary">
            Update
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default PeerEdit;
