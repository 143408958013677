import * as React from "react";
import { alpha, styled } from "@mui/material/styles";
import { GridToolbarContainer, GridPagination } from "@mui/x-data-grid";
import { Card, CardContent, Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Title, TextField } from "react-admin";
import { toastDeletePeer } from "../../components/Toaster";
import {
  DataGrid,
  gridClasses,
  GridToolbar,
  getGridStringOperators,
} from "@mui/x-data-grid";
import Loading from "../../components/Loading";
import peerService from "../../services/investigate/peer-service";
import { useAlert } from "../../providers/AlertProvider";
import { useConfirmationDialog } from "../../providers/ConfirmationDialogProvider";
const PeersDisplay = () => {
  //----------------- Hooks-------------------
  const { showAlert: displayError } = useAlert();
  const { requestConfirm } = useConfirmationDialog();
  const navigate = useNavigate();
  //-----------------FOR DATAGRID-------------------
  const columns = React.useRef(null);
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [filterModel, setFilterModel] = React.useState({
    items: [],
  });
  const [sortModel, setSortModel] = React.useState([
    { field: "id", sort: "desc" },
  ]);
  const [peers, setPeers] = React.useState([]);
  const [rowCount, setRowCount] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  //-----------------Functions to call apis--------------------
  const fetchPeers = async () => {
    setLoading(true);
    const filters = filterModel.items.reduce((acc, item) => {
      acc["filter_field"] = item.columnField;
      acc["filter_value"] = item.value;
      return acc;
    }, {});
    const sortField = sortModel[0].field;
    const sortOrder = sortModel[0].sort;
    peerService
      .getManyPeers(page, pageSize, sortField, sortOrder, filters)
      .then((resp) => {
        setPeers(resp.data.items);
        setRowCount(resp.data.total);
      })
      .catch((err) => displayError(err.response?.data?.detail || err.message))
      .finally(() => {
        setLoading(false);
      });
  };
  const handleDeletePeer = (id) => {
    requestConfirm({
      title: "Delete Peer",
      description: "Are you sure you want to delete this peer?",
      onConfirm: () =>
        toastDeletePeer(peerService.deletePeer(id))
          .then((resp) => {
            setPeers(peers.filter((peer) => peer.id != id));
          })
          .catch((err) => {
            displayError(err.response?.data?.detail || err.message);
          }),
    });
  };
  //-----------------Local functions --------------------
  const handleFilterChange = (model) => {
    const filters = model.items.reduce((acc, item) => {
      acc[item.columnField] = item.value;
      return acc;
    }, {});
    console.log("Filters: ", filters);
    setFilterModel(model);
  };
  //-----------------Startup Effects --------------------
  React.useEffect(() => {
    fetchPeers();
  }, [page, pageSize, JSON.stringify(filterModel), JSON.stringify(sortModel)]);

  //------------------ Rendering When no data -------------------------
  if (rowCount == null) {
    return <Loading />;
  }
  //------------------ Rendering When we have data -------------------------
  //------------------Define the rows after we have data-------------------------
  const rows = peers.map((peer) => ({
    id: peer.id,
    name: peer.name,
    isin: peer.isin,
    country: peer.country,
    verified: peer.verified,
  }));
  //------------------Define the columns based on the row keys -------------------------
  if (!columns.current && rows.length != 0) {
    // Data columns
    columns.current = Object.keys(rows[0]).map((key) => {
      const column = {
        field: key,
        headerName: key.replace(/_/g, " ").toUpperCase(),
        filterOperators:
          key == "id"
            ? getGridStringOperators().filter((op) =>
                ["equals"].includes(op.value)
              )
            : getGridStringOperators().filter((op) =>
                ["contains"].includes(op.value)
              ),
        width: 200,
      };
      return column;
    });
    // Add the edit column
    columns.current.push({
      field: "Edit",
      headerName: "EDIT",
      sortable: false,
      filterable: false,
      // width: 150,
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          console.log("params: ", params);
          // window.open(`/#/meeting-reports/edit/${params.id}`, '_blank');
          navigate(`/peers/edit/${params.id}`, {
            // state: {
            //   report: periodicResults.find((item) => item.id === params.id),
            // },
          });
        };

        return <Button onClick={onClick}>Edit</Button>;
      },
    });
    // Add the delete column
    columns.current.push({
      field: "delete",
      headerName: "DELETE",
      sortable: false,
      filterable: false,
      // width: 150,
      renderCell: (params) => {
        const onClickDelete = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          console.log("params: ", params);
          handleDeletePeer(params.id);
        };

        return (
          <Button color="error" onClick={onClickDelete}>
            Delete
          </Button>
        );
      },
    });
  }
  //------------------Add a custom pagination to display the create button -------------------------
  const CustomPagination = () => {
    const handleClick = () => {
      navigate("/peers/create");
    };

    return (
      <GridToolbarContainer
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={handleClick}
          style={{ margin: "0 0 0 10px" }}
        >
          Add New Peer
        </Button>
        <GridPagination />
      </GridToolbarContainer>
    );
  };
  return (
    <Card>
      <Title title="Peers" />
      <CardContent>
        <Box sx={{ height: "85vh", width: "100%" }}>
          <StripedDataGrid
            // style={{ width: "100%" }}
            columns={columns.current}
            rows={rows}
            components={{
              Pagination: CustomPagination,
            }}
            pagination
            pageSize={pageSize}
            rowCount={rowCount} // replace with total count from server
            paginationMode="server"
            onPageChange={(page) => setPage(page)}
            onPageSizeChange={(pageSize) => setPageSize(pageSize)}
            rowsPerPageOptions={[10, 25, 50]}
            loading={loading}
            onSortModelChange={(newModel) => setSortModel(newModel)}
            onFilterModelChange={handleFilterChange} // columnWidth={'30%'}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
            }
            slots={{ toolbar: GridToolbar }}
          />
        </Box>
      </CardContent>
    </Card>
  );
};
// --------------------define the custom toolbar-----------------------

const ODD_OPACITY = 0.2;

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],
    "&:hover, &.Mui-hovered": {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
    "&.Mui-selected": {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity
      ),
      "&:hover, &.Mui-hovered": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity
        ),
        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  },
}));
export default PeersDisplay;
