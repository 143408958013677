import * as React from "react";
import { alpha, styled } from "@mui/material/styles";
import { Card, CardContent, Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Title } from "react-admin";
import Chip from "@mui/material/Chip";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import CustomStatusFilter from "../../components/CustomStatusFilter";
import { useAlert } from "../../providers/AlertProvider";
import {
  DataGrid,
  gridClasses,
  GridToolbar,
  getGridStringOperators,
} from "@mui/x-data-grid";
import Loading from "../../components/Loading";
import meetingReportsService from "../../services/investigate/meeting-reports-service";
import utilService from "../../services/investigate/util-service";
import { useConfirmationDialog } from "../../providers/ConfirmationDialogProvider";
import { toastSuccessHelper, toastErrorHelper } from "../../components/Toaster";
import CustomDataGrid from "../../components/CustomDataGrid";

import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord"; // Using a dot as an indicator

const MeetingReports = () => {
  const { showAlert: displayError } = useAlert();
  const { requestConfirm } = useConfirmationDialog();
  //----------------- Hooks-------------------
  const navigate = useNavigate();
  //-----------------FOR DATAGRID-------------------
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [filterModel, setFilterModel] = React.useState({
    items: [],
  });
  const [sortModel, setSortModel] = React.useState([
    { field: "id", sort: "desc" },
  ]);
  const [meetingReports, setMeetingReports] = React.useState([]);
  const [rowCount, setRowCount] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  //-----------------Functions to call apis--------------------
  const fetchMeetingReports = async () => {
    setLoading(true);
    const filters = filterModel.items.reduce((acc, item) => {
      acc["filter_field"] = item.columnField;
      acc["filter_value"] = item.value;
      return acc;
    }, {});
    const sortField = sortModel[0]?.field || null;
    const sortOrder = sortModel[0]?.sort || null;
    meetingReportsService
      .getManyMeetings(page, pageSize, sortField, sortOrder, filters)
      .then((res) => {
        const data = res.data.items.map((mrf) => ({
          id: mrf.id,
          company_name: mrf.form.company?.company_name,
          location: mrf.form.terms?.location,
          date_assembly: mrf.form.assembly?.date_assembly,
          student_email: mrf.form.general?.student_email,
          status: {
            general: mrf.form.status["general"],
            terms: mrf.form.status["terms"],
            composition: mrf.form.status["composition"],
            allocation: mrf.form.status["allocation"],
            questions: mrf.form.status["questions"],
            resolutions: mrf.form.status["resolutions"],
            summary: mrf.form.status["summary"],
            files: mrf.form.status["files"],
          },
        }));
        setMeetingReports(data);
        setRowCount(res.data.total);
      })
      .catch((err) => {
        displayError(err.response?.data?.detail || err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  //-----------------Local functions --------------------
  const handleFilterChange = (model) => {
    setFilterModel(model);
  };
  const handleEdit = (params) => {
    navigate(`/meeting-reports/edit/${params.id}`, {
      state: {
        newsItem: meetingReports.find((item) => item.id === params.id),
      },
    });
  };
  const handleDelete = (params) => {
    requestConfirm({
      title: "Confirm Delete",
      description: "Are you sure you want to delete this item?",
      onConfirm: () =>
        meetingReportsService
          .deleteOneMeetingReport(params.id)
          .then((res) => {
            setMeetingReports(
              meetingReports.filter((mr) => mr.id != params.id)
            );
            toastSuccessHelper("Meeting report deleted successfully");
          })
          .catch((err) => {
            toastErrorHelper("Failed to delete meeting report");
            displayError(err.response?.data?.detail || err.message);
          }),
    });
  };
  //-----------------Startup Effects --------------------
  React.useEffect(() => {
    fetchMeetingReports();
  }, [page, pageSize, JSON.stringify(filterModel), JSON.stringify(sortModel)]);
  //------------------ Rendering When no data -------------------------
  if (rowCount == null) {
    return <Loading />;
  }
  const getStatusColor = (status) => {
    if (status === "ACCEPTED" || status.state == "ACCEPTED") return "green";
    if (status === "REJECTED" || status.state == "REJECTED") return "red";
    return "orange"; // For PENDING and other statuses
  };
  //------------------Rendering after we have data-------------------------
  const rows = meetingReports;
  const columns = [
    {
      field: "id",
      headerName: "ID",
      filterOperators: getGridStringOperators().filter((op) =>
        ["equals"].includes(op.value)
      ),
      width: 200,
    },
    {
      field: "company_name",
      headerName: "Company",
      filterOperators: getGridStringOperators().filter((op) =>
        ["contains"].includes(op.value)
      ),
      width: 200,
    },
    {
      field: "location",
      headerName: "Location",
      filterOperators: getGridStringOperators().filter((op) =>
        ["contains"].includes(op.value)
      ),
      width: 200,
    },
    {
      field: "date_assembly",
      headerName: "Date",
      filterOperators: getGridStringOperators().filter((op) =>
        ["contains"].includes(op.value)
      ),
      width: 200,
    },
    {
      field: "student_email",
      headerName: "Student Email",
      filterOperators: getGridStringOperators().filter((op) =>
        ["contains"].includes(op.value)
      ),
      width: 200,
    },
    {
      field: "status",
      headerName: "Status",
      width: 250,
      renderCell: (params) => {
        // Determine if all statuses are "ACCEPTED"
        const allAccepted = Object.values(params.value).every(
          (status) => status === "ACCEPTED" || status.state === "ACCEPTED"
        );

        return (
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: "2px",
              alignItems: "center",
              backgroundColor: allAccepted ? "lightgreen" : "inherit", // Apply light green background if all statuses are ACCEPTED
              padding: "4px", // Optional: Add padding for visual aesthetics
              borderRadius: "4px", // Optional: Add border radius for rounded corners
            }}
          >
            {Object.entries(params.value).map(([category, status]) => (
              <Tooltip
                key={category}
                title={`${category}`}
                placement="top"
                arrow
              >
                <Box
                  sx={{
                    position: "relative",
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: 24,
                    height: 24,
                  }}
                >
                  <FiberManualRecordIcon
                    sx={{ color: getStatusColor(status), fontSize: 24 }}
                  />
                  <Box
                    component="span"
                    sx={{
                      position: "absolute",
                      fontSize: "0.75rem",
                      color: "white",
                      lineHeight: 1,
                    }}
                  >
                    {category.charAt(0).toUpperCase()}
                  </Box>
                </Box>
              </Tooltip>
            ))}
          </Box>
        );
      },
      filterOperators: [
        {
          label: "Equals",
          value: "equals",
          InputComponent: CustomStatusFilter,
          getApplyFilterFn: (filterItem) => {
            if (!filterItem.columnField || !filterItem.value) {
              return null;
            }

            return ({ value }) => {
              return value[filterItem.value.key] === filterItem.value.value;
            };
          },
        },
      ],
    },
  ];
  return (
    <Card>
      <Title title="Meeting Reports" />
      <CardContent>
        <CustomDataGrid
          rows={rows}
          columns={columns}
          pageSize={pageSize}
          rowCount={rowCount}
          loading={loading}
          page={page}
          setPage={setPage}
          setPageSize={setPageSize}
          sortModel={sortModel}
          setSortModel={setSortModel}
          filterModel={filterModel}
          handleFilterChange={handleFilterChange}
          showEditButton={true}
          showDeleteButton={true}
          onEdit={handleEdit}
          onDelete={handleDelete}
        />
      </CardContent>
    </Card>
  );
};

const ODD_OPACITY = 0.2;

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],
    "&:hover, &.Mui-hovered": {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
    "&.Mui-selected": {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity
      ),
      "&:hover, &.Mui-hovered": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity
        ),
        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  },
}));
export default MeetingReports;
