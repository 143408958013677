import React from "react";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import AuthorizeForRoles from "../AuthorizeForRoles";
import { ALL_EXCEPT, Role } from "../../data/roles";
const QuestionItem = ({ question, onDelete, onEdit }) => {
  // console.log("QuestionItem->question: ", question);
  const { t } = useTranslation();
  return (
    <div
      key={crypto.randomUUID()}
      style={{
        display: "flex",
        padding: 5,
        backgroundColor: "#f1f1f1d7",
        borderRadius: 5,
        marginBottom: 5,
      }}
    >
      <div
        style={{
          width: "95%",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "baseline",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 10,
            }}
          >
            <Typography
              style={{ fontSize: 14, fontWeight: "bold" }}
              variant="subtitle1"
              gutterBottom
            >
              {question.author_company}
            </Typography>
            <Typography
              style={{ fontSize: 14 }}
              variant="subtitle1"
              gutterBottom
            >
              ({question.question_type})
            </Typography>
          </div>
        </div>
        <div>
          <div style={{ display: "flex" }}>
            <Typography
              sx={{
                color: "#064c3b",
              }}
              variant="body1"
              component="div"
            >
              {t("theme")}:
            </Typography>
            &nbsp;
            <Typography
              sx={{
                color: "#002782",
              }}
              variant="body1"
              component="div"
            >
              {question.theme}
            </Typography>
          </div>
          <AuthorizeForRoles
            roles={ALL_EXCEPT([Role.SCALENS_EXTERNAL_STUDENT])}
          >
            <div style={{ display: "flex" }}>
              <Typography
                sx={{
                  color: "#064c3b",
                }}
                variant="body1"
                component="div"
              >
                {t("theme_2")}:
              </Typography>
              &nbsp;
              <Typography
                sx={{
                  color: "#002782",
                }}
                variant="body1"
                component="div"
              >
                {question.theme_2}
              </Typography>
            </div>
          </AuthorizeForRoles>
          <div style={{ display: "flex" }}>
            <Typography
              sx={{
                color: "#064c3b",
              }}
              variant="body1"
              component="div"
            >
              {question.author}:
            </Typography>
            &nbsp;
            <Typography
              sx={{
                color: "#002782",
              }}
              variant="body1"
              component="div"
            >
              {question.content}
            </Typography>
          </div>
          <div style={{ display: "flex" }}>
            <Typography
              sx={{
                color: "#064c3b",
              }}
              variant="body1"
              component="div"
            >
              {question.response_author}:
            </Typography>
            &nbsp;
            <Typography
              sx={{
                color: "#002782",
              }}
              variant="body1"
              component="div"
            >
              {question.response}
            </Typography>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
          alignItems: "center",
        }}
      >
        <IconButton
          onClick={onDelete}
          size="small"
          style={{ color: "crimson" }}
        >
          <DeleteOutlineIcon />
        </IconButton>

        <IconButton
          onClick={onEdit}
          size="small"
          style={{ color: "dodgerblue" }}
        >
          <EditIcon />
        </IconButton>
      </div>
    </div>
  );
};

export default QuestionItem;
