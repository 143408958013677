import * as React from "react";
import { Card, CardContent, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Title } from "react-admin";
import { toastDeleteResult } from "../../components/Toaster";
import { getGridStringOperators } from "@mui/x-data-grid";
import Loading from "../../components/Loading";
import utilService from "../../services/investigate/util-service";
import resultsService from "../../services/investigate/result-service";
import { useAlert } from "../../providers/AlertProvider";
import { useConfirmationDialog } from "../../providers/ConfirmationDialogProvider";
import CustomDataGrid from "../../components/CustomDataGrid";

const PeriodicResultsListPage = () => {
  const { showAlert: displayError } = useAlert();
  const { requestConfirm } = useConfirmationDialog();
  //----------------- Hooks-------------------
  const navigate = useNavigate();
  //-----------------FOR DATAGRID-------------------
  const columns = React.useRef(null);
  const [companyNames, setCompanyNames] = React.useState(null);
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [filterModel, setFilterModel] = React.useState({
    items: [],
  });
  const [sortModel, setSortModel] = React.useState([
    { field: "id", sort: "desc" },
  ]);
  const [periodicResults, setPeriodicResults] = React.useState([]);
  const [rowCount, setRowCount] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const handleCreate = () => {
    navigate("/periodic-results/create");
  };
  const handleEdit = (params) => {
    navigate(`/periodic-results/edit/${params.id}`, {
      state: {
        report: periodicResults.find((item) => item.id === params.id),
      },
    });
  };
  const handleDelete = (params) => {
    requestConfirm({
      title: "Delete Result",
      description: "Are you sure you want to delete this result?",
      onConfirm: () =>
        toastDeleteResult(resultsService.deleteResult(params.id))
          .then((res) =>
            setPeriodicResults(
              periodicResults.filter((res) => res.id != params.id)
            )
          )
          .catch((err) =>
            displayError(err.response?.data?.detail || err.message)
          ),
    });
  };
  //-----------------Functions to call apis--------------------
  const fetchPeriodicResults = async () => {
    setLoading(true);
    const filters = filterModel.items.reduce((acc, item) => {
      acc["filter_field"] = item.columnField;
      acc["filter_value"] = item.value;
      return acc;
    }, {});
    const sortField = sortModel[0].field;
    const sortOrder = sortModel[0].sort;
    resultsService
      .getManyResults(page, pageSize, sortField, sortOrder, filters)
      .then((res) => {
        setPeriodicResults(res.data.items);
        setRowCount(res.data.total);
      })
      .catch((err) => displayError(err.response?.data?.detail || err.message))
      .finally(() => {
        setLoading(false);
      });
  };
  function getCompanyNamesByIds(periodicResults) {
    const companyIds = periodicResults.map(
      (periodicResult) => periodicResult.company_id
    );
    utilService
      .getManyCompaniesByIds(companyIds)
      .then((resp) => setCompanyNames(resp.data))
      .catch((err) => displayError(err.response?.data?.detail || err.message));
  }
  //-----------------Local functions --------------------
  function getCompanyNameById(data, id) {
    const result = data.filter((obj) => obj.id === id);
    if (result.length > 0) {
      return result[0].company_name;
    } else {
      return null;
    }
  }
  const handleFilterChange = (model) => {
    const filters = model.items.reduce((acc, item) => {
      acc[item.columnField] = item.value;
      return acc;
    }, {});
    setFilterModel(model);
  };
  //-----------------Startup Effects --------------------
  React.useEffect(() => {
    fetchPeriodicResults();
  }, [page, pageSize, JSON.stringify(filterModel), JSON.stringify(sortModel)]);
  React.useEffect(() => {
    if (periodicResults != null && periodicResults.length != 0) {
      getCompanyNamesByIds(periodicResults);
    }
  }, [JSON.stringify(periodicResults)]);
  //------------------ Rendering When no data -------------------------
  if (rowCount == null || !companyNames) {
    return <Loading />;
  }
  //------------------ Rendering When we have data -------------------------
  //------------------Define the rows after we have data-------------------------
  const rows = periodicResults.map((rec) => ({
    id: rec.id,
    company_name: getCompanyNameById(companyNames, rec.company_id),
    result_type: rec.result_type,
    publication_date: rec.publication_date,
  }));
  //------------------Define the columns based on the row keys -------------------------
  if (!columns.current && rows.length != 0) {
    // Data columns
    columns.current = Object.keys(rows[0]).map((key) => {
      const column = {
        field: key,
        headerName: key.replace(/_/g, " ").toUpperCase(),
        filterOperators:
          key == "id"
            ? getGridStringOperators().filter((op) =>
                ["equals"].includes(op.value)
              )
            : getGridStringOperators().filter((op) =>
                ["contains"].includes(op.value)
              ),
        sortable: key != "company_name",
        width: 200,
      };
      return column;
    });
  }
  return (
    <Card>
      <Title title="Annual & Semi-Annual Results" />
      <CardContent>
        <Box sx={{ height: "85vh", width: "100%" }}>
          <CustomDataGrid
            rows={rows}
            columns={columns.current}
            pageSize={pageSize}
            rowCount={rowCount}
            loading={loading}
            page={page}
            setPage={setPage}
            setPageSize={setPageSize}
            setSortModel={setSortModel}
            filterModel={filterModel}
            handleFilterChange={handleFilterChange}
            showCreateButton={true}
            onCreate={handleCreate}
            showEditButton={true}
            onEdit={handleEdit}
            showDeleteButton={true}
            onDelete={handleDelete}
          />
        </Box>
      </CardContent>
    </Card>
  );
};

export default PeriodicResultsListPage;
