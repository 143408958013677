import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Input from "@mui/material/Input";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { toastSuccessHelper, toastErrorHelper } from "./Toaster";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import weeklyService from "../services/investigate/weekly-service";
// import DatePicker from "./DatePicker"
const categories = ["ODJ", "INT", "CR"];
const WeeklyFiles = ({ assembly_uid }) => {
  //----------------States----------------------------
  const [weeklyFilesMeta, setWeeklyFilesMeta] = useState(null);
  //-- For Filename editing
  const [editingId, setEditingId] = useState(null);
  const [editedFilename, setEditedFilename] = useState("");
  //--For new File
  const [newFileInputKey, setNewFileInputKey] = useState(Date.now());
  const [newFile, setNewFile] = useState(null);
  const [newFilename, setNewFilename] = useState("");
  const [newWeekDate, setNewWeekdate] = useState("");
  //--For Confirm Deletion Dialog
  const [open, setOpen] = useState(false);
  //--For selected row to delete
  const [selectedFileToDelete, setSelectedFileToDelete] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  //----------------Remote functions------------------
  const getScalensWeeklyFiles = async () => {
    weeklyService
      .getAllWeeklyFiles()
      .then((res) => {
        console.log("scalens weekly fetched successfully");
        setWeeklyFilesMeta([...new Set(res.data)]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const uploadFile = async () => {
    if (!newFile) {
      toastErrorHelper("Please select a file to upload");
      return;
    }
    if (!newFilename) {
      toastErrorHelper("Please specify the filename");
      return;
    }
    if (newWeekDate === "") {
      toastErrorHelper("Please specify week date");
      return;
    }
    setIsLoading(true);
    weeklyService
      .uploadWeeklyFile(newFile, newFilename, newWeekDate)
      .then((res) => {
        console.log("file uploaded successfully");
        setWeeklyFilesMeta([...weeklyFilesMeta, res.data]);
        clearNewFileFields();
        toastSuccessHelper("File uploaded successfully");
      })
      .catch((err) => {
        console.log(err);
        toastErrorHelper("Error uploading file");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const updateFilename = async (uid, editedFilename) => {
    weeklyService
      .updateWeeklyFile(uid, { field: "file_name", value: editedFilename })
      .then((res) => {
        console.log("filename updated successfully");
        toastSuccessHelper("Filename updated successfully");
        setWeeklyFilesMeta(
          weeklyFilesMeta.map((item) => (item.uid === uid ? res.data : item))
        );
      })
      .catch((err) => {
        toastErrorHelper("Error updating filename");
        console.log(err);
      });
  };
  const updateWeekDate = async (uid, weekDate) => {
    weeklyService
      .updateWeeklyFile(uid, { field: "week_date", value: weekDate })
      .then((res) => {
        console.log("Week Date updated successfully");
        toastSuccessHelper("Week Date updated successfully");
        setWeeklyFilesMeta(
          weeklyFilesMeta.map((item) => (item.uid === uid ? res.data : item))
        );
      })
      .catch((err) => {
        toastErrorHelper("Error updating Week Date");
        console.log(err);
      });
  };
  const deleteFile = async () => {
    weeklyService
      .deleteWeeklyFile(selectedFileToDelete.uid)
      .then((res) => {
        console.log("file deleted successfully");
        setWeeklyFilesMeta(
          weeklyFilesMeta.filter(
            (item) => item.uid !== selectedFileToDelete.uid
          )
        );
        setOpen(false);
        toastSuccessHelper("File deleted successfully");
      })
      .catch((err) => {
        console.log(err);
        toastErrorHelper("Error deleting file");
      });
  };
  //----------------Local Functions--------------------
  //--For filename Editing
  function startEdit(uid, currentFilename) {
    setEditingId(uid);
    setEditedFilename(currentFilename);
  }

  function saveEdit(uid) {
    updateFilename(uid, editedFilename);
    setEditingId(null);
  }
  //--To clear the new file fields
  const clearNewFileFields = () => {
    setNewFile(null);
    setNewFileInputKey(Date.now());
    setNewFilename("");
    setNewWeekdate("");
  };
  //----------------Effects----------------------------
  useEffect(() => {
    if (!weeklyFilesMeta) {
      getScalensWeeklyFiles();
    }
  }, [weeklyFilesMeta]);

  if (!weeklyFilesMeta) return null;
  console.log("--> newWeekDate: ", newWeekDate);
  return (
    <div>
      <Box sx={{ width: "100%", padding: 1 }}>
        {isLoading && <LinearProgress />}
      </Box>
      <TableContainer
        component={Paper}
        style={{
          border: "1px solid lightgray",
          padding: 5,
          backgroundColor: "#F1F1F1",
          borderRadius: 5,
          marginBottom: 10,
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: "bold" }}>File</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Filename</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {weeklyFilesMeta.map((item) => (
              <TableRow key={item.uid}>
                <TableCell>{item.file_url}</TableCell>
                <TableCell>
                  {editingId === item.uid ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <TextField
                        value={editedFilename}
                        onChange={(e) => setEditedFilename(e.target.value)}
                      />
                      <IconButton onClick={() => saveEdit(item.uid)}>
                        <SaveIcon />
                      </IconButton>
                    </div>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      {item.file_name}
                      <IconButton
                        onClick={() => startEdit(item.uid, item.file_name)}
                      >
                        <EditIcon />
                      </IconButton>
                    </div>
                  )}
                </TableCell>
                <TableCell>
                  <div>
                    {/* <label htmlFor="date">Date: </label> */}
                    <input
                      type="date"
                      id="date"
                      value={item.week_date}
                      onChange={(e) => updateWeekDate(item.uid, e.target.value)}
                    />
                  </div>
                  {/* <Select
                    value={item.category || ""}
                    onChange={(e) => updateCategory(item.uid, e.target.value)}
                    sx={{ minWidth: 120 }} // set width here
                  >
                    {categories.map((category) => (
                      <MenuItem key={category} value={category}>
                        {category}
                      </MenuItem>
                    ))}
                  </Select> */}
                </TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="primary"
                    href={weeklyService.getDownloadWeeklyFileLink(
                      item.file_url
                    )}
                  >
                    Download
                  </Button>
                </TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => {
                      setOpen(true);
                      setSelectedFileToDelete(item);
                    }}
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell>
                <Input
                  key={newFileInputKey}
                  type="file"
                  accept="application/pdf"
                  onChange={(e) => setNewFile(e.target.files[0])}
                />
              </TableCell>
              <TableCell>
                <TextField
                  type="text"
                  value={newFilename}
                  onChange={(e) => setNewFilename(e.target.value)}
                />
              </TableCell>
              <TableCell>
                <div>
                  {/* <label htmlFor="date">Date: </label> */}
                  <input
                    type="date"
                    id="date"
                    value={newWeekDate}
                    onChange={(event) => setNewWeekdate(event.target.value)}
                  />
                </div>
              </TableCell>
              <TableCell>
                <Button
                  variant="contained"
                  color="success"
                  onClick={uploadFile}
                >
                  Upload
                </Button>
              </TableCell>
              <TableCell>
                <Button
                  variant="contained"
                  color="warning"
                  onClick={() => {
                    clearNewFileFields();
                  }}
                >
                  Clear
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete {selectedFileToDelete?.filename}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button onClick={deleteFile} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default WeeklyFiles;
