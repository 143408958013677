import axios from "axios";

// Create Axios instances for each API client
const apiClientInvestigate = axios.create({
  baseURL:
    process.env.REACT_APP_INVESTIGATE_BACKEND_URL ||
    "https://investigate.api.dev.scalens.com/api/admin",
});

const saveTokenInLocalStorage = (token, expiry) => {
  localStorage.setItem("token", token);
  localStorage.setItem("tokenExpiry", expiry);
};

const retrieveTokenFromLocalStorage = () => {
  const token = localStorage.getItem("token");
  const tokenExpiry = localStorage.getItem("tokenExpiry");
  if (token && tokenExpiry && new Date() < new Date(tokenExpiry)) {
    return token;
  }
  return null;
};

let tokenPromise = null;

const getAuthToken = async () => {
  let token = retrieveTokenFromLocalStorage();
  if (token) {
    return token;
  }

  // If a token request is already in progress, return the existing promise
  if (tokenPromise) {
    return tokenPromise;
  }

  // Create a new token request promise
  tokenPromise = axios
    .post(
      `${process.env.REACT_APP_BOARDDATA_BACKEND_URL}/oauth2/token/`,
      {
        client_id: process.env.REACT_APP_BOARDDATA_CLIENT_ID,
        client_secret: process.env.REACT_APP_BOARDDATA_CLIENT_SECRET,
        grant_type: "client_credentials",
      },
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    )
    .then((response) => {
      token = response.data.access_token;
      const tokenExpiry = new Date(
        new Date().getTime() + response.data.expires_in * 1000
      );
      saveTokenInLocalStorage(token, tokenExpiry);

      // Clear the token request promise after completion
      tokenPromise = null;

      return token;
    })
    .catch((error) => {
      // Clear the token request promise if there's an error
      tokenPromise = null;
      return Promise.reject(error);
    });

  return tokenPromise;
};

const apiClientBoarddata = axios.create({
  baseURL: process.env.REACT_APP_BOARDDATA_BACKEND_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

apiClientBoarddata.interceptors.request.use(
  async (config) => {
    const token = await getAuthToken();
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export { apiClientInvestigate, apiClientBoarddata };
