import { apiClientInvestigate } from "../api-client";

class MeetingReportService {
  getOneMeetingReport(id) {
    return apiClientInvestigate.get(`/companies/assemblies/forms/${id}`);
  }
  getManyMeetings(page, pageSize, sortField, sortOrder, filters) {
    return apiClientInvestigate.get(`/companies/assemblies/forms/many`, {
      params: {
        page: page + 1, // API is 1-indexed
        page_size: pageSize,
        sort_field: sortField,
        sort_order: sortOrder,
        ...filters,
      },
    });
  }
  deleteOneMeetingReport(id) {
    return apiClientInvestigate.delete(`/companies/assemblies/forms/${id}`);
  }
  validateMeeting(validatedMeeting) {
    return apiClientInvestigate.post(
      `/companies/assemblies/forms/validate`,
      validatedMeeting
    );
  }
  updateMeeting(id, updatedMeeting) {
    return apiClientInvestigate.patch(
      `/companies/assemblies/forms/${id}`,
      updatedMeeting
    );
  }
  updateMeetingReportSectionData(report_id, section, data) {
    return apiClientInvestigate.patch(
      `/companies/assemblies/forms/${report_id}/${section}/data`,
      data
    );
  }
  updateMeetingReportSectionStatus(report_id, section, status, comment) {
    const encodedComment = encodeURIComponent(comment);
    return apiClientInvestigate.patch(
      `/companies/assemblies/forms/${report_id}/${section}/status?status=${status}&comment=${encodedComment}`
    );
  }
}

export default new MeetingReportService();
