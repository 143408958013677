import Input from "@mui/material/Input";

const SimpleInput = ({
  placeholder,
  value,
  defaultValue,
  onChange,
  type,
  multiline,
  bold,
  width="50%"
}) => {
  return (
    <Input
      type={type}
      defaultValue={defaultValue}
      value={value}
      onChange={onChange}
      sx={{
        width,
        marginBottom: 5,
        fontWeight: bold ? "600 !important" : "",
      }}
      inputProps={{
        style: { fontSize: "1rem", fontWeight: bold && 600, color: "#001756" },
      }}
      placeholder={placeholder}
      multiline={multiline}
    />
  );
};

export default SimpleInput;
