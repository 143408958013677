import React, { useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ControlledInput from "../ControlledInput";
import StyledLabel from "../StyledLabel";
import RadioButtonsGroup from "../RadioButtonsGroup";
import { Button } from "@mui/material";

const tabStyle = {
  textTransform: "none",
  fontWeight: "bold",
  color: "#001756",
  padding: "0 15px",
  borderColor: "#001756",
};

const TabPanel = ({ children, value, index, ...other }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scenario-tabpanel-${index}`}
      aria-labelledby={`scenario-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"div"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Scenarios = ({ reso, addReso, isDisabled }) => {
  const [tabValue, setTabValue] = useState(0);
  const [scenario, setScenario] = useState({
    score: reso?.score ?? "",
    is_for: reso?.is_for ?? "",
    against: reso?.against ?? "",
    abstention: reso?.abstention ?? "",
    accepted: reso?.accepted || false,
    rejected: reso?.rejected || false,
    withdrawn_before_agm: reso?.withdrawn_before_agm || false,
  });

  const onChangeScore = ({ target: { value } }) => {
    const score = Number(value);
    setScenario({ ...scenario, score: !value ? "" : score });
  };

  const onChangeScenario2 = ({ target: { value } }, opt) => {
    const actions = Number(value);
    switch (opt) {
      case "for":
        setScenario({
          ...scenario,
          is_for: !value ? "" : actions,
        });

        break;
      case "against":
        setScenario({
          ...scenario,
          against: !value ? "" : actions,
        });
        break;
      case "abstention":
        setScenario({
          ...scenario,
          abstention: !value ? "" : actions,
        });
        break;
      default:
        break;
    }
  };

  const onChangeVote = ({ target: { value } }) =>
    setScenario({
      ...scenario,
      accepted: value === "accepted",
      rejected: value === "rejected",
    });

  const onChangeScenario4 = (e) =>
    setScenario({
      ...scenario,
      withdrawn_before_agm: e.target.value === "true",
    });

  const handleChange = (_, newValue) => {
    setTabValue(newValue);
  };

  return (
    <>
      <Box sx={{ width: "100%", flex: 1, marginTop: 0.5 }}>
        <Box sx={{ borderColor: "divider" }}>
          <Tabs
            value={tabValue}
            onChange={handleChange}
            aria-label="scenario_tabs"
            centered
          >
            <Tab
              disableRipple
              label="Votes(%) / Nb of Shares"
              {...a11yProps(0)}
              style={tabStyle}
            />
            <Tab
              style={tabStyle}
              disableRipple
              label="Voted/ Rejeted"
              {...a11yProps(1)}
            />

            {reso?.from_boarddata && (
              <Tab
                style={tabStyle}
                disableRipple
                label="Withdrawn before the AGM "
                {...a11yProps(2)}
              />
            )}
          </Tabs>
        </Box>
        <TabPanel value={tabValue} index={0}>
          <span>
            <StyledLabel fontSize={15} text={"For"} />
            <ControlledInput
              value={scenario.score}
              onChange={onChangeScore}
              type="number"
              adornment="%"
              width={150}
            />
          </span>
          <div
            style={{
              display: "flex",
              alignItems: "baseline",
              gap: 10,
            }}
          >
            <span>
              <StyledLabel fontSize={15} text="For (actions)" />
              <ControlledInput
                value={scenario.is_for}
                onChange={(e) => onChangeScenario2(e, "for")}
                type="number"
              />
            </span>
            <span>
              <StyledLabel fontSize={15} text="Against (actions)" />
              <ControlledInput
                value={scenario.against}
                onChange={(e) => onChangeScenario2(e, "against")}
                type="number"
              />
            </span>
            <span>
              <StyledLabel fontSize={15} text="Abstention (actions)" />
              <ControlledInput
                value={scenario.abstention}
                onChange={(e) => onChangeScenario2(e, "abstention")}
                type="number"
              />
            </span>
          </div>
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <RadioButtonsGroup
              value={
                scenario.accepted
                  ? "accepted"
                  : scenario.rejected
                  ? "rejected"
                  : ""
              }
              handleRadioChange={onChangeVote}
              options={[
                { label: "Voted", value: "accepted", id: 1 },
                { label: "Rejected", value: "rejected", id: 2 },
              ]}
              marginTop={0}
              marginBottom={0}
            />
          </div>
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <RadioButtonsGroup
              options={[
                { label: "Yes", value: true },
                { label: "No", value: false },
              ]}
              value={scenario.withdrawn_before_agm}
              handleRadioChange={onChangeScenario4}
              marginTop={0}
              marginBottom={0}
            />
          </div>
        </TabPanel>
      </Box>
      <Button
        disableRipple
        disabled={isDisabled}
        style={{ float: "right" }}
        onClick={() =>
          addReso({
            ...reso,
            score: scenario.score === "" ? null : Number(scenario.score),
            is_for: scenario.is_for === "" ? null : Number(scenario.is_for),
            against: scenario.against === "" ? null : Number(scenario.against),
            abstention:
              scenario.abstention === "" ? null : Number(scenario.abstention),
            accepted: scenario.accepted,
            rejected: scenario.rejected,
            withdrawn_before_agm: scenario.withdrawn_before_agm,
          })
        }
      >
        Save
      </Button>
    </>
  );
};

export default Scenarios;
