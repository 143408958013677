import React, { useState, useEffect } from "react";
import { MenuItem, Select, FormControl, InputLabel } from "@mui/material";

const CustomStatusFilter = ({ item, applyValue }) => {
  const [key, setKey] = useState(item.value?.split(":")[0] || "");
  const [value, setValue] = useState(item.value?.split(":")[1] || "");

  useEffect(() => {
    // Only apply the filter value when both key and value are set
    if (key && value) {
      // Concatenate key and value into a single string "key:value"
      const filterValue = `${key}:${value}`;

      // Only apply if the filter value has changed
      if (filterValue !== item.value) {
        applyValue({ ...item, value: filterValue });
      }
    }
  }, [key, value]); // Removed applyValue and item from dependencies to avoid infinite loop

  return (
    <div style={{ padding: 8 }}>
      <FormControl fullWidth size="small" style={{ marginBottom: 8 }}>
        <InputLabel>Key</InputLabel>
        <Select
          value={key}
          label="Key"
          onChange={(e) => setKey(e.target.value)}
        >
          {/* Map your keys here */}
          <MenuItem value="general">General</MenuItem>
          <MenuItem value="terms">Terms</MenuItem>
          <MenuItem value="composition">Composition</MenuItem>
          <MenuItem value="allocation">Allocation</MenuItem>
          <MenuItem value="questions">Questions</MenuItem>
          <MenuItem value="resolutions">Resolutions</MenuItem>
          <MenuItem value="summary">Summary</MenuItem>
          <MenuItem value="files">Files</MenuItem>
        </Select>
      </FormControl>
      <FormControl fullWidth size="small">
        <InputLabel>Status</InputLabel>
        <Select
          value={value}
          label="Status"
          onChange={(e) => setValue(e.target.value)}
        >
          <MenuItem value="PENDING">PENDING</MenuItem>
          <MenuItem value="ACCEPTED">ACCEPTED</MenuItem>
          <MenuItem value="REJECTED">REJECTED</MenuItem>
          {/* Add more status values as needed */}
        </Select>
      </FormControl>
    </div>
  );
};

export default CustomStatusFilter;
