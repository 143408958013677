import React, { useState } from "react";
import { toastSuccessHelper, toastErrorHelper } from "../components/Toaster";
import LinearProgress from "@mui/material/LinearProgress";
import {
  Input,
  Button,
  Box,
  Card,
  CardContent,
  Typography,
  CssBaseline,
  Container,
} from "@mui/material";
import monitoringService from "../services/investigate/monitoring-service";
import { useAlert } from "../providers/AlertProvider";

export default function UploadMonitoring() {
  const { showAlert: displayError } = useAlert();
  const [isLoading, setIsLoading] = useState(false);
  const [newFile, setNewFile] = useState(null);
  const [newFileInputKey, setNewFileInputKey] = useState(Date.now());
  const clearNewFileFields = () => {
    setNewFile(null);
    setNewFileInputKey(Date.now());
  };
  const uploadFile = async () => {
    if (!newFile) {
      toastErrorHelper("Please select a file to upload");
      return;
    }
    setIsLoading(true);
    monitoringService
      .uploadMonitoring(newFile)
      .then((res) => {
        console.log("file uploaded successfully");
        toastSuccessHelper("File uploaded successfully");
      })
      .catch((err) => {
        console.log(err);
        toastErrorHelper(
          `Error uploading file. Check ${err?.response?.data?.detail}`
        );
        displayError(err.response?.data?.detail || err.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  return (
    <>
      <Container
        maxWidth="lg"
        sx={{
          paddingBottom: ".5rem",
        }}
      >
        <CssBaseline />

        <Box
          sx={{
            bgcolor: "#FFF",
            height: "100%",
            marginTop: "1rem",
            padding: 3,
            borderRadius: 3,
          }}
        >
          <Typography
            sx={{
              color: "#064c3b",
            }}
            variant="h4"
            component="div"
          >
            {"Upload Monitoring (V.6.5)"}
          </Typography>
          <hr style={{ margin: "20px 0" }} />
          <Box sx={{ width: "100%", padding: 1 }}>
            {isLoading && <LinearProgress />}
          </Box>
          <Box
            sx={{
              margin: 5,
              border: "1px solid lightgray",
              padding: 5,
              backgroundColor: "#F1F1F1",
              borderRadius: 5,
              marginBottom: 10,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
              }}
            >
              <Input
                key={newFileInputKey}
                type="file"
                accept="application/pdf"
                onChange={(e) => setNewFile(e.target.files[0])}
              />

              <Button variant="contained" color="success" onClick={uploadFile}>
                Upload
              </Button>

              <Button
                variant="contained"
                color="warning"
                onClick={() => {
                  clearNewFileFields();
                }}
              >
                Clear
              </Button>
            </div>
          </Box>
        </Box>
      </Container>
    </>
  );
}
