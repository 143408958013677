import { apiClientInvestigate } from "../api-client";

class MonitoringService {
  uploadMonitoring(newFile) {
    let formData = new FormData();
    formData.append("file", newFile);
    return apiClientInvestigate.post("/monitoring?version=6", formData);
  }
}

export default new MonitoringService();
