import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import StyledLabel from "./StyledLabel";
import { t } from "i18next";
import ControlledInput from "./ControlledInput";
import MultilineInput from "./MultilineInput";
import SimpleInput from "./SimpleInput";
import { v4 as uuidv4 } from "uuid";
const ScrutineerDialog = ({ open, handleClose, handleAddScrutineer }) => {
  const [scrutineerName, setScrutineerName] = useState("");
  const [description, setDescription] = useState("");
  const [companyName, setCompanyName] = useState("");
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
    >
      <div style={{ padding: "15px 15px 0 15px" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <StyledLabel border text={t("Add scrutineer")} />
          <CloseIcon
            style={{ color: "#064c3b", cursor: "pointer" }}
            onClick={() => handleClose()}
          />
        </div>
        <br />
        <StyledLabel text={t("name")} />
        <SimpleInput
          value={scrutineerName}
          onChange={(e) => setScrutineerName(e.target.value)}
        />
        <StyledLabel text={t("company_name")} />
        <SimpleInput
          value={companyName}
          onChange={(e) => setCompanyName(e.target.value)}
        />
        <StyledLabel text={t("description")} />
        <MultilineInput
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </div>
      <DialogActions>
        <Button
          onClick={() => {
            handleClose();
            handleAddScrutineer({
              uid: uuidv4(),
              name: scrutineerName,
              company_name: companyName,
              description,
            });
            setScrutineerName("");
            setCompanyName("");
            setDescription("");
          }}
          autoFocus
        >
          Add scrutineer
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ScrutineerDialog;
