import TextField from "@mui/material/TextField";

const MultilineInput = ({ value, onChange, defaultValue,width="100%",disabled=false }) => {
  return (
    <TextField
      id="outlined-multiline-flexible"
      defaultValue={defaultValue}
      multiline
      minRows={5}
      value={value}
      onChange={onChange}
      disabled={disabled}
      sx={{ width, marginTop: 2, marginBottom: 5 }}
    />
  );
};

export default MultilineInput;
