import React from "react";
import { ToastContainer, ToastPosition } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Import the Toastify CSS

const ToastifyProvider = ({ children }) => {
  // Configure the Toastify options
  const toastifyOptions = {
    // You can customize the Toastify options here, such as position, autoClose, etc.
    // position: "bottom-right" as ToastPosition,
    style: { marginTop: 30 },
    autoClose: 3000,
    hideProgressBar: false,
    // ... other options
  };

  return (
    <>
      {/* Render the Toastify container */}
      <ToastContainer {...toastifyOptions} />
      {/* Render the children components */}
      {children}
    </>
  );
};

export default ToastifyProvider;
