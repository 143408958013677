export const confirmPrompter = {
  changeStatus: {
    title: (sectionName) => `Change Status of Section ${sectionName}`,
    description: (newStatus) =>
      `Are you sure you want to change the status to ${newStatus}?`,
  },
  updateSection: {
    title: "Update Section",
    description: "Are you sure you want to update this section?",
  },
  resetSection: {
    title: "Reset Section",
    description: "Are you sure you want to reset this section?",
  },
};
