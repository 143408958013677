import TextField from "@mui/material/TextField";

function SimpleDatePicker({ date, onChange }) {
  const handleDateChange = (event) => {
    onChange(event.target.value);
  };

  return (
    <TextField
      sx={{ width: "50%", marginBottom: 5 }}
      type="date"
      value={date}
      onChange={handleDateChange}
      InputLabelProps={{
        shrink: true,
      }}
    />
  );
}

export default SimpleDatePicker;
