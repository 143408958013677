import React from "react";
import { Typography, Box, Container } from "@mui/material";

const WelcomePage = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
    >
      <Container maxWidth="sm">
        <Typography variant="h3" align="center" gutterBottom>
          Welcome to Investigate Admin
        </Typography>
        {/* Add your welcome page content here */}
      </Container>
    </Box>
  );
};

export default WelcomePage;
