import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import StyledLabel from "../StyledLabel.jsx";
import { t } from "i18next";
import ControlledInput from "../ControlledInput.jsx";
import MultilineInput from "../MultilineInput.jsx";
import SimpleSelect from "../SimpleSelect.jsx";
import { getObjectById } from "../../utils.js";
import AuthorizeForRoles from "../AuthorizeForRoles.jsx";
import { ALL_EXCEPT, Role } from "../../data/roles.js";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
const QUESTION_TYPES = [
  { title: t("oral") },
  { title: t("written") },
  { title: t("mail_sms") },
];

const QuestionsForms = ({
  questionToEdit,
  open,
  handleClose,
  addOrUpdateQuestion,
  questionsThemes,
}) => {
  const [question, setQuestion] = useState(questionToEdit?.content || "");
  const [questionType, setQuestionType] = useState(
    questionToEdit?.question_type
      ? { title: questionToEdit?.question_type }
      : ""
  );
  const [questionAuthor, setQuestionAuthor] = useState(
    questionToEdit?.author || ""
  );
  const [questionLanguage, setQuestionLanguage] = useState(
    questionToEdit?.author_language || "English"
  );
  const [questionTheme, setQuestionTheme] = useState(
    getObjectById(questionsThemes, questionToEdit?.theme_id || -1)?.theme || ""
  );
  const [questionTheme2, setQuestionTheme2] = useState(
    getObjectById(questionsThemes, questionToEdit?.theme_id_2 || -1)?.theme ||
      ""
  );
  const [questionThemeId, setQuestionThemeId] = useState(
    questionToEdit?.theme_id || ""
  );
  const [questionThemeId2, setQuestionThemeId2] = useState(
    questionToEdit?.theme_id_2 || null
  );

  const [questionCompany, setQuestionCompany] = useState(
    questionToEdit?.author_company || ""
  );

  const [questionAnswer, setQuestionAnswer] = useState(
    questionToEdit?.response || ""
  );
  const [responseAuthor, setResponseAuthor] = useState(
    questionToEdit?.response_author || ""
  );
  const [tag, setTag] = useState(questionToEdit?.tag || "");

  useEffect(() => {
    setQuestionType(
      questionToEdit?.question_type
        ? { title: questionToEdit?.question_type }
        : ""
    );
    setQuestionTheme(
      getObjectById(questionsThemes, questionToEdit?.theme_id || -1)?.theme ||
        ""
    );
    setQuestionTheme2(
      getObjectById(questionsThemes, questionToEdit?.theme_id_2 || -1)?.theme ||
        ""
    );
    setQuestionAuthor(questionToEdit?.author || "");
    setQuestionLanguage(questionToEdit?.author_language || "English");
    setQuestionCompany(questionToEdit?.author_company || "");
    setQuestion(questionToEdit?.content || "");
    setQuestionAnswer(questionToEdit?.response || "");
    setResponseAuthor(questionToEdit?.response_author || "");
    setQuestionThemeId(questionToEdit?.theme_id || "");
    setQuestionThemeId2(questionToEdit?.theme_id_2 || null);
    setTag(questionToEdit?.tag || "");
  }, [questionToEdit]);

  const handleAddQuestion = () => {
    const payload = {
      uid: questionToEdit?.uid || crypto.randomUUID(),
      content: question,
      question_type: questionType.title,
      theme: questionTheme,
      theme_2: questionTheme2,
      theme_id: questionThemeId,
      theme_id_2: questionThemeId2,
      author: questionAuthor,
      author_language: questionLanguage,
      response: questionAnswer,
      author_company: questionCompany,
      response_author: responseAuthor,
      tag,
    };
    addOrUpdateQuestion(payload);
    setTimeout(() => {
      setQuestion("");
      setQuestionType("");
      setQuestionTheme("");
      setQuestionTheme2("");
      setQuestionAuthor("");
      setQuestionLanguage("English");
      setQuestionCompany("");
      setQuestionAnswer("");
      setResponseAuthor("");
      setQuestionThemeId("");
      setQuestionThemeId2(null);
      setTag("");
    }, 50);
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
    >
      <div style={{ padding: "15px 15px 0 15px" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <StyledLabel border text={t("qa")} />
          <CloseIcon
            style={{ color: "#064c3b", cursor: "pointer" }}
            onClick={() => handleClose()}
          />
        </div>
        <br />
        <div>
          <StyledLabel text={t("question")} />
          <MultilineInput
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
          />
          <StyledLabel required text={t("question_type")} />
          <SimpleSelect
            value={questionType}
            onChange={(e, newVal) => setQuestionType(newVal)}
            renderOption={(props, option) => (
              <li {...props} key={crypto.randomUUID()}>
                {option.title}
              </li>
            )}
            options={QUESTION_TYPES}
            width="100%"
          />
          <div
            style={{
              display: "flex",
              alignItems: "baseline",
              justifyContent: "space-between",
              gap: 10,
            }}
          >
            <div style={{ flex: 1 }}>
              <StyledLabel text={t("question_author")} />
              <ControlledInput
                value={questionAuthor}
                onChange={(e) => setQuestionAuthor(e.target.value)}
              />
            </div>
            <div style={{ flex: 1 }}>
              <StyledLabel text={t("language")} />
              <Select
                value={questionLanguage}
                onChange={(event) => setQuestionLanguage(event.target.value)}
                label={t("language")}
              >
                <MenuItem value={"English"}>English</MenuItem>
                <MenuItem value={"French"}>French</MenuItem>
              </Select>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "baseline",
              justifyContent: "space-between",
              gap: 10,
            }}
          >
            <div style={{ flex: 1 }}>
              <StyledLabel required text={t("theme")} />
              <SimpleSelect
                value={getObjectById(questionsThemes, questionThemeId || -1)}
                onChange={(_, newVal) => {
                  setQuestionTheme(newVal.theme);
                  setQuestionThemeId(newVal.id);
                }}
                options={questionsThemes}
                width="100%"
              />
            </div>
            <div style={{ flex: 1 }}>
              <StyledLabel text={t("company")} />
              <ControlledInput
                value={questionCompany}
                onChange={(e) => setQuestionCompany(e.target.value)}
              />
            </div>
          </div>
          <AuthorizeForRoles roles={ALL_EXCEPT(Role.SCALENS_EXTERNAL_STUDENT)}>
            <div>
              <StyledLabel text={t("theme_2")} />
              <SimpleSelect
                value={getObjectById(questionsThemes, questionThemeId2 || -1)}
                onChange={(_, newVal) => {
                  setQuestionTheme2(newVal.theme);
                  setQuestionThemeId2(newVal.id);
                }}
                options={questionsThemes}
                width="50%"
              />
            </div>
          </AuthorizeForRoles>
          <div>
            <StyledLabel text={t("tag")} />
            <ControlledInput
              value={tag}
              onChange={(e) => setTag(e.target.value)}
            />
          </div>
          <StyledLabel text={t("response")} />
          <MultilineInput
            value={questionAnswer}
            onChange={(e) => setQuestionAnswer(e.target.value)}
          />
        </div>
        <div style={{ flex: 1 }}>
          <StyledLabel text={t("response_author")} />
          <ControlledInput
            value={responseAuthor}
            onChange={(e) => setResponseAuthor(e.target.value)}
          />
        </div>
      </div>
      <DialogActions>
        <>
          {!questionTheme ||
            (!questionType && (
              <>
                <span style={{ color: "red" }}>*</span>{" "}
                <span style={{ fontSize: 13, color: "crimson" }}>
                  {" "}
                  {t("required_fields")}
                </span>
              </>
            ))}
          <Button
            disabled={!questionTheme || !questionType}
            onClick={handleAddQuestion}
            autoFocus
          >
            {questionToEdit ? "Update Question" : "Add question"}
          </Button>
        </>
      </DialogActions>
    </Dialog>
  );
};

export default QuestionsForms;
