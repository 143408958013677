import { Autocomplete, TextField } from "@mui/material";
import React from "react";

const SimpleSelect = ({
  options,
  value,
  onChange,
  renderOption,
  disabled,
  width = "50%",
}) => {
  // console.log("InitialValue_SimpleSelect: ",initialValue)
  // const [value, setValue] = React.useState(initialValue);
  // React.useEffect(()=>{
  //   if(JSON.stringify(initialValue)!==JSON.stringify(value)){
  //     setValue(initialValue)
  //   }
  // },[JSON.stringify(initialValue)])
  return (
    <Autocomplete
      disabled={disabled}
      freeSolo
      value={value}
      onChange={onChange}
      filterOptions={(opts, _) => opts}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      options={options}
      getOptionLabel={(option) =>
        option.title ||
        option.theme ||
        option.resolution_type?.title ||
        option.date_assembly ||
        option.status ||
        ""
      }
      renderOption={renderOption}
      sx={{ width, marginBottom: 3 }}
      renderInput={(params) => <TextField {...params} />}
    />
  );
};

export default SimpleSelect;
