import React from "react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";

import "./RichText.css";

const RichText = ({ text, setText }) => {
  return (
    <div className="editor">
      <CKEditor
        editor={ClassicEditor}
        data={text}
        onChange={(e, editor) => {
          const data = editor.getData();
          setText(data);
        }}
        config={{
          toolbar: [
            "bold",
            "italic",
            "underline",
            "strikethrough",
            "|",
            "numberedList",
            "bulletedList",
            "|",
            "removeFormat",
            "|",
            "undo",
            "redo",
          ],
        }}
      />
    </div>
  );
};

export default RichText;
