import React from "react";
import { t } from "i18next";
import StyledLabel from "../StyledLabel";
import Typography from "@mui/material/Typography";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";

const PresentationItem = ({
  expo,
  presentations,
  onClickEdit,
  onClickDelete,
}) => {
  return (
    <div
      key={expo.uid}
      style={{
        border: "1px solid lightgray",
        padding: 5,
        backgroundColor: "#F1F1F1",
        borderRadius: 5,
        marginBottom: 10,
        cursor: "pointer",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <StyledLabel
          text={`${presentations.indexOf(expo) + 1} - ${expo.theme}`}
        />
        <EditIcon
          onClick={() => onClickEdit(expo)}
          fontSize="small"
          style={{ color: "#001756", cursor: "pointer" }}
        />
      </div>
      <ul style={{ padding: 0, margin: 0 }}>
        <li style={{ display: "flex", alignItems: "center" }}>
          <Typography
            sx={{
              color: "#064c3b",
            }}
            variant="body1"
            component="div"
          >
            {t("duration")}:
          </Typography>
          &nbsp;
          <Typography
            sx={{
              color: "#002782",
            }}
            variant="body1"
            component="div"
          >
            {expo.duration}min
          </Typography>
        </li>
        <li style={{ display: "flex" }}>
          <Typography
            sx={{
              color: "#064c3b",
            }}
            variant="body1"
            component="div"
          >
            {t("speaker")}:
          </Typography>
          &nbsp;
          <Typography
            sx={{
              color: "#002782",
            }}
            variant="body1"
            component="div"
          >
            {expo.speaker}
          </Typography>
        </li>
        <li style={{ display: "flex" }}>
          <Typography
            sx={{
              color: "#064c3b",
            }}
            variant="body1"
            component="div"
          >
            {t("key_points")}:
          </Typography>
          &nbsp;
          <Typography
            sx={{
              color: "#002782",
              overflowWrap: "anywhere",
            }}
            variant="body1"
            component="div"
          >
            {expo.highlights}
          </Typography>
        </li>
      </ul>
      <div style={{ display: "flex", justifyContent: "right" }}>
        <DeleteOutlineIcon
          onClick={onClickDelete}
          fontSize="small"
          style={{ color: "crimson", cursor: "pointer" }}
        />
      </div>
    </div>
  );
};

export default PresentationItem;
