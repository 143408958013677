import { toast } from "react-toastify";

export const toastSuccessHelper = (msg, position = "top-right") => {
  toast.success(msg, {
    position: position,
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  });
  setTimeout(() => {
    // window.location.reload(false);
  }, 1000);
};

export const toastErrorHelper = (msg, position = "top-center") => {
  toast.error(msg, {
    position,
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  });
};

export const toastChangeStatus = (func) => {
  return toast.promise(func, {
    pending: "Changing status ...",
    success: "Status changed successfully!",
    error: "Failed to change status.",
  });
};
export const toastUpdateSection = (func) => {
  return toast.promise(func, {
    pending: "updating section ...",
    success: "Section updated successfully!",
    error: "Failed to update section.",
  });
};

export const toastCreatePeer = (func) => {
  return toast.promise(func, {
    pending: "Creating peer ...",
    success: "Peer created successfully",
    error: "Failed to create peer.",
  });
};

export const toastUpdatePeer = (func) => {
  return toast.promise(func, {
    pending: "Updating peer ...",
    success: "Peer updated successfully",
    error: "Failed to update peer.",
  });
};
export const toastDeletePeer = (func) => {
  return toast.promise(func, {
    pending: "Deleting peer ...",
    success: "Peer deleted successfully",
    error: "Failed to delete peer.",
  });
};
export const toastDeleteNews = (func) => {
  return toast.promise(func, {
    pending: "Deleting news ...",
    success: "News deleted successfully",
    error: "Failed to delete news.",
  });
};
export const toastCreateResult = (func) => {
  return toast.promise(func, {
    pending: "Creating result ...",
    success: "Result created successfully",
    error: "Failed to create peer.",
  });
};

export const toastUpdateResult = (func) => {
  console.log (func)
  return toast.promise(func, {
    pending: "Updating result ...",
    success: "Result updated successfully",
    error: "Failed to update result.",
  });
};
export const toastDeleteResult = (func) => {
  return toast.promise(func, {
    pending: "Deleting result ...",
    success: "result deleted successfully",
    error: "Failed to delete result.",
  });
};
