import { useState, useEffect } from "react";
import {
  toastSuccessHelper,
  toastErrorHelper,
} from "../../../components/Toaster";
import { ToastContainer } from "react-toastify";
import { useParams } from "react-router-dom";
import Loading from "../../../components/Loading";
import { TextField, Box, Button, Typography } from "@mui/material";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import axios from "axios";
import BasicCard from "../../../components/BasicCard";

import { config } from "../../../config";
const NotificationFrequencyEdit = () => {
  const { id } = useParams();
  const [notificationFrequency, setNotificationFrequency] = useState(null);

  useEffect(() => {
    axios
      .get(`${config.base_url}/notifications/frequencies/${id}`)
      .then((respRes) => {
        setNotificationFrequency(respRes.data);
      });
  }, []);

  if (!notificationFrequency) return <Loading />;
  else
    return (
      <>
        <ToastContainer style={{ marginTop: 35 }} />
        <RenderNotificationFrequency notifFrequency={notificationFrequency} />
      </>
    );
};

const RenderNotificationFrequency = ({ notifFrequency }) => {
  const id = notifFrequency.id;
  const [notificationFrequency, setNotificationFrequency] = useState(
    notifFrequency.frequency_interval
  );
  const [openDialog, setOpenDialog] = useState(false);
  //-----------------API functions-----------------

  const updateNotificationFrequency = async (
    id,
    editedNotificationFrequency
  ) => {
    try {
      const response = await axios.put(
        `${config.base_url}/notifications/frequencies/${id}`,
        editedNotificationFrequency
      );
      console.log("NotificationType updated successfully: ", response.data);
      // setIsLoading(false);
      toastSuccessHelper("Notification frequency updated Successfully");
    } catch (error) {
      console.log(error);
      // setIsLoading(false);
      toastErrorHelper("Failed to Update Notification frequency");
    }
  };

  //-----------------Local functions-----------------

  const handleNotificationFrequencyChange = (event) => {
    setNotificationFrequency(event.target.value);
  };
  const handleConfirm = async () => {
    const payload = {
      frequency_interval: notificationFrequency,
    };
    console.log("HERE");
    console.log(payload);
    updateNotificationFrequency(id, payload);
    setOpenDialog(false); // Close the confirmation dialog
  };
  return (
    <Box sx={{ maxWidth: 800, mx: "auto", mt: 4 }}>
      <ToastContainer style={{ marginTop: 35 }} />
      <Typography sx={{ color: "#001756" }} variant="h3" gutterBottom>
        Notification frequency Edit Form
      </Typography>
      <form>
        <BasicCard elevation={0} title="Notification frequency">
          <TextField
            value={notificationFrequency}
            onChange={handleNotificationFrequencyChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </BasicCard>

        <Button
          disabled={!notificationFrequency}
          onClick={() => setOpenDialog(true)}
          variant="contained"
          sx={{ float: "right", mt: 2 }}
        >
          Submit
        </Button>
      </form>

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Confirm Submission</DialogTitle>
        <DialogContent>
          Are you sure you want to submit this form?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button onClick={handleConfirm} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default NotificationFrequencyEdit;
