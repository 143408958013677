import { apiClientBoarddata } from "../api-client";

class NewsService {
  createNews(news) {
    return apiClientBoarddata.post("/api/news/", news);
  }
  getManyNews(page, pageSize, sortField, sortOrder, filters) {
    return apiClientBoarddata.get(`/api/news/`, {
      params: {
        page: page + 1, // API is 1-indexed
        page_size: pageSize,
        sort_field: sortField,
        sort_order: sortOrder,
        ...filters,
      },
    });
  }
  getOneNewsItem(id) {
    return apiClientBoarddata.get(`/api/news/${id}/`);
  }
  updateNews(id, editedNews) {
    return apiClientBoarddata.patch(`/api/news/${id}/`, editedNews);
  }
  deleteNews(id) {
    return apiClientBoarddata.delete(`/api/news/${id}/`);
  }
  getNewsThemes() {
    return apiClientBoarddata.get(`/api/news/theme/`);
  }
  getNewsStatus() {
    return apiClientBoarddata.get(`/api/news/status/`);
  }
}

export default new NewsService();
