import dayjs from "dayjs";
import { assemblyTypes } from "./data/assemblyTypes";

export const removeDuplicates = (arr) => {
  const firstNames = arr.map((item) => item.FIRST_NAME);
  return arr.filter(
    (item, index) => firstNames.indexOf(item.FIRST_NAME) === index
  );
};
const deepEqual = (obj1, obj2) => {
  if (obj1 === obj2) {
    return true;
  }

  if (
    typeof obj1 !== "object" ||
    typeof obj2 !== "object" ||
    obj1 == null ||
    obj2 == null
  ) {
    return false;
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
      return false;
    }
  }

  return true;
};

export const sortByDate = (arr) => {
  const currentYear = new Date().getFullYear();

  return arr
    .sort(
      (d1, d2) =>
        new Date(d2.date_assembly).getTime() -
        new Date(d1.date_assembly).getTime()
    )
    .filter(
      (date) => new Date(date.date_assembly).getFullYear() === currentYear
    );
};

export const diffInMinutes = (date1, date2) => {
  const parsedDate1 = new Date(date1);
  const parsedDate2 = new Date(date2);

  const diff = Math.abs(parsedDate1 - parsedDate2);
  return Math.ceil(diff / (1000 * 60));
};

export const convertMinutesToHours = (minutes) => {
  if (minutes < 60) {
    return minutes + " minutes";
  } else {
    const hours = Math.floor(minutes / 60);
    const minutesLeft = minutes % 60;
    return (
      hours +
      " hour" +
      (hours > 1 ? "s" : "") +
      " and " +
      minutesLeft +
      " minute" +
      (minutesLeft > 1 ? "s" : "")
    );
  }
};

//-----------------------Reconstruct form data -------------------
export const getObjectByUid = (objects, uid) => {
  // Use the filter method to create a new array containing only objects
  // whose uid property matches the given uid
  const filteredObjects = objects.filter((obj) => obj.uid === uid);

  // If any objects match the given uid, return the first one, otherwise return null
  return filteredObjects.length > 0 ? filteredObjects[0] : null;
};
export const getObjectById = (objects, id) => {
  // Use the filter method to create a new array containing only objects
  // whose uid property matches the given uid
  const filteredObjects = objects.filter((obj) => obj.id === id);

  // If any objects match the given uid, return the first one, otherwise return null
  return filteredObjects.length > 0 ? filteredObjects[0] : null;
};

export const getHasPostponed = (postponement) => {
  if (postponement.meeting_no_postponed)
    return "No postponement, held in 1st convocation";
  else if (postponement.meeting_held_2d_convocation)
    return "Held in 2d convocation";
  else if (postponement.meeting_postponed_other_reason)
    return "Postponed for other reasons";
  else return null;
};

export const getVenue = (venue) => {
  if (venue.meeting_virtual) return "Virtual";
  else if (venue.meeting_in_person) return "In person";
  else if (venue.meeting_hybrid) return "Hybrid";
  else return null;
};

export const getLiveVoting = (liveVoting) => {
  if (liveVoting == null) return null;
  else return liveVoting ? "yes" : "no";
};
export const getWebcastValue = (webcast) => {
  if (webcast.has_audio) return "Audio";
  else if (webcast.has_video) return "Video";
  else if (webcast.can_ask_direct) return "No webcast";
  else return null;
};

export const sortByPosition = (array) => {
  return array.sort((a, b) => {
    if (a.position === undefined || b.position === undefined)
      return a.position === undefined ? -1 : 1;

    if (typeof a.position === "number" && typeof b.position === "number") {
      return a.position - b.position;
    } else if (!a.position === "number" && !b.position === "number") {
      return b.position > a.position ? -1 : 1;
    }

    return a.position === "number" ? 1 : -1;
  });
};

export const getReplay = (replay) => {
  if (replay == null) return null;
  else return replay ? "yes" : "no";
};
export const getLiveQA = (liveQA) => {
  if (liveQA == null) return null;
  else return liveQA ? "yes" : "no";
};
export const getDomesticLanguage = (language) => {
  if (language.domestic_language == null) return null;
  else return language.domestic_language ? "yes" : "no";
};
export const getSimultaneousTranslation = (language) => {
  if (language.simultaneous_translation == null) return null;
  else return language.simultaneous_translation ? "yes" : "no";
};
export const getExclusiveEng = (language) => {
  if (language.exclusively_english == null) return null;
  else return language.exclusively_english ? "yes" : "no";
};

export const getScrutineers = (report) => {
  const scrutineers = report.scrutineer_list.map((s) => ({
    company_name: s.company_name,
    description: s.description,
    name: s.name,
  }));
  return scrutineers;
};

export const getBoardPresence = (report) => {
  const mandataries = report.mandatory.map((m) => ({
    name: m.name,
    present: true,
    boarddata_uid: m.uid,
  }));
  return mandataries;
};

export const getComexPresence = (report) => {
  const comexes = report.comex.map((c) => ({
    name: c.name,
    present: true,
    boarddata_uid: c.uid,
  }));
  return comexes;
};
export const getMeetingTypesValue = (meeting_type) => {
  if (meeting_type == "") return assemblyTypes;
  const arr = meeting_type.split(",").map((substring) => substring.trim());
  const assemblyTypesCopy = JSON.parse(JSON.stringify(assemblyTypes));
  const updatedArray = assemblyTypesCopy.map((obj) => {
    if (arr.includes(obj.label)) {
      obj.checked = true;
    }
    return obj;
  });
  return updatedArray;
};

export const getExposValue = (report, proceedingsThemes) => {
  return report.presentation_list.map((pr) => ({
    ...pr,
    duration: diffInMinutes(
      dayjs(`2022-04-17T${pr.end}`),
      dayjs(`2022-04-17T${pr.start}`)
    ),
    theme: getObjectById(proceedingsThemes, pr.theme_id)?.theme,
  }));
};
export const getQuestionsListValue = (report, questionsThemes) => {
  console.log("questionsThemes: ", questionsThemes);
  return report.question_list.map((quest) => ({
    ...quest,
    theme: getObjectById(questionsThemes, quest.theme_id)?.theme,
    theme_2: getObjectById(questionsThemes, quest.theme_id_2)?.theme,
  }));
};
const isObjectEmpty = (obj) => {
  if (Object.keys(obj).length === 0 && obj.constructor === Object) {
    return true;
  } else {
    return false;
  }
};

export const getResosAdded = (report, resolutions) => {
  console.log("report resolutions: ", report.resolution_list);
  console.log("assembly resolutions: ", resolutions);
  const treatedResolutions = report.resolution_list.map((comingReso) => {
    console.log("resolutions: ", resolutions);
    if (comingReso.boarddata_meta) {
      if (comingReso.scenario_one_vote_for != comingReso.boarddata_meta.score) {
        if (
          comingReso.scenario_one_vote_for > 0 &&
          (comingReso.boarddata_meta.score == 0 ||
            comingReso.boarddata_meta.score == null)
        ) {
          comingReso.boarddata_meta.score = comingReso.scenario_one_vote_for;
        }
      }
      if (
        comingReso.scenario_two_vote_for != comingReso.boarddata_meta.vote_for
      ) {
        if (
          comingReso.scenario_two_vote_for > 0 &&
          (comingReso.boarddata_meta.vote_for == 0 ||
            comingReso.boarddata_meta.vote_for == null)
        ) {
          comingReso.boarddata_meta.score = comingReso.scenario_one_vote_for;
        }
      }
      if (
        comingReso.scenario_two_vote_against !=
        comingReso.boarddata_meta.against
      ) {
        if (
          comingReso.scenario_two_vote_against > 0 &&
          (comingReso.boarddata_meta.against == 0 ||
            comingReso.boarddata_meta.against == null)
        ) {
          comingReso.boarddata_meta.against =
            comingReso.scenario_two_vote_against;
        }
      }
      if (
        comingReso.scenario_two_vote_abstention !=
        comingReso.boarddata_meta.abstention
      ) {
        if (
          comingReso.scenario_two_vote_abstention > 0 &&
          (comingReso.boarddata_meta.abstention == 0 ||
            comingReso.boarddata_meta.abstention == null)
        ) {
          comingReso.boarddata_meta.abstention =
            comingReso.scenario_two_vote_abstention;
        }
      }
      if (
        (comingReso.scenario_three_voted ||
          comingReso.scenario_three_rejected) &&
        comingReso.boarddata_meta.status != "ACCEPTED" &&
        comingReso.boarddata_meta.status != "REJECTED"
      ) {
        if (comingReso.scenario_three_voted) {
          comingReso.boarddata_meta.status = "ACCEPTED";
        } else if (comingReso.scenario_three_rejected) {
          comingReso.boarddata_meta.status = "REJECTED";
        }
      }
      if (
        comingReso.resolution_name != null &&
        comingReso.resolution_name != "" &&
        (comingReso.boarddata_meta.title == null ||
          comingReso.boarddata_meta.title == "")
      ) {
        comingReso.boarddata_meta.title = comingReso.resolution_name;
      }

      if (
        (comingReso.boarddata_meta.title == null ||
          comingReso.boarddata_meta.title == "") &&
        comingReso.boarddata_meta.resolution_type &&
        comingReso.boarddata_meta.resolution_type.title &&
        comingReso.boarddata_meta.resolution_type.title != ""
      ) {
        comingReso.boarddata_meta.title =
          comingReso.boarddata_meta.resolution_type.title;
      }
      return comingReso;
    }

    // If boarddata_meta is null (Registered with old api)
    let boarddata_meta = getObjectByUid(
      resolutions,
      comingReso.boarddata_resolution_uid
    );
    if (!boarddata_meta) {
      // new resolution
      boarddata_meta = {
        uid: comingReso.boarddata_resolution_uid,
        title: comingReso.resolution_name,
        score: comingReso.scenario_one_vote_for,
        vote_for: comingReso.scenario_two_vote_for,
        against: comingReso.scenario_two_vote_against,
        abstention: comingReso.scenario_two_vote_abstention,
        status: comingReso.scenario_three_voted
          ? "ACCEPTED"
          : comingReso.scenario_three_rejected
          ? "REJECTED"
          : null,
      };
      return { ...comingReso, boarddata_meta: boarddata_meta };
    }
    //if the resolution exists in the resolutions list

    const detailedVal = {
      ...comingReso,
      boarddata_meta: {
        ...boarddata_meta,
        title: comingReso.resolution_name,
        score: comingReso.scenario_one_vote_for,
        vote_for: comingReso.scenario_two_vote_for,
        against: comingReso.scenario_two_vote_against,
        abstention: comingReso.scenario_two_vote_abstention,
        status: comingReso.scenario_three_voted
          ? "ACCEPTED"
          : comingReso.scenario_three_rejected
          ? "REJECTED"
          : null,
      },
      // number_or_letter: resolutionInfo?.resolutionInfo,
      // score: resolutionInfo?.score,
      // position: resolutionInfo?.position,
      // category: resolutionInfo?.resolution_type?.category,
      // majority: resolutionInfo?.resolution_type?.majority,
      // code: resolutionInfo?.resolution_type?.code,
      // title:resolutionInfo?.resolution_type?.title
    };
    return detailedVal;
  });
  const untreatedResolutions = resolutions.filter(
    (reso) =>
      !treatedResolutions.some(
        (treatedReso) => treatedReso.boarddata_meta.uid === reso.uid
      )
  );
  console.log("Treated resolutions: ", treatedResolutions);
  const adaptedUntreatedResolutions = untreatedResolutions.map((res) => ({
    boarddata_meta: { ...res, title: res.resolution_type?.title || "" },
  }));
  console.log("untreated resolutions: ", adaptedUntreatedResolutions);
  const fullResolutions = treatedResolutions.concat(
    adaptedUntreatedResolutions
  );
  const sorted = fullResolutions.sort(
    (res1, res2) => res1.boarddata_meta.position - res2.boarddata_meta.position
  );
  return sorted;
};

export const getUserRoles = (user) => {
  // Roles are typically stored in the user object with a namespace that you specified in Auth0 rules.
  // Replace 'http://your-api/roles' with your actual namespace.
  const roles = user ? user["https://api.praexo.com/roles"] : [];

  return roles;
};

export const parseStatus = (variable) => {
  try {
    return JSON.parse(variable);
  } catch (e) {
    return {
      PENDING: false,
      COMPLETED: false,
      "DATA ON BOARDDATA": false,
      "DOC ON BOARDDATA": false,
      "MINUTES TO BE REVISED": false,
      "MINUTES INTEGRATED": false,
      "MINUTES IN HUBSSPOT": false,
      "MINUTES INTEGRATED ON BOARDDATA": false,
      "MINUTES VALIDATED": false,
      "Q&A to excel Michel-Oral": false,
      "Q&A to excel Michel-Written": false,
    };
  }
};

export const objectRemap = (obj) => {
  let newObject = {};
  newObject.including_abstention = obj.including_abstention;
  newObject.resolutions = obj.resolutions.map((reso) => ({
    is_correction: reso.is_correction,
    score: reso.score,
    is_for: reso.is_for,
    against: reso.against,
    abstention: reso.abstention || reso.absentation,
    accepted: reso.accepted,
    rejected: reso.rejected,
    proposed_at_agm: reso.proposed_at_agm,
    is_dissenting: reso.is_dissenting,
    resolution_type: reso.resolution_type?.uid,
  }));
  return newObject;
};

export const getStatusColor = (status) => {
  if (status === "DRAFT") return "dodgerblue";
  if (status === "REJECTED") return "crimson";
  if (status === "ACCEPTED") return "mediumseagreen";
  if (status === "PENDING") return "darkorange";
  return "default";
};

export const cleanHtmlString = (html) =>
  html
    .replace(/\s*(<[^>]+>)\s*/g, "$1")
    .replace(/\s{2,}/g, " ")
    .trim();
