import { apiClientInvestigate } from "../api-client";

class ResultService {
  createResult(result) {
    return apiClientInvestigate.post(
      `/companies/${result.company_id}/results`,
      result
    );
  }
  getManyResults(page, pageSize, sortField, sortOrder, filters) {
    return apiClientInvestigate.get(`/companies/results/many`, {
      params: {
        page: page + 1, // API is 1-indexed
        page_size: pageSize,
        sort_field: sortField,
        sort_order: sortOrder,
        ...filters,
      },
    });
  }
  getOneResult(id) {
    return apiClientInvestigate.get(`/companies/results/${id}`);
  }
  updateResult(id, editedResult) {
    return apiClientInvestigate.put(`/companies/results/${id}`, editedResult);
  }
  deleteResult(id) {
    return apiClientInvestigate.delete(`/companies/results/${id}`);
  }
}

export default new ResultService();
