import { apiClientBoarddata } from "../api-client";

class CompanyService {
  searchCompanies(query) {
    return apiClientBoarddata.get("/api/company/search/", {
      params: { query: query },
    });
  }
}

export default new CompanyService();
