import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";

import { config } from "../config.js";
import TextField from "@mui/material/TextField";
import Input from "@mui/material/Input";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { toastSuccessHelper, toastErrorHelper } from "./Toaster.jsx";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import meetingReportFileService from "../services/investigate/meeting-report-file-service.js";
const categories = ["ODJ", "INT", "CR"];
const MeetingReportFiles = ({
  meeting_id,
  filesMetas,
  handleFilesMetasChange,
}) => {
  //----------------States----------------------------
  //-- For Filename editing
  const [editingId, setEditingId] = useState(null);
  const [editedFilename, setEditedFilename] = useState("");
  //--For new File
  const [newFileInputKey, setNewFileInputKey] = useState(Date.now());
  const [newFile, setNewFile] = useState(null);
  const [newFilename, setNewFilename] = useState("");
  const [newCategory, setNewCategory] = useState("");
  //--For Confirm Deletion Dialog
  const [open, setOpen] = useState(false);
  //--For selected row to delete
  const [selectedFileToDelete, setSelectedFileToDelete] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  //----------------Remote functions------------------

  const uploadFile = async () => {
    if (!newFile) {
      toastErrorHelper("Please select a file to upload");
      return;
    }
    if (!newFilename) {
      toastErrorHelper("Please specify the filename");
      return;
    }
    if (newCategory === "") {
      toastErrorHelper("Please specify file category");
      return;
    }
    setIsLoading(true);
    meetingReportFileService
      .uploadFile(meeting_id, newFile, newFilename, newCategory)
      .then((res) => {
        handleFilesMetasChange([...filesMetas, res.data]);
        clearNewFileFields();
        toastSuccessHelper("File uploaded successfully");
      })
      .catch((err) => {
        console.log(err);
        toastErrorHelper("Error uploading file");
      })
      .finally(() => {
        setIsLoading(false);
      });
    let formData = new FormData();
    formData.append("file", newFile);
    const params = {
      modified_filename: newFilename,
      category: newCategory,
      meeting_id: meeting_id,
    };
  };
  const updateFilename = async (uid, editedFilename) => {
    meetingReportFileService
      .updateFile(meeting_id, uid, "modified_filename", editedFilename)
      .then((res) => {
        toastSuccessHelper("Filename updated successfully");
        handleFilesMetasChange(
          filesMetas.map((item) => (item.uid === uid ? res.data : item))
        );
      })
      .catch((err) => {
        toastErrorHelper("Error updating filename");
        console.log(err);
      });
  };
  const updateCategory = async (uid, category) => {
    meetingReportFileService
      .updateFile(meeting_id, uid, "category", category)
      .then((res) => {
        toastSuccessHelper("Category updated successfully");
        handleFilesMetasChange(
          filesMetas.map((item) => (item.uid === uid ? res.data : item))
        );
      })
      .catch((err) => {
        toastErrorHelper("Error updating category");
        console.log(err);
      });
  };
  const deleteFile = async () => {
    meetingReportFileService
      .deleteFile(meeting_id, selectedFileToDelete.uid)
      .then((res) => {
        handleFilesMetasChange(
          filesMetas.filter((item) => item.uid !== selectedFileToDelete.uid)
        );
        setOpen(false);
        toastSuccessHelper("File deleted successfully");
      })
      .catch((err) => {
        console.log(err);
        toastErrorHelper("Error deleting file");
      });
  };
  //----------------Local Functions--------------------
  //--For filename Editing
  function startEdit(uid, currentFilename) {
    setEditingId(uid);
    setEditedFilename(currentFilename);
  }

  function saveEdit(uid) {
    updateFilename(uid, editedFilename);
    setEditingId(null);
  }
  //--To clear the new file fields
  const clearNewFileFields = () => {
    setNewFile(null);
    setNewFileInputKey(Date.now());
    setNewFilename("");
    setNewCategory("");
  };
  //----------------Effects----------------------------

  if (!filesMetas) return null;
  return (
    <div>
      <Box sx={{ width: "100%", padding: 1 }}>
        {isLoading && <LinearProgress />}
      </Box>
      <TableContainer
        component={Paper}
        style={{
          border: "1px solid lightgray",
          padding: 5,
          backgroundColor: "#F1F1F1",
          borderRadius: 5,
          marginBottom: 10,
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: "bold" }}>File</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>
                Modified Filename
              </TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Category</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filesMetas.map((item) => (
              <TableRow key={item.uid}>
                <TableCell>{item.filename}</TableCell>
                <TableCell>
                  {editingId === item.uid ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <TextField
                        value={editedFilename}
                        onChange={(e) =>
                          setEditedFilename(e.target.value.toUpperCase())
                        }
                      />
                      <IconButton onClick={() => saveEdit(item.uid)}>
                        <SaveIcon />
                      </IconButton>
                    </div>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      {item.modified_filename}
                      <IconButton
                        onClick={() =>
                          startEdit(item.uid, item.modified_filename)
                        }
                      >
                        <EditIcon />
                      </IconButton>
                    </div>
                  )}
                </TableCell>
                <TableCell>
                  <Select
                    value={item.category || ""}
                    onChange={(e) => updateCategory(item.uid, e.target.value)}
                    sx={{ minWidth: 120 }} // set width here
                  >
                    {categories.map((category) => (
                      <MenuItem key={category} value={category}>
                        {category}
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="primary"
                    href={meetingReportFileService.getDownloadLink(
                      meeting_id,
                      item.s3_key
                    )}
                  >
                    Download
                  </Button>
                </TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => {
                      setOpen(true);
                      setSelectedFileToDelete(item);
                    }}
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell>
                <Input
                  key={newFileInputKey}
                  type="file"
                  accept="application/pdf"
                  onChange={(e) => setNewFile(e.target.files[0])}
                />
              </TableCell>
              <TableCell>
                <TextField
                  type="text"
                  value={newFilename}
                  onChange={(e) => setNewFilename(e.target.value.toUpperCase())}
                />
              </TableCell>
              <TableCell>
                <Select
                  value={newCategory}
                  onChange={(e) => setNewCategory(e.target.value)}
                  sx={{ minWidth: 120 }} // set width here
                >
                  {categories.map((category) => (
                    <MenuItem key={category} value={category}>
                      {category}
                    </MenuItem>
                  ))}
                </Select>
              </TableCell>
              <TableCell>
                <Button
                  variant="contained"
                  color="success"
                  onClick={uploadFile}
                >
                  Upload
                </Button>
              </TableCell>
              <TableCell>
                <Button
                  variant="contained"
                  color="warning"
                  onClick={() => {
                    clearNewFileFields();
                  }}
                >
                  Clear
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete {selectedFileToDelete?.filename}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button onClick={deleteFile} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default MeetingReportFiles;
