import { apiClientBoarddata } from "../api-client";

class PersonService {
  searchPersons(query) {
    return apiClientBoarddata.get("/api/person/search/", {
      params: { query: query },
    });
  }
}

export default new PersonService();
