import { useState, useEffect } from "react";
import { toastCreateResult } from "../../components/Toaster";
import {
  TextField,
  Autocomplete,
  Box,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import BasicCard from "../../components/BasicCard";
import RichText from "../../components/RichTextEditor/RichText";
import resultService from "../../services/investigate/result-service";
import utilService from "../../services/investigate/util-service";
import { useAlert } from "../../providers/AlertProvider";
import { useConfirmationDialog } from "../../providers/ConfirmationDialogProvider";
import { useNavigate } from "react-router-dom";

const PeriodicResultsCreatePage = () => {
  const { showAlert: displayError } = useAlert();
  const { requestConfirm } = useConfirmationDialog();

  const today = new Date();
  const dateString = `${today.getFullYear()}-${(today.getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${today.getDate().toString().padStart(2, "0")}`;
  const [companies, setCompanies] = useState([]);
  const [company, setCompany] = useState("");
  const [date, setDate] = useState(dateString);
  const [resultType, setResultType] = useState("Annual");
  const [resultContent, setResultContent] = useState("");
  const navigate = useNavigate();
  //-----------------API functions-----------------
  const getCompanies = async () => {
    utilService
      .getAllCompanies()
      .then((res) => {
        setCompanies([...new Set(res.data)]);
      })
      .catch((err) => {
        displayError(err.response?.data?.detail || err.message);
      });
  };
  const createPeriodicResult = () => {
    const result = {
      company_id: company.id,
      publication_date: date,
      result_type:
        resultType == "Annual"
          ? "Résultats annuels"
          : resultType == "Semi-Annual"
          ? "Résultats semestriels"
          : "",
      content: resultContent,
    };

    requestConfirm({
      title: "Create Result",
      description: "Are you sure you want to create this result?",
      onConfirm: () => {
        toastCreateResult(resultService.createResult(result))
          .then(() => {
            navigate("/periodic-results");
          })
          .catch((err) => {
            displayError(err.response?.data?.detail || err.message);
          });
      },
    });
  };
  useEffect(() => {
    getCompanies();
  }, []);
  //-----------------Local functions-----------------
  const handleCompanyChange = (event, value) => {
    setCompany(value);
  };

  const handleDateChange = (event) => {
    setDate(event.target.value);
  };

  const handleResultTypeChange = (event) => {
    setResultType(event.target.value);
  };

  return (
    <Box sx={{ maxWidth: 800, mx: "auto", mt: 4 }}>
      <Typography sx={{ color: "#001756" }} variant="h3" gutterBottom>
        Annual & Semi-Annual Results Form
      </Typography>
      <form>
        <BasicCard elevation={0} title="Company">
          <Autocomplete
            options={companies}
            getOptionLabel={(option) => option.company_name || ""}
            renderInput={(params) => (
              <TextField placeholder="Type to search for company" {...params} />
            )}
            value={company}
            onChange={handleCompanyChange}
          />
        </BasicCard>
        <BasicCard elevation={0} title="Date">
          <TextField
            type="date"
            value={date}
            onChange={handleDateChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </BasicCard>
        <BasicCard elevation={0} title="Result Type">
          <RadioGroup
            aria-label="Result Type"
            name="resultType"
            value={resultType}
            onChange={handleResultTypeChange}
          >
            <FormControlLabel
              value="Annual"
              control={<Radio />}
              label="Annual"
            />
            <FormControlLabel
              value="Semi-Annual"
              control={<Radio />}
              label="Semi-Annual"
            />
          </RadioGroup>
        </BasicCard>
        <BasicCard elevation={0} title="Result Content">
          <RichText text={resultContent} setText={setResultContent} />
        </BasicCard>

        <Button
          disabled={!company || !date || !resultType}
          onClick={() => createPeriodicResult()}
          variant="contained"
          sx={{ float: "right", mt: 2 }}
        >
          Submit
        </Button>
      </form>
    </Box>
  );
};

export default PeriodicResultsCreatePage;
