import * as React from "react";
import { alpha, styled } from "@mui/material/styles";
import { Box, Button } from "@mui/material";
import {
  DataGrid,
  gridClasses,
  GridToolbarContainer,
  GridPagination,
} from "@mui/x-data-grid";

const ODD_OPACITY = 0.2;

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],
    "&:hover, &.Mui-hovered": {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
    "&.Mui-selected": {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity
      ),
      "&:hover, &.Mui-hovered": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity
        ),
        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  },
}));

const CustomPagination = ({
  onCreate,
  onExport,
  showCreateButton,
  showExportButton,
}) => {
  return (
    <GridToolbarContainer
      style={{ display: "flex", justifyContent: "space-between" }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        {showExportButton && (
          <Button
            variant="contained"
            color="success"
            onClick={onExport}
            style={{ marginRight: "10px" }}
          >
            Export
          </Button>
        )}
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        {showCreateButton && (
          <Button
            variant="contained"
            color="primary"
            onClick={onCreate}
            style={{ marginRight: "10px" }}
          >
            Create New Item
          </Button>
        )}
        <GridPagination />
      </div>
    </GridToolbarContainer>
  );
};

const CustomDataGrid = ({
  rows,
  columns,
  pageSize,
  rowCount,
  loading,
  page,
  setPage,
  setPageSize,
  sortModel,
  setSortModel,
  filterModel,
  handleFilterChange,
  showCreateButton = false,
  showShowButton = false,
  showEditButton = false,
  showDeleteButton = false,
  showExportButton = false,
  onCreate,
  onShow,
  onEdit,
  onDelete,
  onExport,
}) => {
  const enhancedColumns = React.useMemo(() => {
    const actionColumns = [];

    if (showShowButton && onShow) {
      actionColumns.push({
        field: "show",
        headerName: "SHOW",
        sortable: false,
        filterable: false,
        renderCell: (params) => (
          <Button onClick={() => onShow(params)}>Show</Button>
        ),
      });
    }

    if (showEditButton && onEdit) {
      actionColumns.push({
        field: "edit",
        headerName: "EDIT",
        sortable: false,
        filterable: false,
        renderCell: (params) => (
          <Button onClick={() => onEdit(params)}>Edit</Button>
        ),
      });
    }

    if (showDeleteButton && onDelete) {
      actionColumns.push({
        field: "delete",
        headerName: "DELETE",
        sortable: false,
        filterable: false,
        renderCell: (params) => (
          <Button color="error" onClick={() => onDelete(params)}>
            DELETE
          </Button>
        ),
      });
    }

    return [...columns, ...actionColumns];
  }, [
    columns,
    onShow,
    onEdit,
    onDelete,
    showShowButton,
    showEditButton,
    showDeleteButton,
  ]);

  return (
    <Box sx={{ height: "85vh", width: "100%" }}>
      <StripedDataGrid
        columns={enhancedColumns}
        rows={rows}
        components={{
          Pagination: CustomPagination,
        }}
        componentsProps={{
          pagination: {
            onCreate,
            showCreateButton,
            onExport,
            showExportButton,
          },
        }}
        pagination
        pageSize={pageSize}
        rowCount={rowCount}
        paginationMode="server"
        page={page}
        onPageChange={(page) => setPage(page)}
        onPageSizeChange={(pageSize) => setPageSize(pageSize)}
        rowsPerPageOptions={[10, 25, 50]}
        loading={loading}
        sortModel={sortModel}
        onSortModelChange={(newModel) => setSortModel(newModel)}
        filterModel={filterModel}
        onFilterModelChange={handleFilterChange}
        filterMode="server"
        sortingMode="server"
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
        }
      />
    </Box>
  );
};

export default CustomDataGrid;
