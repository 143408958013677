import { t } from "i18next";

export const assemblyTypes = [
  { label: t("agm_annual"), value: "YEAR", id: 1 },
  {
    label: t("agm_capital_increase"),
    value: "CAPITAL",
    id: 2,
  },
  {
    label: t("agm_subject_of_remuneration"),
    value: "TOKEN",
    id: 3,
  },
  {
    label: t("agm_collective_proceedings"),
    value: "COLLECTIVE",
    id: 4,
  },
  { label: t("agm_other"), value: "PURPOSE", id: 5 },
];
