import { useState, useEffect } from "react";
import { toastUpdateResult } from "../../components/Toaster";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../components/Loading";
import {
  TextField,
  Autocomplete,
  Box,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import BasicCard from "../../components/BasicCard";
import RichText from "../../components/RichTextEditor/RichText";
import resultsService from "../../services/investigate/result-service";
import utilService from "../../services/investigate/util-service";
import { useAlert } from "../../providers/AlertProvider";
import { useConfirmationDialog } from "../../providers/ConfirmationDialogProvider";

const PeriodicResultsEditPage = () => {
  const { showAlert: displayError } = useAlert();
  const { id } = useParams();
  const [periodicResult, setPeriodicResult] = useState(null);
  const [company, setCompany] = useState(null);

  useEffect(() => {
    resultsService
      .getOneResult(id)
      .then((res) => {
        let periodicResult = res.data;
        utilService
          .getOneCompanyById(periodicResult.company_id)
          .then((res) => {
            let company = res.data[0];
            periodicResult["company"] = company;
            setPeriodicResult(periodicResult);
          })
          .catch((err) =>
            displayError(err.response?.data?.detail || err.message)
          );
      })
      .catch((err) => displayError(err.response?.data?.detail || err.message));
  }, []);

  if (!periodicResult) return <Loading />;
  else
    return (
      <>
        <RenderPeriodicResult
          periodicResult={periodicResult}
          companyObj={company}
        />
      </>
    );
};

const RenderPeriodicResult = ({ periodicResult }) => {
  const { showAlert: displayError } = useAlert();
  const { requestConfirm } = useConfirmationDialog();
  const [companies, setCompanies] = useState([]);
  const [company, setCompany] = useState(periodicResult.company || "");
  const [date, setDate] = useState(periodicResult.publication_date);
  const [resultType, setResultType] = useState(
    periodicResult.result_type == "Résultats annuels"
      ? "Annual"
      : periodicResult.result_type == "Résultats semestriels"
      ? "Semi-Annual"
      : ""
  );
  const [resultContent, setResultContent] = useState(periodicResult.content);
  const navigate = useNavigate();
  //-----------------API functions-----------------
  const getCompanies = async () => {
    utilService
      .getAllCompanies()
      .then((res) => {
        setCompanies([...new Set(res.data)]);
      })
      .catch((err) => {
        displayError(err.response?.data?.detail || err.message);
      });
  };
  const updatePeriodicResult = () => {
    const editedResult = {
      company_id: company.id,
      publication_date: date,
      result_type:
        resultType == "Annual"
          ? "Résultats annuels"
          : resultType == "Semi-Annual"
          ? "Résultats semestriels"
          : "",
      content: resultContent,
    };
    requestConfirm({
      title: "Update Result",
      description: "Are you sure you want to update this result?",
      onConfirm: () => {
        toastUpdateResult(
          resultsService.updateResult(periodicResult.id, editedResult)
        )
          .then(() => {
            navigate("/periodic-results");
          })
          .catch((err) => {
            displayError(err.response?.data?.detail || err.message);
          });
      },
    });
  };

  useEffect(() => {
    getCompanies();
  }, []);

  //-----------------Local functions-----------------
  const handleCompanyChange = (event, value) => {
    setCompany(value);
  };

  const handleDateChange = (event) => {
    setDate(event.target.value);
  };

  const handleResultTypeChange = (event) => {
    setResultType(event.target.value);
  };

  return (
    <Box sx={{ maxWidth: 800, mx: "auto", mt: 4 }}>
      <Typography sx={{ color: "#001756" }} variant="h3" gutterBottom>
        Annual & Semi-Annual Results Form
      </Typography>
      <form>
        <BasicCard elevation={0} title="Company">
          <Autocomplete
            options={companies}
            getOptionLabel={(option) => option.company_name || ""}
            renderInput={(params) => (
              <TextField placeholder="Type to search for company" {...params} />
            )}
            value={company}
            onChange={handleCompanyChange}
          />
        </BasicCard>
        <BasicCard elevation={0} title="Date">
          <TextField
            type="date"
            value={date}
            onChange={handleDateChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </BasicCard>
        <BasicCard elevation={0} title="Result Type">
          <RadioGroup
            aria-label="Result Type"
            name="resultType"
            value={resultType}
            onChange={handleResultTypeChange}
          >
            <FormControlLabel
              value="Annual"
              control={<Radio />}
              label="Annual"
            />
            <FormControlLabel
              value="Semi-Annual"
              control={<Radio />}
              label="Semi-Annual"
            />
          </RadioGroup>
        </BasicCard>
        <BasicCard elevation={0} title="Result Content">
          <RichText text={resultContent} setText={setResultContent} />
        </BasicCard>

        <Button
          disabled={!company || !date || !resultType}
          color="warning"
          onClick={() => updatePeriodicResult()}
          variant="contained"
          sx={{ float: "right", mt: 2 }}
        >
          update
        </Button>
      </form>
    </Box>
  );
};

export default PeriodicResultsEditPage;
