import { apiClientInvestigate } from "../api-client";

class PeerService {
  createPeer(peer) {
    return apiClientInvestigate.post("/peers", peer);
  }
  getManyPeers(page, pageSize, sortField, sortOrder, filters) {
    return apiClientInvestigate.get(`/peers/many`, {
      params: {
        page: page + 1, // API is 1-indexed
        page_size: pageSize,
        sort_field: sortField,
        sort_order: sortOrder,
        ...filters,
      },
    });
  }
  getOnePeer(id) {
    return apiClientInvestigate.get(`/peers/${id}`);
  }
  updatePeer(id, editedPeer) {
    return apiClientInvestigate.put(`/peers/${id}`, editedPeer);
  }
  deletePeer(id) {
    return apiClientInvestigate.delete(`/peers/${id}`);
  }
}

export default new PeerService();
