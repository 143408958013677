import InputLabel from "@mui/material/InputLabel";

const StyledLabel = ({
  text,
  fontSize = 19,
  flex,
  marginTop,
  cursor,
  onClick,
  border,
  preWrap,
  required,
}) => {
  return (
    <InputLabel
      onClick={onClick}
      sx={{
        border: border && "1px solid #001756",
        backgroundColor: border && "#001756",
        padding: 1,
        borderRadius: 1,
        color: border ? "#FFF" : "#002782",
        fontSize,
        fontWeight: "medium",
        flex,
        cursor,
        marginTop,
        whiteSpace: preWrap && "pre-wrap",
      }}
      htmlFor="company-input"
    >
      <span style={{ marginRight: 10 }}>{text}</span>
      {required && <span style={{ color: "red" }}>*</span>}
    </InputLabel>
  );
};

export default StyledLabel;
