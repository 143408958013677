import React, { createContext, useContext, useState } from "react";
import ConfirmationDialog from "../components/ConfirmationDialog";

const ConfirmationDialogContext = createContext();

export const useConfirmationDialog = () =>
  useContext(ConfirmationDialogContext);

export const ConfirmationDialogProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [dialogProps, setDialogProps] = useState({});

  const requestConfirm = (props) => {
    setDialogProps(props);
    setIsOpen(true);
  };

  const handleConfirm = (comment) => {
    if (dialogProps.onConfirm) {
      dialogProps.onConfirm(comment);
    }
    setIsOpen(false);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <ConfirmationDialogContext.Provider value={{ requestConfirm }}>
      {children}
      <ConfirmationDialog
        {...dialogProps}
        open={isOpen}
        onClose={handleClose}
        onConfirm={(comment) => handleConfirm(comment)}
      />
    </ConfirmationDialogContext.Provider>
  );
};
