import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Role } from "../data/roles";
const AuthorizeForRoles = ({ roles, protectionType, children }) => {
  const { user } = useAuth0();
  if (user && user["https://api.praexo.com/roles"]) {
    const userRoles = user["https://api.praexo.com/roles"];
    let userHasRequiredRole = false;
    // check if any of the required roles are present in the user's roles
    // console.log({user_roles:userRoles,authorized_for:roles});
    if (roles === Role.ANY) {
      userHasRequiredRole = true;
    } else if (roles === Role.NONE || roles.length === 0) {
      userHasRequiredRole = false;
    } else {
      userHasRequiredRole = roles.some((role) => userRoles.includes(role));
    }

    if (userHasRequiredRole) {
      return children;
    }
    // console.log("userHasRequiredRole: ", userHasRequiredRole);
    // else
    switch (protectionType) {
      case "DISABLE":
        return React.cloneElement(children, { disabled: true });
      case "HIDE":
        return React.cloneElement(children, { visible: false });
      case "REMOVE":
        return React.cloneElement(children, { display: "none" });
      default:
        return null;
    }
  }

  return null;
};

export default AuthorizeForRoles;
