// NewsListDisplay.js
import * as React from "react";
import { Card, CardContent, Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Title } from "react-admin";
import { getStatusColor } from "../../utils";
import { useAlert } from "../../providers/AlertProvider";
import { useConfirmationDialog } from "../../providers/ConfirmationDialogProvider";
import Loading from "../../components/Loading";
import newsService from "../../services/boarddata/news-service";
import { toastSuccessHelper, toastErrorHelper } from "../../components/Toaster";
import CustomDataGrid from "../../components/CustomDataGrid";
import { getGridStringOperators } from "@mui/x-data-grid";
import { Document, Packer, Paragraph, TextRun, AlignmentType } from "docx";
import { saveAs } from "file-saver";
import { htmlToText } from "html-to-text";
import DateRangeDialog from "../../components/DateRangeDialog";

const NewsListPage = () => {
  const { showAlert: displayError } = useAlert();
  const { requestConfirm } = useConfirmationDialog();
  const navigate = useNavigate();

  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [filterModel, setFilterModel] = React.useState({ items: [] });
  const [sortModel, setSortModel] = React.useState([
    { field: "id", sort: "desc" },
  ]);
  const [dateRangeDialogOpen, setDateRangeDialogOpen] = React.useState(false);
  const [news, setNews] = React.useState([]);
  const [rowCount, setRowCount] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const fetchNews = async () => {
    setLoading(true);
    const filters = filterModel.items.reduce((acc, item) => {
      acc["filter_field"] = item.columnField;
      acc["filter_value"] = item.value;
      return acc;
    }, {});
    const sortField = sortModel[0]?.field || null;
    const sortOrder = sortModel[0]?.sort || null;
    newsService
      .getManyNews(page, pageSize, sortField, sortOrder, filters)
      .then((res) => {
        const data = res.data.news_items.map((newsItem) => ({
          id: newsItem.id,
          title: newsItem.title,
          date_news: newsItem.date_news,
          news: newsItem.news,
          themes: newsItem.themes.map((theme) => theme.label).join(", "),
          persons: newsItem.persons.map((person) => person.name).join(", "),
          companies: newsItem.companies
            .map((company) => company.name)
            .join(", "),
          status: newsItem.status,
        }));
        setNews(data);
        setRowCount(res.data.total_items);
      })
      .catch((err) => {
        displayError(err.response?.data?.detail || err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleFilterChange = (model) => {
    setFilterModel(model);
  };

  const handleShow = (params) => {
    navigate(`/news/show/${params.id}`, {
      state: {
        newsItem: news.find((item) => item.id === params.id),
      },
    });
  };

  const handleEdit = (params) => {
    navigate(`/news/edit/${params.id}`, {
      state: {
        newsItem: news.find((item) => item.id === params.id),
      },
    });
  };

  const handleDelete = (params) => {
    requestConfirm({
      title: "Confirm Delete",
      description: "Are you sure you want to delete this item?",
      onConfirm: () =>
        newsService
          .deleteNews(params.id)
          .then((res) => {
            setNews(news.filter((mr) => mr.id !== params.id));
            toastSuccessHelper("News item deleted successfully");
          })
          .catch((err) => {
            toastErrorHelper("Failed to delete news item");
            displayError(err.response?.data?.detail || err.message);
          }),
    });
  };

  const handleCreate = () => {
    navigate("/news/create");
  };
  const handleExport = () => {
    setDateRangeDialogOpen(true);
  };
  const downloadNews = async (newsItems) => {
    if (newsItems.length == 0) {
      displayError("No news to download");
      return;
    }
    try {
      const doc = new Document({ sections: [] });
      const groupedByCountry = newsItems.reduce((acc, item) => {
        if (item.companies && item.companies.length > 0) {
          item.companies.forEach((company) => {
            if (!acc[company.country]) {
              acc[company.country] = {};
            }
            item.themes.forEach((theme) => {
              if (!acc[company.country][theme.label]) {
                acc[company.country][theme.label] = [];
              }
              acc[company.country][theme.label].push({
                news: item.news,
                date: item.date_news,
                title: item.title,
              });
            });
          });
        }
        return acc;
      }, {});
      const sortedNewsByCountry = Object.keys(groupedByCountry)
        .sort()
        .reduce((acc, key) => {
          acc[key] = groupedByCountry[key];
          return acc;
        }, {});
      const content = [];
      for (const [country, themes] of Object.entries(sortedNewsByCountry)) {
        content.push(
          new Paragraph({
            children: [
              new TextRun({
                text: `${country}`,
                bold: true,
                size: 28,
              }),
            ],
            spacing: {
              after: 200,
            },
          })
        );
        for (const [theme, newsItems] of Object.entries(themes)) {
          content.push(
            new Paragraph({
              children: [
                new TextRun({
                  text: `${theme}`,
                  italics: true,
                  underline: true,
                  size: 24,
                }),
              ],
              spacing: {
                after: 150,
              },
            })
          );
          for (const { news } of newsItems) {
            const text = htmlToText(news, {
              wordwrap: 130,
              uppercaseHeadings: false,
              singleNewLineParagraphs: true,
              preserveNewlines: false,
              selectors: [
                { selector: "strong", format: "text" },
                { selector: "em", format: "text" },
                { selector: "p", format: "paragraph" },
              ],
              formatters: {
                text: function (elem, walk, builder) {
                  if (elem.name === "strong") {
                    builder.addInline("**");
                    walk(elem.children, builder);
                    builder.addInline("**");
                  } else if (elem.name === "em") {
                    builder.addInline("_");
                    walk(elem.children, builder);
                    builder.addInline("_");
                  } else {
                    walk(elem.children, builder);
                  }
                },
              },
            });

            const paragraphs = text.split("\n\n");
            paragraphs.forEach((paragraph) => {
              const runs = [];
              const parts = paragraph.split(/(\*\*.*?\*\*)/g);
              parts.forEach((part) => {
                if (part.startsWith("**") && part.endsWith("**")) {
                  runs.push(
                    new TextRun({
                      text: part.slice(2, -2),
                      bold: true,
                    })
                  );
                } else {
                  runs.push(new TextRun(part));
                }
              });
              content.push(
                new Paragraph({
                  children: runs,
                  bullet: {
                    level: 0,
                  },
                  spacing: {
                    after: 100,
                  },
                })
              );
            });
          }
        }
      }

      doc.addSection({
        children: content,
      });
      const currentDate = new Date();
      const formattedDate = currentDate.toISOString().split("T")[0];
      Packer.toBlob(doc)
        .then((blob) => {
          saveAs(blob, `News_[${formattedDate}].docx`);
        })
        .catch((err) => {
          toastErrorHelper("Failed to generate document:", err);
        });
    } catch (error) {
      toastErrorHelper("Error initializing document:", error);
    }
  };

  const handleDateRangeConfirm = (range) => {
    if (!range.startDate || !range.endDate) {
      displayError("Both Start Date and End Date are required.");
      return;
    }
    if (new Date(range.startDate) > new Date(range.endDate)) {
      displayError("Start Date cannot be later than End Date.");
      return;
    }
    newsService
      .getManyNews(1, 100000, "date_news", "desc", {
        filter_field: "date_range",
        filter_value: JSON.stringify([range.startDate, range.endDate]),
      })
      .then((res) => downloadNews(res.data.news_items))
      .catch((err) => displayError(err.response?.data?.detail || err.message));
  };

  React.useEffect(() => {
    fetchNews();
  }, [page, pageSize, JSON.stringify(filterModel), JSON.stringify(sortModel)]);

  if (rowCount == null) {
    return <Loading />;
  }

  const rows = news;
  const columns = [
    {
      field: "id",
      headerName: "ID",
      filterOperators: getGridStringOperators().filter((op) =>
        ["equals"].includes(op.value)
      ),
      width: 75,
    },
    {
      field: "date_news",
      headerName: "Date",
      filterOperators: getGridStringOperators().filter((op) =>
        ["contains"].includes(op.value)
      ),
      width: 100,
    },
    {
      field: "title",
      headerName: "Title",
      filterOperators: getGridStringOperators().filter((op) =>
        ["contains"].includes(op.value)
      ),
      width: 150,
    },
    {
      field: "themes",
      headerName: "Themes",
      filterOperators: getGridStringOperators().filter((op) =>
        ["contains"].includes(op.value)
      ),
      width: 150,
    },
    {
      field: "persons",
      headerName: "Persons",
      filterOperators: getGridStringOperators().filter((op) =>
        ["contains"].includes(op.value)
      ),
      width: 150,
    },
    {
      field: "companies",
      headerName: "Companies",
      filterOperators: getGridStringOperators().filter((op) =>
        ["contains"].includes(op.value)
      ),
      width: 150,
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      renderCell: (params) => {
        const status = params.value;
        const backgroundColor = getStatusColor(status);
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
            }}
          >
            <Box
              sx={{
                backgroundColor, // Apply the background color
                color: "white", // Adjust text color for better readability
                padding: "4px", // Optional: Add padding for visual aesthetics
                borderRadius: "4px", // Optional: Add border radius for rounded corners
                width: "100px", // Fixed width for the status text box
                textAlign: "center", // Center the text inside the box
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              <Typography>{status}</Typography>
            </Box>
          </Box>
        );
      },
      filterOperators: getGridStringOperators().filter((op) =>
        ["contains"].includes(op.value)
      ),
    },
  ];

  return (
    <>
      <Card>
        <Title title="News List" />
        <CardContent>
          <CustomDataGrid
            rows={rows}
            columns={columns}
            pageSize={pageSize}
            rowCount={rowCount}
            loading={loading}
            page={page}
            setPage={setPage}
            setPageSize={setPageSize}
            sortModel={sortModel}
            setSortModel={setSortModel}
            filterModel={filterModel}
            handleFilterChange={handleFilterChange}
            showCreateButton={true}
            showShowButton={true}
            showEditButton={true}
            showDeleteButton={true}
            showExportButton={true}
            onCreate={handleCreate}
            onShow={handleShow}
            onEdit={handleEdit}
            onDelete={handleDelete}
            onExport={handleExport}
          />
        </CardContent>
      </Card>
      <DateRangeDialog
        open={dateRangeDialogOpen}
        onClose={() => setDateRangeDialogOpen(false)}
        onConfirm={handleDateRangeConfirm}
      />
    </>
  );
};

export default NewsListPage;
