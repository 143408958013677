export const Role = {
  ANY: "ANY",
  NONE: "NONE",
  SCALENS_SUPER_ADMIN: "scalens-super-admin", //Benedicte
  SCALENS_CUSTOMER_MANAGER: "scalens-customer-management", //Michel
  SCALENS_ANALYST_ADMIN: "scalens-analyst-admin", //Othmane, Daniele
  SCALENS_EXTERNAL_STUDENT: "scalens-external-student", //Student
};
export const ALL_EXCEPT = (unauthorized) => {
  return Object.values(Role).filter(
    (key) => !unauthorized.includes(key) && key != Role.ANY && key != Role.NONE,
  );
};
