import { apiClientInvestigate } from "../api-client";
import { config } from "../../config";
class MeetingReportFileService {
  getFileMeta(meeting_id) {
    return apiClientInvestigate.get(
      `/companies/assemblies/forms/${meeting_id}/files/metadata`
    );
  }
  uploadFile(meeting_id, file, fileName, category) {
    let formData = new FormData();
    formData.append("file", file);
    const params = {
      id: meeting_id,
      modified_filename: fileName,
      category: category,
    };
    return apiClientInvestigate.post(
      `/companies/assemblies/forms/${meeting_id}/files/one`,
      formData,
      {
        params,
      }
    );
  }
  updateFile(meeting_id, fileUid, field, value) {
    return apiClientInvestigate.patch(
      `/companies/assemblies/forms/${meeting_id}/files/${fileUid}/metadata`,
      null,
      {
        params: {
          field,
          value,
        },
      }
    );
  }

  deleteFile(meeting_id, uid) {
    return apiClientInvestigate.delete(
      `/companies/assemblies/forms/${meeting_id}/files/${uid}`
    );
  }

  getDownloadLink(meeting_id, s3_key) {
    return `${apiClientInvestigate.getUri()}/companies/assemblies/forms/${meeting_id}/files/${s3_key}/download`;
  }
}

export default new MeetingReportFileService();
