import React from "react";
import { Button, Badge } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ResetIcon from "@mui/icons-material/SettingsBackupRestore";
import IconButton from "@mui/material/IconButton";
import HelpIcon from "@mui/icons-material/Help";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import { useUserRole } from "../hooks/useUserRole";
import { Role } from "../data/roles";
// import StyledLabel from "./StyledLabel";

const DataSender = ({
  key,
  children,
  title,
  status,
  comment,
  onStatusChange,
  onUpdate,
  onResetClicked,
}) => {
  const userRole = useUserRole();
  const containerStyle =
    status == "ACCEPTED"
      ? {
          pointerEvents: "none",
          opacity: 0.5,
        }
      : {};
  const badgeStyle = {
    fontSize: "0.75rem", // Adjust the font size as needed
    color: "#ffffff", // Text color
    backgroundColor: "#4caf50", // Background color of the badge, green for success
    borderRadius: "10px", // Gives the badge rounded corners
    padding: "5px 10px", // Adjust padding to control the size of the badge
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "20px", // Set a fixed height for the badge
    boxSizing: "border-box",
  };
  return (
    <>
      <InputLabel
        // onClick={onClick}
        sx={{
          border: "1px solid #001756",
          backgroundColor: "#001756",
          padding: 1,
          borderRadius: 1,
          color: "#FFF",
          fontSize: 19,
          fontWeight: "medium",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        htmlFor="company-input"
      >
        <Box sx={{ display: "flex", flexDirection: "horizontal" }}>
          <span style={{ marginRight: 10 }}>{title}</span>
          {/* <Box> */}
          {status == "REJECTED" && (
            <Tooltip
              title={
                <Typography
                  component="span"
                  dangerouslySetInnerHTML={{ __html: comment }}
                />
              }
              arrow
              placement="top-end"
              componentsProps={{
                tooltip: {
                  sx: {
                    maxWidth: "600px",
                    backgroundColor: "#FFF9C4",
                    color: "black",
                    borderRadius: "8px",
                    padding: "10px",
                  },
                },
              }}
            >
              <HelpIcon />
            </Tooltip>
          )}
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {" "}
          <Box
            sx={{
              minWidth: 50,
              padding: "2px",
              marginRight: 2,
            }}
            onClick={() => onResetClicked()}
          >
            <IconButton
              disabled={status == "ACCEPTED"}
              sx={{ backgroundColor: "#dcedc8" }}
            >
              <ResetIcon />
            </IconButton>
          </Box>
          <Box
            sx={{
              minWidth: 120,
              backgroundColor: "whitesmoke",
              padding: "2px",
              borderRadius: "10px",
              marginRight: 2,
            }}
          >
            <FormControl
              fullWidth
              size="small"
              sx={{ m: 0, minHeight: "auto" }}
            >
              <Select
                disabled={
                  status == "ACCEPTED" ||
                  userRole == Role.SCALENS_EXTERNAL_STUDENT
                }
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={status}
                label="Status"
                onChange={(e) => {
                  onStatusChange(e.target.value);
                }}
                sx={{
                  m: 0,
                  lineHeight: "1",
                  minHeight: "auto",
                  ".MuiSelect-select": { py: 0.5 },
                }}
              >
                <MenuItem
                  value={"PENDING"}
                  sx={{ fontSize: "0.875rem", py: 0.5, color: "orange" }}
                >
                  PENDING
                </MenuItem>
                <MenuItem
                  // disabled={userRole == Role.SCALENS_EXTERNAL_STUDENT}
                  value={"ACCEPTED"}
                  sx={{ fontSize: "0.875rem", py: 0.5, color: "green" }}
                >
                  ACCEPTED
                </MenuItem>
                <MenuItem
                  // disabled={userRole == Role.SCALENS_EXTERNAL_STUDENT}
                  value={"REJECTED"}
                  sx={{ fontSize: "0.875rem", py: 0.5, color: "red" }}
                >
                  REJECTED
                </MenuItem>
                {/* <MenuItem
                    value={"COMPLETED"}
                    sx={{ fontSize: "0.875rem", py: 0.5 }}
                  >
                    COMPLETED
                  </MenuItem> */}
              </Select>
            </FormControl>
          </Box>
          <Button
            disabled={status == "ACCEPTED"}
            variant="contained"
            onClick={onUpdate}
          >
            Update
          </Button>
        </Box>
      </InputLabel>
      <div style={{ position: "relative", ...containerStyle }}>
        <br />
        {children}
        <br />
      </div>
    </>
  );
};

export default DataSender;
