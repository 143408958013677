import React, { useState } from "react";
import { Card, CardContent, Tabs, Tab, Box, Typography } from "@mui/material";
import NotificationTypes from "./NotificationType/NotificationTypesDisplay";
import NotificationMethods from "./NotificationMethod/NotificationMethodDisplay";
import NotificationFrequencies from "./NotificationFrequency/NotificationFrequencyDisplay";

// Enum-like object for component keys
const NOTIFICATION_COMPONENTS = {
  TYPES: "Notification Types",
  METHODS: "Notification Methods",
  FREQUENCIES: "Notification Frequencies",
};

const Notifications = () => {
  const [selectedTab, setSelectedTab] = useState(NOTIFICATION_COMPONENTS.TYPES);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  // Define the mapping for the components to their respective tabs
  const tabComponents = {
    [NOTIFICATION_COMPONENTS.TYPES]: NotificationTypes,
    [NOTIFICATION_COMPONENTS.METHODS]: NotificationMethods,
    [NOTIFICATION_COMPONENTS.FREQUENCIES]: NotificationFrequencies,
  };

  // Render tab panel content
  const renderTabPanel = (componentKey) => {
    const Component = tabComponents[componentKey];
    return (
      <Box sx={{ padding: 2 }}>
        {/* <Typography variant="h5" gutterBottom>
          {componentKey}
        </Typography> */}
        <Component />
      </Box>
    );
  };

  return (
    <Card>
      <CardContent>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            aria-label="notification settings tabs"
          >
            {Object.values(NOTIFICATION_COMPONENTS).map((componentKey) => (
              <Tab
                key={componentKey}
                label={componentKey}
                value={componentKey}
              />
            ))}
          </Tabs>
        </Box>
        {renderTabPanel(selectedTab)}
      </CardContent>
    </Card>
  );
};

export default Notifications;
