import React from "react";
import { Menu } from "react-admin";
import GroupsIcon from "@mui/icons-material/Groups";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ViewWeekIcon from "@mui/icons-material/ViewWeek";
import ArticleIcon from "@mui/icons-material/Article";
import GroupIcon from "@mui/icons-material/Group";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import LogoutIcon from "@mui/icons-material/Logout";
import EditNotificationsIcon from "@mui/icons-material/EditNotifications";
import { useAuth0 } from "@auth0/auth0-react";
export const MyMenu = () => {
  const { logout } = useAuth0();

  const handleLogout = () => logout({ returnTo: window.location.origin });

  return (
    <Menu>
      {/* <Menu.DashboardItem /> */}
      {/* <Menu.ResourceItem name="news" /> */}
      <Menu.Item
        to="/meeting-reports"
        primaryText="Meeting Reports"
        leftIcon={<GroupsIcon />}
      />
      <Menu.Item
        to="/upload-monitoring"
        primaryText="Upload Monitoring"
        leftIcon={<DashboardIcon />}
      />
      <Menu.Item
        to="/upload-weekly"
        primaryText="Upload Weekly Files"
        leftIcon={<ViewWeekIcon />}
      />
      <Menu.Item
        to="/periodic-results"
        primaryText="Annual & Semi Annual Results"
        leftIcon={<ArticleIcon />}
      />
      <Menu.Item to="/peers" primaryText="Peers" leftIcon={<GroupIcon />} />
      {/* <Menu.Item
      to="/notifications"
      primaryText="Notifications"
      leftIcon={<EditNotificationsIcon />}
    /> */}
      <Menu.Item to="/news" primaryText="News" leftIcon={<NewspaperIcon />} />
      <div style={{ position: "relative" }}>
        <button
          onClick={handleLogout}
          style={{
            position: "absolute",
            right: 10,
            zIndex: 1000,
            padding: "5px",
            backgroundColor: "#ab0022",
            color: "white",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
          }}
        >
          <LogoutIcon onClick={handleLogout} />
        </button>
      </div>
    </Menu>
  );
};
