import { useState, useEffect } from "react";
import {
  toastSuccessHelper,
  toastErrorHelper,
} from "../../../components/Toaster";
import { ToastContainer } from "react-toastify";
import { TextField, Box, Button, Typography } from "@mui/material";
import axios from "axios";
import BasicCard from "../../../components/BasicCard";
import { config } from "../../../config";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
const NotificationFrequencyCreate = () => {
  const [notificationFrequency, setNotificationFrequency] = useState("");
  //--------------for the confirmation dialog------------
  const [openDialog, setOpenDialog] = useState(false);
  //-----------------API functions-----------------

  const createNotificationFrequency = async (payload) => {
    try {
      const response = await axios.post(
        `${config.base_url}/notifications/frequencies`,
        payload
      );
      console.log(
        "notification frequency created successfully: ",
        response.data
      );
      // setIsLoading(false);
      toastSuccessHelper("notification frequency created Successfully");
    } catch (error) {
      console.log(error);
      // setIsLoading(false);
      toastErrorHelper("Failed to Create notification frequency");
    }
  };
  //-----------------Local functions-----------------

  const handleNotificationFrequencyChange = (event) => {
    setNotificationFrequency(event.target.value);
  };
  const handleConfirm = async () => {
    const payload = {
      frequency_interval: notificationFrequency,
    };
    console.log("HERE");
    console.log(payload);
    createNotificationFrequency(payload);
    setOpenDialog(false); // Close the confirmation dialog
  };
  return (
    <Box sx={{ maxWidth: 800, mx: "auto", mt: 4 }}>
      <ToastContainer style={{ marginTop: 35 }} />
      <Typography sx={{ color: "#001756" }} variant="h3" gutterBottom>
        Notification frequency Creation Form
      </Typography>
      <form>
        <BasicCard elevation={0} title="Notification frequency">
          <TextField
            value={notificationFrequency}
            onChange={handleNotificationFrequencyChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </BasicCard>

        <Button
          disabled={!notificationFrequency}
          onClick={() => setOpenDialog(true)}
          variant="contained"
          sx={{ float: "right", mt: 2 }}
        >
          Submit
        </Button>
      </form>

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Confirm Submission</DialogTitle>
        <DialogContent>
          Are you sure you want to submit this form?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button onClick={handleConfirm} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default NotificationFrequencyCreate;
