import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      app_title: "Meeting report",
      general: "General",
      company: "Company",
      meeting_date: "Meeting date",
      report_made_by: "Report made by",
      student_email: "Student Email",
      report_has_posponed: "Has this meeting been postponed ?",
      terms: "Terms",
      meeting_type: "Meeting type",
      no_posponement: "No postponement, held in the 1st call",
      posponement_cause: "postponement due to covid",
      held_second: "Held on 2nd call",
      held_adhoc: "Held with an ad hoc mandatary",
      other_reason_posponement: "Postponement for other reason",
      scene_description: "Description of the scene (desk, colors,...)",
      office_composition: "Composition of the Office",
      assembly_president: "President of the Assembly",
      scrutineer1: "Scrutineer #1",
      scrutineer2: "Scrutineer #2",
      quorum: "Quorum",
      physical_attendance: "Attendance in person",
      all_present: "Attendance of directors",
      connected_persons: "Number of connected persons",
      french_version: "Connect to the French version",
      english_version: "Connect to the English version",
      total_duration: "Total duration",
      course: "Course of action",
      highlights: "Highlights",
      finance: "Finance",
      strategy: "Strategy",
      governance: "Governance",
      compensation: "Compensation",
      rse: "RSE",
      covid: "Covid-19",
      qa: "Q&A",
      opening_formalities: "Opening formalities and votes",
      presentations: "Presentations",
      summary: "Summary for publication",
      summary_template: "Auto Generated Summary",
      presentation: "Présentation",
      start: "Start",
      end: "End",
      duration: "Duration",
      topic: "topic",
      speaker: "Speaker",
      key_points: "Key points",
      record: "Record",
      remuneration: "Remuneration",
      q1: "Q1",
      key_facts: "Key facts",
      esg: "ESG",
      energy_crisis: "Energy crisis",
      votes_results: "Votes results",
      closed_doors: "In person",
      live_voting: "Live voting  possible",
      presential_online: "Presential / Online",
      agm_annual: "Annual AGM",
      agm_capital_increase: "AGM for capital increase",
      agm_subject_of_remuneration: "AGM for subject of remuneration",
      agm_collective_proceedings: "AGM for collective proceedings",
      agm_other: "AGM for other reasons",
      held_adhoc_representative: "Held with an ad hoc representative",
      scrutineer: "Scrutineer",
      score: "Score",
      company_name: "Company name",
      description: "Description",
      others: "Others",
      directors_attendance: "Attendance of directors",
      board: "Board",
      executive_comitee: "Attendance of executive commitee",
      time_allocation: "Time allocation",
      place: "Place",
      venue: "Venue",
      webcast: "Webcast",
      replay: "Replay",
      live_qa: "Live Q&A",
      domestic_language: "Domestic language",
      simultaneous_trans: "Simultaneous translation",
      exclusive_eng: "Exclusively in English",
      domestic_version: "Domestic version",
      connected_eng: "Connect to the english version",
      add_expo: "Add presentation",
      edit_expo: "Edit presentation",
      add_question: "Add question",
      question: "Question",
      type: "Type",
      theme: "Theme",
      theme_2: "Additional Theme",
      author: "Author",
      language: "Language",
      answer: "Answer",
      question_type: "Type of question",
      question_author: "Author of the question",
      tag: "Tag",
      response: "Response",
      response_author: "Author of the response",
      oral: "Oral",
      written: "Written",
      mail_sms: "Mail/SMS/Chat",
      board_secretary: "Secretary of the Assembly",
      formal_aspects: "Formal aspects",
      auditor: "Auditor",
      required_fields: "Required fields",
      no_assembly: "No assembly",
      add_reso: "Add new resolution",
      add_score: "Add score",
      reso_name: "Resolution name",
      agm_interruption: "AGM interruption",
      proposed_at_agm: "Proposed at AGM",
      shareholder_proposal: "Shareholder Proposal",
      dissenting: "Dissenting",
      meeting_status: "Meeting Status",
      assembly_files: "Assembly Files",
    },
  },
  fr: {
    translation: {
      app_title: "Compte rendu Assemblée",
      general: "Génerale",
      company: "Société",
      meeting_date: "Date assemblée",
      report_made_by: "Compte rendu fait par",
      report_has_posponed:
        "Cette assemblée a-t-elle fait l’objet d’un report ?",
      terms: "Modalités",
      meeting_type: "Type d'assemblée",
      no_posponement: "Pas de report, tenue en 1 ère convocation",
      posponement_cause: "Report pour cause de covid",
      held_second: "Tenue en 2ème convocation",
      held_adhoc: "Tenue avec un mandataire ad hoc",
      other_reason_posponement: "Report pour autre raison",
      scene_description: "Description de la scène (desk, couleurs,...)",
      office_composition: "Composition du bureau",
      assembly_president: "Président de l’Assemblée",
      scrutineer1: "Scrutateur 1",
      scrutineer2: "Scrutateur 2",
      quorum: "Quorum",
      physical_attendance: "Affluence en personne",
      all_present: "Présence de tous les administrateurs",
      connected_persons: "Nombre de personnes connectées",
      french_version: "Connectés à la version française",
      english_version: "Connectés à la version anglaise",
      total_duration: "Durée totale",
      course: "Déroulé",
      highlights: "Points saillants",
      finance: "Finance",
      strategy: "Stratégie",
      governance: "Gouvernance",
      compensation: "Rémunération",
      rse: "RSE",
      covid: "Covid-19",
      qa: "Q&A",
      opening_formalities: "Formalités d’ouverture et vote",
      presentations: "EXPOSES",
      summary: "Résumé pour publication",
      presentation: "Exposé",
      start: "Début",
      end: "Fin",
      duration: "Durée",
      topic: "thème",
      speaker: "Orateur",
      key_points: "Points saillants",
      record: "Enregistrer",
      remuneration: "Rémuneration",
      q1: "Q1",
      key_facts: "Points clés",
      esg: "ESG",
      energy_crisis: "Crise énergétique",
      votes_results: "Résultat des votes",
      closed_doors: "Huis clos",
      live_voting: "Vote en direct possible",
      presential_online: "Presentiel / En ligne",
      agm_annual: "AGE annuelle",
      agm_capital_increase: "AGE pour augmentation de capital",
      agm_subject_of_remuneration: "AGE pour sujet de rémunération",
      agm_collective_proceedings: "AGE pour procédure collective",
      agm_other: "AGE pour autre motif",
      held_adhoc_representative: "Held with an ad hoc representative",
      scrutineer: "Scrutateur",
      score: "Score",
      company_name: "Nom de la société",
      description: "Description",
      others: "Autres",
      directors_attendance: "Attendance of directors",
      board: "Board",
      executive_comitee: "Comité exécutif",
      time_allocation: "Time allocation",
      place: "Lieu",
      venue: "Venue",
      webcast: "Webcast",
      replay: "Replay",
      live_qa: "Live Q&A",
      domestic_language: "Langue domestique",
      simultaneous_trans: "Traduction simultanée",
      exclusive_eng: "Exclusivement en anglais",
      add_expo: "Ajouter exposé",
      edit_expo: "Modifier exposé",
      add_question: "Ajouter question",
      question: "Question",
      type: "Type",
      theme: "Theme",
      theme_2: "thème supplémentaire",
      author: "Auteur",
      language: "Language",
      answer: "Reponse",
      question_type: "Type de question",
      question_author: "Auteur de la question",
      tag: "Tag",
      response: "Réponse",
      response_author: "Auteur de la réponse",
      oral: "Oral",
      written: "Ecrit",
      mail_sms: "Mail/SMS/Chat",
      board_secretary: "Secretariat du conseil",
      formal_aspects: "Formal aspects",
      auditor: "Auditeur",
      required_fields: "Champs obligatoires",
      no_assembly: "Aucune assemblée",
      add_reso: "Ajouter résolution",
      add_score: "Ajouter score",
      reso_name: "Nom de la résolution",
      agm_interruption: "Interruption AGE",
      proposed_at_agm: "Proposée à l'AGE",
    },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    resources,
    lng: "en",
    detection: {
      order: [
        "navigator",
        "htmlTag",
        "querystring",
        "cookie",
        "localStorage",
        "path",
        "subdomain",
      ],
    },
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
