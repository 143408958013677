import React, { createContext, useContext, useState } from "react";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import CloseIcon from "@mui/icons-material/Close";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
const TransitionAlerts = ({ open, message, onClose }) => {
  const [showCopiedTooltip, setShowCopiedTooltip] = useState(false);

  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setShowCopiedTooltip(true);
        setTimeout(() => setShowCopiedTooltip(false), 1000); // Hide tooltip after 1 second
      })
      .catch((err) => {
        console.error("Could not copy text: ", err);
      });
  };

  return (
    <Box
      sx={{
        width: "100%",
        position: "fixed",
        bottom: 0,
        zIndex: 1000,
        left: 0,
        right: 0,
      }}
    >
      <Collapse in={open}>
        <Alert
          severity="error"
          action={
            <>
              <Tooltip title="Copied!" open={showCopiedTooltip} arrow>
                <IconButton
                  aria-label="copy"
                  color="inherit"
                  size="small"
                  onClick={() => copyToClipboard(message)}
                >
                  <ContentCopyIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={onClose}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            </>
          }
          sx={{ mb: 2, marginTop: 7 }}
        >
          {message}
        </Alert>
      </Collapse>
    </Box>
  );
};

const AlertContext = createContext();

export const useAlert = () => useContext(AlertContext);

export const AlertProvider = ({ children }) => {
  const [alertProps, setAlertProps] = useState({ open: false, message: "" });

  const showAlert = (message) => {
    setAlertProps({ open: true, message:JSON.stringify(message) });
  };

  const hideAlert = () => {
    setAlertProps({ open: false, message: "" });
  };

  return (
    <AlertContext.Provider value={{ showAlert, hideAlert }}>
      <TransitionAlerts {...alertProps} onClose={hideAlert} />
      {children}
    </AlertContext.Provider>
  );
};
