import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import StyledLabel from "../StyledLabel";

const filter = createFilterOptions();

const ProceedingThemesSelect = ({
  label,
  instructions,
  value,
  onChange,
  options,
}) => {
  return (
    <>
      {label && <StyledLabel text={label} />}
      <Autocomplete
        value={value || instructions}
        onChange={onChange}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          return filtered;
        }}
        id="search-input-dialog"
        options={options || []}
        getOptionLabel={(option) => {
          if (typeof option === "string") {
            return option;
          }
          if (option.theme) {
            return option.theme;
          }
          return "";
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        renderOption={(props, option) => <li {...props}>{option.theme}</li>}
        sx={{ width: "100%" }}
        freeSolo
        renderInput={(params) => <TextField {...params} />}
      />
    </>
  );
};

export default ProceedingThemesSelect;
