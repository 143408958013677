import React from "react";

import Typography from "@mui/material/Typography";
export default function Loading() {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        width: "100%",
      }}
    >
      <Typography variant="body1" style={{ marginLeft: "8px" }}>
        Loading...
      </Typography>
    </div>
  );
}
