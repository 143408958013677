import React from "react";
import { Auth0Provider } from "@auth0/auth0-react";
import { createRoot } from "react-dom/client";
import "./i18n";
import App from "./App";
import "./index.css";
import "/node_modules/flag-icons/css/flag-icons.min.css";
import ToastifyProvider from "./providers/ToastifyProvider";
import { ConfirmationDialogProvider } from "./providers/ConfirmationDialogProvider";
import { AxiosProvider } from "./providers/AxiosProvider";
import { AlertProvider } from "./providers/AlertProvider";

const root = createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      redirectUri={window.location.origin}
      useRefreshTokens={true}
    >
      <AlertProvider>
        <ToastifyProvider>
          <ConfirmationDialogProvider>
            <AxiosProvider>
              <App />
            </AxiosProvider>
          </ConfirmationDialogProvider>
        </ToastifyProvider>
      </AlertProvider>
    </Auth0Provider>
  </React.StrictMode>,
);
