import { apiClientInvestigate } from "../api-client";

class UtilService {
  getAllCompanies() {
    return apiClientInvestigate.get("/companies");
  }
  getOneCompanyById(id) {
    return apiClientInvestigate.get(`/companies/${id}`);
  }

  getManyCompaniesByIds(ids) {
    const ids_string = JSON.stringify(ids);
    return apiClientInvestigate.get("/companies/by-ids", {
      params: {
        ids: ids_string,
      },
    });
  }
  getManyCompaniesByUids(uids) {
    const uids_string = JSON.stringify(uids);
    return apiClientInvestigate.get("/companies/by-uids", {
      params: {
        uids: uids_string,
      },
    });
  }
  // -------------------
  getResolutionTypes() {
    return apiClientInvestigate.get("/companies/assemblies/resolutions/types");
  }
  getAssemblyResolutions(assemblyId) {
    return apiClientInvestigate.get(
      `/companies/assemblies/${assemblyId}/resolutions`
    );
  }
  classifyResolution(resolution_name) {
    return apiClientInvestigate.get(
      `/companies/assemblies/resolutions/classify`,
      {
        params: {
          resolution_name: resolution_name,
        },
      }
    );
  }
  // -------------------
  getCompanyComexes(companyId) {
    return apiClientInvestigate.get(`/companies/${companyId}/comexes`);
  }
  getCompanyMandataries(companyId) {
    return apiClientInvestigate.get(`/companies/${companyId}/mandataries`);
  }
  // -------------------
  getAssembliesForCompany(companyId) {
    return apiClientInvestigate.get(`/companies/${companyId}/assemblies`);
  }
  //--------------------
  getQuestionsThemes() {
    return apiClientInvestigate.get("/companies/assemblies/questions/themes");
  }
  getProceedingsThemes() {
    return apiClientInvestigate.get(
      "/companies/assemblies/presentations/themes"
    );
  }
}

export default new UtilService();
