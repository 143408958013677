import React, { Component } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

class RichTextEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editorHtml: ''
    };
  }

  //initialized the text editor state
  componentDidMount() {
    const { value } = this.props;
    const savedContent = localStorage.getItem('richTextEditorContent');
    this.setState({
      editorHtml: value ? value : (savedContent ? savedContent : '')
    });
  }

  // Delete existing text editor value on localstorage
  componentWillUnmount() {
    const richTextEditorContent = localStorage.getItem('richTextEditorContent');
    if (richTextEditorContent) {
      localStorage.removeItem('richTextEditorContent');
    }
  }

  // update the text editor value when summary is update
  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      const { value } = this.props;
      this.setState({
        editorHtml: value
      });
    }
  }

  // Update the text editor value when writing in it
  handleSummaryChange = (content) => {
    const { onChange } = this.props;
    this.setState({ editorHtml: content });
    localStorage.setItem('richTextEditorContent', content);
    onChange(content);
  };

  render() {
    const { editorHtml } = this.state;
    const { summary } = this.props;
    const { disabled } = this.props
    const { style } = this.props
    return (
      // Rich text editor component
      <ReactQuill
        key={summary} 
        value={editorHtml}
        onChange={this.handleSummaryChange}
        readOnly={disabled}
        style={style}
        modules={{
          // Set editor options ...
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['link'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['clean'] 
          ]
        }}
        formats={[
          'bold',
          'italic',
          'underline',
          'strike',
          'link',
          'list',
          'bullet'
        ]}
      />
    )
  }
}

export default RichTextEditor;