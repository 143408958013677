import React, { useEffect, useState } from "react";
import {
  Container,
  Card,
  CardContent,
  Typography,
  Box,
  TextField,
  Button,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import AsyncSelect from "react-select/async";
import { useNavigate, useParams } from "react-router-dom";
import newsService from "../../services/boarddata/news-service";
import companyService from "../../services/boarddata/company-service";
import personService from "../../services/boarddata/person-service";
import { useAlert } from "../../providers/AlertProvider";
import Loading from "../../components/Loading";
import { getStatusColor } from "../../utils";
import RichText from "../../components/RichTextEditor/RichText";
import { useUserRole } from "../../hooks/useUserRole";
import { Role } from "../../data/roles";

const customStyles = {
  control: (provided) => ({
    ...provided,
    display: "flex",
    height: "56px",
    padding: "0 14px",
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: "4px",
    "&:hover": {
      borderColor: "rgba(0, 0, 0, 0.87)",
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: "0",
  }),
  input: (provided) => ({
    ...provided,
    margin: "0",
    padding: "0",
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 2000,
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: "#e0e0e0",
    borderRadius: "2px",
    margin: "2px",
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    padding: "2px 6px",
    color: "rgba(0, 0, 0, 0.87)",
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    color: "rgba(0, 0, 0, 0.87)",
    "&:hover": {
      backgroundColor: "#c2c2c2",
      color: "rgba(0, 0, 0, 0.87)",
    },
  }),
};

const NewsEditPage = () => {
  const userRole = useUserRole();
  const { id } = useParams();
  const navigate = useNavigate();
  const { showAlert: displayError } = useAlert();
  const [loading, setLoading] = useState(true);
  const [newsItem, setNewsItem] = useState({
    title: "",
    date_news: "",
    news: "",
    themes: [],
    persons: [],
    companies: [],
    status: "PENDING",
  });

  const [themeOptions, setThemeOptions] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);
  const [isTitleManuallySet, setIsTitleManuallySet] = useState(false); // Track if title is manually set

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "title") {
      setIsTitleManuallySet(true);
    }
    setNewsItem((prev) => ({ ...prev, [name]: value }));
  };

  const handleDateChange = (e) => {
    setNewsItem((prev) => ({ ...prev, date_news: e.target.value }));
  };

  const handleThemeChange = (event, values) => {
    const themeKeys = values.map((item) => item.key);
    setNewsItem((prev) => ({ ...prev, themes: themeKeys }));
    if (!isTitleManuallySet && values.length > 0) {
      const themeLabels = values.map((item) => item.value).join(", ");
      setNewsItem((prev) => ({ ...prev, title: themeLabels }));
    }
  };

  const loadCompanyOptions = (inputValue) => {
    if (!inputValue) {
      return Promise.resolve([]);
    }
    return companyService
      .searchCompanies(inputValue)
      .then((res) => res.data)
      .catch((err) => {
        displayError(err.res?.data?.detail || err.message);
        return [];
      });
  };

  const loadPersonOptions = (inputValue) => {
    if (!inputValue) {
      return Promise.resolve([]);
    }
    return personService
      .searchPersons(inputValue)
      .then((res) => res.data)
      .catch((err) => {
        displayError(err.res?.data?.detail || err.message);
        return [];
      });
  };

  const handleCompanyChange = (values) => {
    setNewsItem((prev) => ({ ...prev, companies: values }));
  };

  const handlePersonChange = (values) => {
    setNewsItem((prev) => ({ ...prev, persons: values }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (
      !newsItem.title ||
      !newsItem.date_news ||
      !newsItem.news ||
      newsItem.themes.length === 0
    ) {
      displayError("Title, date, news content, and themes are required.");
      return;
    }

    setLoading(true);

    newsService
      .updateNews(id, newsItem)
      .then(() => {
        navigate("/news");
      })
      .catch((err) => {
        displayError(err.res?.data?.detail || err.message);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    newsService
      .getOneNewsItem(id)
      .then((res) => {
        const { data } = res;
        setNewsItem({
          title: data.title,
          date_news: data.date_news,
          news: data.news,
          themes: data.themes.map((theme) => theme.key),
          persons: data.persons,
          companies: data.companies,
          status: data.status,
        });
        setLoading(false);
      })
      .catch((err) => {
        displayError(err.res?.data?.detail || err.message);
        setLoading(false);
      });

    newsService
      .getNewsThemes()
      .then((res) => setThemeOptions(res.data))
      .catch((err) => displayError(err.res?.data?.detail || err.message));

    newsService
      .getNewsStatus()
      .then((res) => setStatusOptions(res.data))
      .catch((err) => displayError(err.res?.data?.detail || err.message));
  }, [id, displayError]);

  if (loading) return <Loading />;

  return (
    <Container maxWidth="md" sx={{ marginTop: 4 }}>
      <Card elevation={3}>
        <CardContent>
          <Typography variant="h4" component="h1" gutterBottom>
            Edit News Item
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 2 }}
          >            
          <TextField
              label="Title"
              name="title"
              value={newsItem.title}
              onChange={handleInputChange}
              fullWidth
              required
              margin="normal"
            />
            <TextField
              type="date"
              label="Date"
              value={newsItem.date_news}
              onChange={handleDateChange}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{ marginBottom: 2 }}
              fullWidth
              required
            />
            <Typography variant="body1" gutterBottom>
              News Content *
            </Typography>
            <RichText
              text={newsItem.news}
              setText={(value) =>
                handleInputChange({ target: { name: "news", value: value } })
              }
            />
            <Autocomplete
              multiple
              options={themeOptions}
              getOptionLabel={(option) => option.value}
              value={themeOptions.filter((option) =>
                newsItem.themes.includes(option.key)
              )}
              onChange={handleThemeChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Themes"
                  placeholder="Select themes"
                  fullWidth
                  required
                  margin="normal"
                />
              )}
              sx={{ marginBottom: 1 }}
            />
            <AsyncSelect
              isMulti
              cacheOptions
              defaultOptions
              loadOptions={loadCompanyOptions}
              onChange={handleCompanyChange}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              placeholder="Search companies"
              styles={customStyles}
              value={newsItem.companies}
              noOptionsMessage={(obj) =>
                obj.inputValue
                  ? "No results found. Keep typing to search more companies."
                  : "Type to search for companies"
              }
            />
            <Box mt={2} />
            <AsyncSelect
              isMulti
              cacheOptions
              defaultOptions
              loadOptions={loadPersonOptions}
              onChange={handlePersonChange}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              placeholder="Search persons"
              styles={customStyles}
              value={newsItem.persons}
              noOptionsMessage={(obj) =>
                obj.inputValue
                  ? "No results found. Keep typing to search more persons."
                  : "Type to search for persons"
              }
            />
            <FormControl fullWidth margin="normal">
              <InputLabel>Status</InputLabel>
              <Select
                name="status"
                value={newsItem.status}
                onChange={handleInputChange}
                label="Status"
                sx={{ color: getStatusColor(newsItem.status) }}
              >
                {statusOptions.map((option) => (
                  <MenuItem
                    key={option.key}
                    value={option.key}
                    sx={{ color: getStatusColor(option.key) }}
                    disabled={
                      (option.key == "ACCEPTED" || option.key == "REJECTED") &&
                      userRole == Role.SCALENS_EXTERNAL_STUDENT
                    }
                  >
                    {option.value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Box mt={2} display="flex" justifyContent="flex-end">
              <Button type="submit" variant="contained" color="primary">
                Update News Item
              </Button>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
};

export default NewsEditPage;
