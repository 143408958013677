import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

const BasicCard = ({
  children,
  title,
  titleColor = "#001756",
  titleBackground,
  typo = "h6",
  elevation,
}) => {
  return (
    <Card
      elevation={elevation}
      sx={{ minWidth: 275, height: "fit-content", flex: 1 }}
    >
      <CardContent>
        <Typography
          style={{
            backgroundColor: titleBackground,
            margin: titleBackground && -16,
            padding: titleBackground && 5,
          }}
          variant={typo}
          color={titleColor}
          gutterBottom
        >
          {title}
        </Typography>
        {children}
      </CardContent>
    </Card>
  );
};

export default BasicCard;
