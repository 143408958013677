import { useEffect } from "react";
import { Admin, CustomRoutes } from "react-admin";
import { dataProvider } from "./providers/DataProvider";
import { Route } from "react-router-dom";
import MeetingReports from "./pages/MeetingReports/MeetingReportsDisplay";
import MeetingReportEdit from "./pages/MeetingReports/MeetingReportUpdate";
import { MyLayout } from "./layout/Layout";
import WelcomePage from "./pages/WelcomePage";
import { useAuth0 } from "@auth0/auth0-react";
import UploadMonitoring from "./pages/UploadMonitoring";
import UploadWeeklyFiles from "./pages/UploadWeeklyFiles";
import PeriodicResultsCreatePage from "./pages/PeriodicResults/PeriodicResultsCreatePage";
import PeriodicResultsListPage from "./pages/PeriodicResults/PeriodicResultsListPage";
import PeriodicResultsEditPage from "./pages/PeriodicResults/PeriodicResultsEditPage";
import PeersDisplay from "./pages/Peers/PeersDisplay";
import RenderPeerEdit from "./pages/Peers/PeerEdit";
import PeerCreate from "./pages/Peers/PeerCreate";
import Notifications from "./pages/Notifications/Notifications";
import NotificationTypeCreate from "./pages/Notifications/NotificationType/NotificationTypeCreate";
import NotificationTypeEdit from "./pages/Notifications/NotificationType/NotificationTypeEdit";
import NotificationMethodCreate from "./pages/Notifications/NotificationMethod/NotificationMethodCreate";
import NotificationMethodEdit from "./pages/Notifications/NotificationMethod/NotificationMethodEdit";
import NotificationFrequencyEdit from "./pages/Notifications/NotificationFrequency/NotificationFrequencyEdit";
import NotificationFrequencyCreate from "./pages/Notifications/NotificationFrequency/NotificationFrequencyCreate";
import NewsListPage from "./pages/News/NewsListPage";
import NewsCreatePage from "./pages/News/NewsCreatePage";
import NewsEditPage from "./pages/News/NewsEditPage";
import NewsDetailPage from "./pages/News/NewsDetailPage";
const App = () => {
  const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0();

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect();
    }
  }, [isLoading, isAuthenticated, loginWithRedirect]);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <p style={{ fontWeight: "bold" }}>Loading...</p>
      </div>
    );
  }

  if (!isAuthenticated) {
    return null;
  }

  return (
    <Admin
      title="Investigate Admin"
      dataProvider={dataProvider}
      layout={MyLayout}
    >
      <CustomRoutes>
        <Route path="/" element={<WelcomePage />} />
        <Route path="/meeting-reports" element={<MeetingReports />} />
        <Route
          path="/meeting-reports/edit/:id"
          element={<MeetingReportEdit />}
        />
        <Route path="/upload-monitoring" element={<UploadMonitoring />} />
        <Route path="/upload-weekly" element={<UploadWeeklyFiles />} />
        <Route
          path="/periodic-results/create"
          element={<PeriodicResultsCreatePage />}
        />
        <Route path="/periodic-results" element={<PeriodicResultsListPage />} />
        <Route
          path="/periodic-results/edit/:id"
          element={<PeriodicResultsEditPage />}
        />
        <Route path="/peers/create" element={<PeerCreate />} />
        <Route path="/peers" element={<PeersDisplay />} />
        <Route path="/peers/edit/:id" element={<RenderPeerEdit />} />

        <Route path="/news/create" element={<NewsCreatePage />} />
        <Route path="/news/show/:id" element={<NewsDetailPage />} />
        <Route path="/news/edit/:id" element={<NewsEditPage />} />
        <Route path="/news" element={<NewsListPage />} />

        <Route path="/notifications" element={<Notifications />} />
        <Route
          path="/notifications/types/create"
          element={<NotificationTypeCreate />}
        />
        <Route
          path="/notifications/types/edit/:id"
          element={<NotificationTypeEdit />}
        />
        <Route
          path="/notifications/methods/create"
          element={<NotificationMethodCreate />}
        />
        <Route
          path="/notifications/methods/edit/:id"
          element={<NotificationMethodEdit />}
        />
        <Route
          path="/notifications/frequencies/create"
          element={<NotificationFrequencyCreate />}
        />
        <Route
          path="/notifications/frequencies/edit/:id"
          element={<NotificationFrequencyEdit />}
        />
      </CustomRoutes>
    </Admin>
  );
};
export default App;
