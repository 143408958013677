import * as React from "react";
import { alpha, styled } from "@mui/material/styles";
import { GridToolbarContainer, GridPagination } from "@mui/x-data-grid";
import { Card, CardContent, Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Title, TextField } from "react-admin";
import { ToastContainer } from "react-toastify";
import {
  toastSuccessHelper,
  toastErrorHelper,
} from "../../../components/Toaster";
import {
  DataGrid,
  GridColDef,
  GridApi,
  gridClasses,
  GridToolbar,
  getGridStringOperators,
} from "@mui/x-data-grid";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useAxios } from "../../../providers/AxiosProvider";
import Loading from "../../../components/Loading";
const NotificationTypes = () => {
  //----------------- Hooks-------------------
  const navigate = useNavigate();
  const axios = useAxios();
  //-----------------FOR DATAGRID-------------------
  const columns = React.useRef(null);
  //   const [companyNames, setCompanyNames] = React.useState(null);
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [notificationTypeIdToDelete, setNotificationTypeIdToDelete] =
    React.useState(null);
  const [filterModel, setFilterModel] = React.useState({
    items: [],
  });
  const [sortModel, setSortModel] = React.useState([
    { field: "id", sort: "desc" },
  ]);
  const [notificationTypes, setNotificationTypes] = React.useState([]);
  const [rowCount, setRowCount] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  //----------------- FOR THE CONFIRMATION DIALOG -------------------
  const [openDialog, setOpenDialog] = React.useState(false);
  const handleConfirm = async () => {
    setOpenDialog(false);
    await deleteNotificationType(notificationTypeIdToDelete);
    fetchNotificationTypes();
  };
  //-----------------Functions to call apis--------------------
  const fetchNotificationTypes = async () => {
    setLoading(true);
    try {
      const filters = filterModel.items.reduce((acc, item) => {
        acc["filter_field"] = item.columnField;
        acc["filter_value"] = item.value;
        return acc;
      }, {});
      const sortField = sortModel[0].field;
      const sortOrder = sortModel[0].sort;
      const response = await axios.get(`/notifications/types`, {
        params: {
          page: page + 1, // API is 1-indexed
          page_size: pageSize,
          sort_field: sortField,
          sort_order: sortOrder,
          ...filters,
        },
      });
      console.log("rowCount: ", response.data);
      setNotificationTypes(response.data.items);
      setRowCount(response.data.total);
    } finally {
      setLoading(false);
    }
  };

  async function deleteNotificationType(id) {
    try {
      const response = await axios.delete(`/notifications/types/${id}`);
      console.log(response.data);
      toastSuccessHelper("Notification type deleted successfully");
    } catch (error) {
      console.error("Error deleting notification type", error);
      toastErrorHelper("Failed to delete notification type");
    }
  }
  //-----------------Local functions --------------------

  const handleFilterChange = (model) => {
    const filters = model.items.reduce((acc, item) => {
      acc[item.columnField] = item.value;
      return acc;
    }, {});
    console.log("Filters: ", filters);
    setFilterModel(model);
  };
  //-----------------Startup Effects --------------------
  React.useEffect(() => {
    fetchNotificationTypes();
  }, [page, pageSize, JSON.stringify(filterModel), JSON.stringify(sortModel)]);
  //------------------ Rendering When no data -------------------------
  if (rowCount == null) {
    return <Loading />;
  }
  //------------------ Rendering When we have data -------------------------
  //------------------Define the rows after we have data-------------------------
  const rows = notificationTypes.map((rec) => ({
    id: rec.id,
    type_name: rec.type_name,
  }));
  //------------------Define the columns based on the row keys -------------------------
  if (!columns.current && rows.length != 0) {
    // Data columns
    columns.current = Object.keys(rows[0]).map((key) => {
      const column = {
        field: key,
        headerName: key.replace(/_/g, " ").toUpperCase(),
        filterOperators:
          key == "id"
            ? getGridStringOperators().filter((op) =>
                ["equals"].includes(op.value)
              )
            : getGridStringOperators().filter((op) =>
                ["contains"].includes(op.value)
              ),
        width: 200,
      };
      return column;
    });
    // Add the edit column
    columns.current.push({
      field: "Edit",
      headerName: "EDIT",
      sortable: false,
      filterable: false,
      // width: 150,
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          console.log("params: ", params);
          // window.open(`/#/meeting-reports/edit/${params.id}`, '_blank');
          navigate(`/notifications/types/edit/${params.id}`, {
            // state: {
            //   report: periodicResults.find((item) => item.id === params.id),
            // },
          });
        };

        return <Button onClick={onClick}>Edit</Button>;
      },
    });
    // Add the delete column
    columns.current.push({
      field: "delete",
      headerName: "DELETE",
      sortable: false,
      filterable: false,
      // width: 150,
      renderCell: (params) => {
        const onClickDelete = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          console.log("params: ", params);
          setNotificationTypeIdToDelete(params.id);
          setOpenDialog(true);
        };

        return (
          <Button color="error" onClick={onClickDelete}>
            Delete
          </Button>
        );
      },
    });
  }
  //------------------Add a custom pagination to display the create button -------------------------
  const CustomPagination = () => {
    const handleClick = () => {
      navigate("/notifications/types/create");
    };

    return (
      <GridToolbarContainer
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={handleClick}
          style={{ margin: "0 0 0 10px" }}
        >
          Add New Notification Type
        </Button>
        <GridPagination />
      </GridToolbarContainer>
    );
  };
  // console.log("--> companies: ", companyNames);
  // console.log("columns: ",columns.current)
  return (
    <Card>
      <Title title="Notification Types" />
      <CardContent>
        <Box sx={{ height: "85vh", width: "100%" }}>
          <StripedDataGrid
            // style={{ width: "100%" }}
            columns={columns.current}
            rows={rows}
            components={{
              Pagination: CustomPagination,
            }}
            pagination
            pageSize={pageSize}
            rowCount={rowCount} // replace with total count from server
            paginationMode="server"
            onPageChange={(page) => setPage(page)}
            onPageSizeChange={(pageSize) => setPageSize(pageSize)}
            rowsPerPageOptions={[10, 25, 50]}
            loading={loading}
            onSortModelChange={(newModel) => setSortModel(newModel)}
            onFilterModelChange={handleFilterChange} // columnWidth={'30%'}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
            }
            slots={{ toolbar: GridToolbar }}
          />
        </Box>
        <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
          <DialogTitle>Confirm Deletion</DialogTitle>
          <DialogContent>
            Are you sure you want to delete result that has id{" "}
            {notificationTypeIdToDelete}?
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
            <Button onClick={handleConfirm} color="primary">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </CardContent>
      <ToastContainer style={{ marginTop: 35 }} />
    </Card>
  );
};
// --------------------define the custom toolbar-----------------------

const ODD_OPACITY = 0.2;

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],
    "&:hover, &.Mui-hovered": {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
    "&.Mui-selected": {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity
      ),
      "&:hover, &.Mui-hovered": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity
        ),
        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  },
}));
export default NotificationTypes;
