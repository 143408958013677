import React, { useState } from "react";
import { toastSuccessHelper, toastErrorHelper } from "../components/Toaster";
import { ToastContainer } from "react-toastify";
import WeeklyFiles from "../components/WeeklyFiles";
import LinearProgress from "@mui/material/LinearProgress";
import {
  Input,
  Button,
  Box,
  Card,
  CardContent,
  Typography,
  CssBaseline,
  Container,
} from "@mui/material";
import StyledLabel from "../components/StyledLabel";
export default function UploadDashboard() {
  const [isLoading, setIsLoading] = useState(false);
  const [newFile, setNewFile] = useState(null);
  const [newFileInputKey, setNewFileInputKey] = useState(Date.now());
  return (
    <>
      <ToastContainer style={{ marginTop: 50 }} />
      <Container
        maxWidth="lg"
        sx={{
          paddingBottom: ".5rem",
        }}
      >
        <CssBaseline />

        <Box
          sx={{
            bgcolor: "#FFF",
            height: "100%",
            marginTop: "1rem",
            padding: 3,
            borderRadius: 3,
          }}
        >
          <Typography
            sx={{
              color: "#064c3b",
            }}
            variant="h4"
            component="div"
          >
            {"Upload Scalens Weekly Files"}
          </Typography>
          <hr style={{ margin: "20px 0" }} />
          <WeeklyFiles />
        </Box>
      </Container>
    </>
  );
}
