import React, { createContext, useContext } from "react";
import axios from "axios";
import { config } from "../config";
const REACT_APP_BACKEND_URL = config.base_url;

// Create an Axios instance
const axiosInstance = axios.create({
  // You can set the base URL and other configurations here
  baseURL: REACT_APP_BACKEND_URL,
  // ... other configurations
});

// Add a request interceptor to handle request errors
axiosInstance.interceptors.request.use(
  (config) => {
    // You can customize the request config here, e.g. adding headers, modifying data, etc.
    return config;
  },
  (error) => {
    // Handle request error
    console.error("Request error:", error);
    return Promise.reject(error);
  }
);

// Add a response interceptor to handle response errors
axiosInstance.interceptors.response.use(
  (response) => {
    // You can customize the response data here, e.g. extracting data, modifying data, etc.
    return response;
  },
  (error) => {
    // Handle response error
    console.error("Response error:", error);

    // You can also handle specific error status codes or error messages here
    if (error.response) {
      // Handle response error with status code
      console.error("Status code:", error.response.status);
    } else if (error.message) {
      // Handle response error with error message
      console.error("Error message:", error.message);
    }

    return Promise.reject(error);
  }
);

// Create a context for the Axios instance
const AxiosContext = createContext(null);

// Create a provider component to wrap around your app
const AxiosProvider = ({ children }) => {
  return (
    <AxiosContext.Provider value={axiosInstance}>
      {children}
    </AxiosContext.Provider>
  );
};

// Custom hook to access the Axios instance from within components
const useAxios = () => {
  const axiosInstance = useContext(AxiosContext);
  if (!axiosInstance) {
    throw new Error("useAxios must be used within an AxiosProvider");
  }
  return axiosInstance;
};

export { AxiosProvider, useAxios };
